import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchSubCategory,deleteSubCategory,createSubCategory,updateSubCategory } from '../Apis/subCategoryApi';
import {subCategoryList, SubCategoryTableColumnData } from '../../Types/TableType';

interface SubCategoryResponse {
    _id:number,
    sub_category_code:string,
    main_category_code:string,
    sub_category_image:string,
    sub_category_description:string,
    sub_category_name:string,
    
}

interface State {
    data: {
        [key: string]: SubCategoryResponse; // Allow dynamic keys
    };
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

export const fetchSubCategoryData = createAsyncThunk(
    'SubCategory/fetchSubCategoryData',
    async () => {
        const SubCategory = await fetchSubCategory();
        console.log("API response inside fetchMainCategoryData:", SubCategory); // Log the API response
        return SubCategory; // Return the nested SubCategory data
    }
);
export const deleteSubCategoryData = createAsyncThunk(
    "SubCategory/deleteSubCategoryData",
    async (MainCategory_code: string) => {
        const data = await deleteSubCategory(MainCategory_code);
        return data;
    }
);

// MainCategoryReducer.tsx
export const createSubCategoryData = createAsyncThunk(
    "SubCategory/createSubCategoryData",
    async (formData: FormData) => { // Expect FormData as the input
        const data = await createSubCategory(formData); // Pass it directly to createMainCategory
        return data;
    }
);


export const updateSubCategoryData = createAsyncThunk(
    "SubCategory/updateSubCategoryData",
    async (formData: SubCategoryTableColumnData) => {
        const data = await updateSubCategory(formData); 
        return data;
    }
);

const SubCategorySlice = createSlice({
    name: 'MainCategory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            //-----------------SubCategory Reducers ----------------------------------//
            .addCase(fetchSubCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchSubCategoryData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchSubCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(createSubCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createSubCategoryData.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(createSubCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(updateSubCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateSubCategoryData.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(updateSubCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(deleteSubCategoryData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteSubCategoryData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            });
    },
});

export default SubCategorySlice.reducer;