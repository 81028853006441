import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { ServiceListTitle, ServiceTitle } from '../../../../Utils/Constants'; // Updated constants for services
import ServiceListNav from "./ServiceListNav"; // Updated import for ServiceListNav
import ServiceListData from "./ServiceListData"; // Updated import for ServiceListData

export default function ServiceListContainer() {
    const [activeTab, setActiveTab] = useState("1"); // State to manage active tab for services

    return (
        <Fragment>
            {/* Breadcrumbs updated for services */}
            <Breadcrumbs pageTitle={ServiceListTitle} parent={ServiceTitle} title={ServiceListTitle} />
            <Container fluid>
                <Row className="service-cards"> {/* Updated className */}
                    <Col md={12} className="service-list"> {/* Updated className */}
                        <ServiceListNav activeTab={activeTab} setActiveTab={setActiveTab} /> {/* Updated for services */}
                    </Col>
                    <Col sm={12}>
                        <ServiceListData /> {/* Updated for services */}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
