import React from 'react'
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap'
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from '../../../../../redux-toolkit/store';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router';
import { Add, Big, Cancel, ClientName, EnterSomeDetails, Medium, ProjectRate, ProjectSize, ProjectTitleLabel, Small } from '../../../../../Utils/Constants'
import { Btn } from '../../../../../AbstractElements'
import { MilestoneDataCallBackData } from '../../../../../Types/TableType';
import { RegisterType } from '../../../../../Types/ProjectType';
import { createMilestonesData,fetchData } from '../../../../../redux-toolkit/reducers/MilestonesReducer';
import { setProjects } from '../../../../../redux-toolkit/reducers/ProjectReducer';


export default function CreateNewMilestoneForm() {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors }, } = useForm<MilestoneDataCallBackData>();
    const addMilestone: SubmitHandler<MilestoneDataCallBackData> = (data) => {
        // const projectData = { ...data};
        console.log("data",data)
        dispatch(createMilestonesData(data))
        .then(() => {
            dispatch(fetchData());
            navigate(`${process.env.PUBLIC_URL}/applications/Milestone/milestone-list`);
        })
        .catch((error) => {
            console.error("Failed to delete:", error);
        });
        // dispatch(fetchData());
        // navigate(`${process.env.PUBLIC_URL}/applications/Milestone/milestone-list`);
    };
    return (
        <Form className="theme-form" onSubmit={handleSubmit(addMilestone)}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Milestone Name</Label>
                        <input className="form-control" type="text" placeholder="Enter Milestone Name *" {...register("am_name", { required: true })} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Milestone Count</Label>
                        <input className="form-control" type="text" placeholder="Enter the Milestone Count" {...register("am_count", { required: true })} />
                    </FormGroup>
                </Col>
            </Row>
            {/* <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label>{ProjectRate}</Label>
                        <input className="form-control" type="number" defaultValue="10" placeholder="Enter project Rate" {...register("rate", { required: true })} />
                    </FormGroup>
                </Col>
            </Row> */}
            {/* <Row>
                <Col sm={4}>
                    <FormGroup>
                        <Label>{ProjectSize}</Label>
                        <Input type="select">
                            <option>{Small}</option>
                            <option>{Medium}</option>
                            <option>{Big}</option>
                        </Input>
                    </FormGroup>
                </Col>
            </Row> */}
            {/* <Row>
                <Col>
                    <FormGroup>
                        <Label>{EnterSomeDetails}</Label>
                        <textarea className="form-control" rows={3} {...register("description", { required: true })} />
                        <span style={{ color: "red" }}>{errors.description && "Some Details is required"}</span>
                    </FormGroup>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <div className="text-end">
                        <Btn color="success" className="me-3">{Add}</Btn>
                        <Btn color="danger">{Cancel}</Btn>
                    </div>
                </Col>
            </Row>
        </Form>
    )
}
