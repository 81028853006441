import axios from "axios";
import { api } from "../../api";
console.log("api",api)

// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};

// Define types for the form data and response
export interface MilestoneFormData {
  _id: string;
  am_name: string;
  am_count: string;
  am_code: string;
  created_date: string;
  updated_date: string;
  am_Status: string;
}


interface MilestoneResponse {
  data: any; // Adjust this based on the actual response structure
}

export const fetchMilestones = async (): Promise<MilestoneResponse> => {
  try {
    console.log("api callimg")
    const response = await axios.get(`${api}/milestone/amFetch`, {
      headers: { Authorization: getUserToken() },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch milestones.");
  }
};

export const createMilestones = async (formData: MilestoneFormData): Promise<MilestoneResponse> => {
  try {
    const response = await axios.post(`${api}/milestone/amCreation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to create milestone.");
  }
};

export const updateMilestones = async (formData: { am_code: string, am_name: string, am_count: string }): Promise<MilestoneResponse> => {
  try {
    const response = await axios.post(`${api}/milestone/amCreation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to update milestone.");
  }
};


export const deleteMilestones = async (am_code: string): Promise<MilestoneResponse> => {
  try {
    const response = await axios.delete(`${api}/milestone/amDelete?am_code=${am_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete milestone.");
  }
};
