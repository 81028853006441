import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchBlog,deleteBlog,createBlog,updateBlog } from '../Apis/blogApi';
import { BlogTableColumn } from '../../Types/TableType';


interface BlogResponse {
    id: number;
    blogCode: string;
    blogName: string;
    blogSubject: string;
    blogThumbnail: string;
    blogDescription: string;
    external_link: string;
    blog_list: BlogStory[]; // Ensure this is BlogStory[]
    bb_story_title: string;
    bb_story_description: string;
}
interface BlogStory {
    bb_story_title: string;
    bb_story_description: string;
}

interface State {
    data: {
        [key: string]: BlogResponse; // Allow dynamic keys
    };
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch milestone data
export const fetchData = createAsyncThunk(
    'Blog/fetchData',
    async () => {
        const Blog = await fetchBlog();
        console.log("API response inside fetchData:", Blog); // Log the API response
        return Blog; // Return the nested Blog data
    }
);

export const deleteBlogData = createAsyncThunk(
    "Blog/deleteBlogData",
    async (blogCode: string) => {
        const data = await deleteBlog(blogCode);
        return data;
    }
);

// BlogReducer.tsx
export const createBlogData = createAsyncThunk(
    "Blog/createBlogData",
    async (formData: FormData) => { // Expect FormData as the input
        const data = await createBlog(formData); // Pass it directly to createBlog
        return data;
    }
);


export const updateBlogData = createAsyncThunk(
    "Blog/updateBlogData",
    async (blogData:BlogTableColumn) => {
        const data = await updateBlog(blogData);
        return data;
    }
);

const BlogSlice = createSlice({
    name: 'Blog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Store the fetched data in state
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.isLoading = false;
                // .state.error = action.error.message ?? null; // Assign null if message is undefined
 
            })
            .addCase(createBlogData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createBlogData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, you can update the state with the new milestone if needed
                // state.data = { ...state.data, [action.payload._id]: action.payload }; // Assuming the response has _id
            })
            .addCase(createBlogData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(updateBlogData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateBlogData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the specific milestone in the state
                // state.data[action.payload._id] = action.payload; // Assuming the response has _id
            })
            .addCase(updateBlogData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(deleteBlogData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteBlogData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            });
    },
});

export default BlogSlice.reducer;
