import React, { useState, useEffect } from 'react'
import { Card, Col, Container, Row, Button, CardBody,Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { useSelector,useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { PlusSquare } from 'react-feather'
import { ProjectListNavProps } from '../../../../Types/ProjectType'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs'
import DataTable, { TableColumn } from 'react-data-table-component'
import CardHeaderSpan from "../../../../Utils/CommonComponents/CardHeaderSpan"
import FilterComponent from '../../../Tables/DataTables/Common/FilterComponent'
import { RootState, AppDispatch } from '../../../../redux-toolkit/store'
import { fetchData,deleteBlogData, updateBlogData } from '../../../../redux-toolkit/reducers/BlogReducer'
import { BlogTableColumn } from '../../../../Types/TableType'
import { Editor } from "primereact/editor"; // PrimeReact Editor
import 'quill/dist/quill.snow.css';

// Define the type for blog data with the new fields
interface Blog {
    id: number;
    blogCode: string;
    blogName: string;
    blogSubject: string;
    blogThumbnail: string;
    blogDescription: string;
    external_link: string;
    blog_list: BlogStory[]; // Ensure this is BlogStory[]
    bb_story_title: string;
    bb_story_description: string;
}
interface BlogStory {
    bb_story_title: string;
    bb_story_description: string;
}


// Function to handle edit action


// Filter Component for search functionality
// const FilterComponent = ({ filterText, onFilter, onClear }: { filterText: string, onFilter: (e: React.ChangeEvent<HTMLInputElement>) => void, onClear: () => void }) => (
//     <div className="filter-component">
//         <input
//             type="text"
//             placeholder="Filter by blog name, subject, or description"
//             value={filterText}
//             onChange={onFilter}
//         />
//         <Button onClick={onClear}>Clear</Button>
//     </div>
// )

export default function BlogBoxTable({ activeTab, setActiveTab }: ProjectListNavProps) {
    const [filterText, setFilterText] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [selectedBlog, setSelectedBlog] = useState<BlogTableColumn | null>(null); 
    const [blog_list, setStories] = useState<
    Array<{ bb_story_title: string; bb_story_description: string }>
  >([{ bb_story_title: "", bb_story_description: "" }]); 
  const [editData, setEditData] = useState<Blog>({
    blogName: '',
    id: 1,
    blogCode: '',
    blogSubject: '',
    blogDescription: '',
    blogThumbnail: '',
    external_link: '',
    blog_list: [], // Initialize as an empty array
    bb_story_title: '',
    bb_story_description: '',
}); 
    const dispatch: AppDispatch = useDispatch();
    
    const Blog = useSelector((state: RootState) => state.Blog);
    const BlogData: BlogTableColumn[] = Array.isArray(Blog?.data) 
        ? Blog.data 
        : Object.values(Blog?.data || {}); // Ensure data is an array

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                await dispatch(fetchData());
            } catch (error) {
                console.error("Error fetching Blog:", error);
            }
        };
        fetchBlog();
    }, [dispatch]);
    const handleSubmit = async () => {
        console.log(editData,"editData")
        try {
            await dispatch(updateBlogData(editData));  
            dispatch(fetchData());
            setIsModalOpen(false);  
        } catch (error) {
            console.error("Failed to update:", error);
        }
    };
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({ ...prevData, [name]: value }));
    };
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEditData((prevData) => ({
                    ...prevData,
                    blogThumbnail: reader.result as string // Assuming the image will be in base64 format
                }));
            };
            reader.readAsDataURL(file);
        }
    };
    const handleEdit = (row : Blog) => {
        console.log(`Edit blog with id: ${row}`)
        setSelectedBlog(row);
        setEditData(row);
        setIsModalOpen(true);
    }
    
    // Function to handle delete action
    const handleDelete = (id: any) => {
        console.log(`Delete blog with id: ${id}`)
        dispatch(deleteBlogData(id))
        .then(() => {
            dispatch(fetchData());
        })
        .catch((error) => {
            console.error("Failed to delete :", error);
        });
    }
    const truncateText = (text: string, wordLimit: number) => {
        const words = text.split(' ');
        return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
    };
    
    // Define columns for the DataTable
    const columns: TableColumn<Blog>[] = [
        {
            name: 'Blog Name',
            selector: (row) => row.blogName,
            sortable: true
        },
        {
            name: 'Blog Subject',
            selector: (row) => truncateText(row.blogSubject, 10),
            sortable: true
        },
        {
            name: 'Blog Description',
            selector: (row) => truncateText(row.blogDescription, 10),
            sortable: true
        },
        {
            name: "Actions",
            cell: (row) => (
                <div>
                    <button className="btn btn-primary btn-sm" onClick={() => handleEdit(row)}>
                        Edit
                    </button>
                    <button className="btn btn-danger btn-sm ms-2" onClick={() => handleDelete(row.blogCode)}>
                        Delete
                    </button>
                </div>
            ),
        },
    ]
    
    
      const removeStory = (index: number) => {
        const updatedStories = blog_list.filter((_, i) => i !== index);
        setStories(updatedStories);
      };
    
      const addStory = () => {
        const newStory = { bb_story_title: "", bb_story_description: "" };
        setStories((prevStories) => [...prevStories, newStory]);
        setEditData((prevData) => ({
            ...prevData,
            blog_list: [...prevData.blog_list, newStory]
        }));
    };
    
    // const handleStoryInputChange = (
    //     index: number,
    //     field: "bb_story_title" | "bb_story_description",
    //     value: string
    //   ) => {
    //     const updatedStories = blog_list.map((story, idx) => {
    //       if (idx === index) {
    //         // Only update the specific field (either bb_story_title or bb_story_description)
    //         return { ...story, [field]: value };
    //       }
    //       return story; // Return the unchanged story for other indexes
    //     });
      
    //     setStories(updatedStories);
    //     setEditData((prevData) => ({
    //       ...prevData,
    //       blog_list: updatedStories, // Update the blog_list in editData
    //     }));
    //   };

    const handleStoryInputChange = (
        index: number,
        field: "bb_story_title" | "bb_story_description",
        value: string
      ) => {
        setEditData((prevData) => {
          // Copy the current blog_list array from prevData
          const updatedStories = prevData.blog_list.map((story, idx) => {
            if (idx === index) {
              // Update only the specific field (bb_story_title or bb_story_description)
              return { ...story, [field]: value };
            }
            return story;
          });
      
          return {
            ...prevData,
            blog_list: updatedStories,
          };
        });
      };
      
    
      
    
    


    const filteredItems = BlogData.filter(
        (item: Blog) =>
            item.blogName.toLowerCase().includes(filterText.toLowerCase()) ||
            item.blogSubject.toLowerCase().includes(filterText.toLowerCase()) ||
            item.blogDescription.toLowerCase().includes(filterText.toLowerCase())
    )

    return (
        <>
            <Container>
                <Breadcrumbs pageTitle={"Blog List"} parent={"Blog"} title={"Blog List"} />
            </Container>
            <Card>
                <Row>
                    <Col md={6} className="p-0"></Col>
                    <Col md={6} className="p-0">
                        <Row className="project-cards">
                            <Col md={12} className="project-list">
                                <Link className="btn btn-primary" to={`${process.env.PUBLIC_URL}/miscellaneous/blog/blog-Add`}>
                                    <PlusSquare /> Create a Blog
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <Col sm={12}>
                <Card>
                    <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={"Blog"} />
                    <CardBody>
                        <FilterComponent
                            onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                            onClear={() => setFilterText('')}
                            filterText={filterText}
                        />
                        <div className="table-responsive custom-scrollbar">
                            <DataTable data={filteredItems} columns={columns} striped={true} pagination className="display dataTable" />
                        </div>
                    </CardBody>
                </Card>
            </Col>
            {/* Edit Modal */}
            <Modal size='lg'
    isOpen={isModalOpen} 
    toggle={() => setIsModalOpen(!isModalOpen)} 
    className="custom-modal" // Custom class to increase modal size
>
    <ModalHeader  toggle={() => setIsModalOpen(!isModalOpen)}>Edit Blog</ModalHeader>
    <ModalBody>
        {selectedBlog && (
            <Form>
                <FormGroup>
                    <Label for="blogName">Blog Name</Label>
                    <Input
                        type="text"
                        name="blogName"
                        value={editData.blogName || ''}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="blogSubject">Blog Subject</Label>
                    <Input
                        type="text"
                        name="blogSubject"
                        value={editData.blogSubject || ''}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="blogDescription">Blog Description</Label>
                    <Input
                        type="textarea"
                        name="blogDescription"
                        value={editData.blogDescription || ''}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="external_link">External Link</Label>
                    <Input
                        type="textarea"
                        name="external_link"
                        value={editData.external_link || ''}
                        onChange={handleChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="blogThumbnail">Change Image</Label>
                    <Input
                        type="file"
                        name="blogThumbnail"
                        accept="image/*"
                        onChange={handleImageChange}
                    />
                </FormGroup>
                {editData.blogThumbnail && (
                    <img 
                        src={editData.blogThumbnail} 
                        alt="Thumbnail"
                        style={{ width: '50%', height: 'auto', marginTop: '5px', marginBottom:'10px' }} 
                    />
                )}
                {editData.blog_list.map((story: BlogStory, index: number) => (
                    <div key={index}>
                        <Card className="p-4">
                            <Row>
                                <Col className="text-end">
                                    <button type="button" onClick={() => removeStory(index)} className="btn">X</button>
                                </Col>
                                <FormGroup>
                                    <Label>Blog Title:</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        value={story.bb_story_title || ''}
                                        onChange={(e) =>
                                            handleStoryInputChange(index, "bb_story_title", e.target.value)
                                        }
                                        
                                    />
                                </FormGroup>
                            </Row>
                            <FormGroup>
                                <Label>Blog Description:</Label>
                                <Editor
                                    value={story.bb_story_description || ""}
                                    style={{ height: '200px' }}
                                    onTextChange={(e) =>
                                        handleStoryInputChange(index, "bb_story_description", e.htmlValue || "")
                                    }
                                />
                            </FormGroup>
                        </Card>
                    </div>
                ))}
                <Button type="button" onClick={addStory} className="btn btn-primary">+ Add Story</Button>
            </Form>
        )}

    </ModalBody>
    <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>Save</Button>{' '}
        <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
    </ModalFooter>
</Modal>


        </>
    )
}
