import React from 'react'
import TasksContainer from '../../../Components/Apps/Tasks'

export default function Tasks() {
  return (
    <div className='page-body'>
        <TasksContainer/>
    </div>
  )
}
