import React from 'react'
import InputMaskContainer from '../../../../Components/Forms/FormControls/InputMask'

export default function InputMask() {
  
  return (
    <div className='page-body'>
        <InputMaskContainer/>
    </div>
  )
}
