import { configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./reducers/LayoutReducer";
import themeCustomizerReducer from "./reducers/ThemeCustomizerReducer";
import chatReducer from "./reducers/ChatReducer";
import tasksReducer from "./reducers/TasksReducer";
import todoReducer from "./reducers/TodoReducer";
import bookmarkReducer from "./reducers/BookmarkReducer";
import contactReducer from "./reducers/ContactReducer";
import usersReducer from "./reducers/UsersReducer";
import searchResultReducer from "./reducers/SearchResultReducer";
import emailReducer from "./reducers/LetterBoxReducer"
import filterReducer from "./reducers/EcommerceReducer/FilterReducer";
import productReducer from "./reducers/ProductReducer";
import ProjectReducer from "./reducers/ProjectReducer";
import MilestonesReducer from "./reducers/MilestonesReducer";
import TestimonialReducer from "./reducers/TestimonialReducer";
import ServiceReducer from "./reducers/ServiceReducer";
import ContactUsReducer from "./reducers/ContactUsReducer";
import FeedBackReducer from "./reducers/FeedBackReducer";
import ClientReducer from "./reducers/ClientReducer";
import LoginReducer from "./reducers/LoginReducer";
import BlogReducer from "./reducers/BlogReducer";
import PowerfulFeatureReducer from "./reducers/PowerfulFeatureReducer";
import getAdminReducer from "./reducers/GetAdminReducer";
import TechnologyReducer from "./reducers/TechnologyReducer";
import MainCategoryDara from "./reducers/marketSolutionsReducer";
import SubCategoryDara from "./reducers/subCategoryReducer";
import MarketsData from "./reducers/MarketReducer";


export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    themeCustomizer: themeCustomizerReducer,
    chat: chatReducer,
    tasks: tasksReducer,
    todos: todoReducer,
    bookmark: bookmarkReducer,
    contact: contactReducer,
    userCards: usersReducer,
    searchResult: searchResultReducer,
    email: emailReducer,
    filter: filterReducer,
    product: productReducer,
    project: ProjectReducer,
    milestones:MilestonesReducer,
    Testimonial:TestimonialReducer,
    services:ServiceReducer,
    contactUs:ContactUsReducer,
    feedback:FeedBackReducer,
    Client:ClientReducer,
    authadmin:LoginReducer,
    Blog:BlogReducer,
    powerfulFeatures:PowerfulFeatureReducer,
    getadmin:getAdminReducer,
    technology:TechnologyReducer,
    MainCategory:MainCategoryDara,
    SubCategory:SubCategoryDara,
    Markets:MarketsData
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
