import React from 'react'
import FormWizard2Container from '../../../../Components/Forms/FormLayout/FormWizard2'

export default function FormWizard2() {
  return (
    <div className='page-body'>
        <FormWizard2Container/>
    </div>
  )
}
