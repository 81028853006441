import React from 'react'
import MegaOptionsContainer from '../../../../Components/Forms/FormControls/MegaOptions'

export default function MegaOptions() {
  
  return (
    <div className='page-body'>
        <MegaOptionsContainer/>
    </div>
  )
}
