import React, { Fragment, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs'
import { ProjectListTitle, ProjectTitle,MilestoneListTitle,MilestoneTitlee } from '../../../../Utils/Constants'
import MilestoneListNav from "./MilestoneListNav";
import MilestoneListData from "./MilestoneListData";
// import ProjectListNav from './ProductListNav';
// import ProjectListTabContent from './ProductListTabContent';

export default function MilestoneListContainer() {
    const [activeTab, setActiveTab] = useState("1");

    return (
        <Fragment>
            <Breadcrumbs pageTitle={MilestoneListTitle} parent={MilestoneTitlee} title={MilestoneListTitle} />
            <Container fluid>
                <Row className="project-cards">
                    <Col md={12} className="project-list">
                        <MilestoneListNav activeTab={activeTab} setActiveTab={setActiveTab} />
                    </Col>
                    <Col sm={12}>
                        <MilestoneListData />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
