import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { TechnologyListTitle, TechnologyTitle } from '../../../../Utils/Constants'; // Updated constants for technology
import TechnologyListNav from "./TechnologyListNav"; // Updated import for TechnologyListNav
import TechnologyListData from "./TechnologyListData"; // Updated import for TechnologyListData

export default function TechnologyListContainer() {
    const [activeTab, setActiveTab] = useState("1"); // State to manage active tab for technology

    return (
        <Fragment>
            {/* Breadcrumbs updated for technology */}
            <Breadcrumbs pageTitle={TechnologyListTitle} parent={TechnologyTitle} title={TechnologyListTitle} />
            <Container fluid>
                <Row className="technology-cards"> {/* Updated className */}
                    <Col md={12} className="technology-list"> {/* Updated className */}
                        <TechnologyListNav activeTab={activeTab} setActiveTab={setActiveTab} /> {/* Updated for technology */}
                    </Col>
                    <Col sm={12}>
                        <TechnologyListData /> {/* Updated for technology */}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
