import axios from "axios";
import { api } from "../../api";
import { MainCategoryTableColumnData,SubCategoryTableColumnData } from "../../Types/TableType";
// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};


interface MarketSolutionResponse {
  data: any; // Adjust this based on the actual response structure
}
//-------------------------------Main Category API's--------------------------------//
export const fetchMainCategory = async (): Promise<MarketSolutionResponse> => {
  try {
    const response = await axios.get(`${api}/subCategory/fetch`, {
      headers: {  Authorization: `Bearer ${getUserToken()}` },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch MainCategory.");
  }
};

export const fetchMainCategoryByCode = async (category_code :string): Promise<MarketSolutionResponse> => {
  try {
    const response = await axios.get(`${api}/subCategory/fetch?category_code=${category_code}`, {
      headers: {  Authorization: `Bearer ${getUserToken()}` },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch MainCategory.");
  }
};

export const fetchMainCategoryCurrent = async (): Promise<MarketSolutionResponse> => {
  try {
    const response = await axios.get(`${api}/MainCategory/Retrieve`, {
      headers: {  Authorization: `Bearer ${getUserToken()}` },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch MainCategory.");
  }
};

// MainCategoryApi.tsx
export const createMainCategory = async (formData: FormData): Promise<MarketSolutionResponse> => { 
  try {
    console.log("formData", formData);
    const response = await axios.post(`${api}/MainCategory/Creation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data', // Correct header for file uploads
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to create MainCategory.");
  }
};

export const updateMainCategory = async (formData: MainCategoryTableColumnData): Promise<MarketSolutionResponse> => {
  try {
      const response = await axios.put(`${api}/MainCategory/MainCategoryEdit`, formData, {
          headers: {
              Authorization: `Bearer ${getUserToken()}`,
              'Content-Type': 'application/json',
          },
      });
      return response.data;
  } catch (error) {
      throw new Error("Failed to update MainCategory.");
  }
};

export const deleteMainCategory = async (category_code: string): Promise<MarketSolutionResponse> => {
  try {
    const response = await axios.delete(`${api}/MainCategory/Delete?category_code=${category_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete MainCategory.");
  }
};

