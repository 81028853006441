import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { TableColumn } from "react-data-table-component";
import CardHeaderSpan from '../../../../../Utils/CommonComponents/CardHeaderSpan';
import { ServiceTitle } from '../../../../../Utils/Constants';
import FilterComponent from '../../../../Tables/DataTables/Common/FilterComponent';
import { fetchServicesData, deleteServiceData, updateServiceData } from '../../../../../redux-toolkit/reducers/ServiceReducer';

export interface ServiceRowData {
    image_title: string;
    image_url: string;
    serviceSubject: string;
    image_description: string;
    service_code: string;
    serviceStatus: string;
}

export default function RowCreateCallback() {
    const [filterText, setFilterText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedService, setSelectedService] = useState<ServiceRowData | null>(null);
    const [editData, setEditData] = useState<ServiceRowData>({
        image_title: '',
        image_url: '',
        serviceSubject: '',
        image_description: '',
        service_code: '',
        serviceStatus: '',
    });
    const [imageFile, setImageFile] = useState<File | null>(null);
    const dispatch: AppDispatch = useDispatch();

    const services = useSelector((state: RootState) => state.services);
    const servicesData: ServiceRowData[] = Array.isArray(services?.data)
        ? services.data
        : Object.values(services?.data || {});

    useEffect(() => {
        const fetchServices = async () => {
            try {
                await dispatch(fetchServicesData());
                console.log("Fetched services data:", servicesData);
            } catch (error) {
                console.error("Error fetching services:", error);
            }
        };

        fetchServices();
    }, [dispatch]);

    const filteredItems: ServiceRowData[] = servicesData.filter(
        (item) =>
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );

    const handleEdit = (row: ServiceRowData) => {
        console.log("Editing row:", row);
        if (row && row.service_code) {
            setEditData(row);
            setSelectedService(row);
            setIsModalOpen(true);
        } else {
            console.error("Selected row does not contain service_code", row);
        }
    };

    const handleDelete = (row: ServiceRowData) => {
        dispatch(deleteServiceData(row.service_code))
            .then(() => {
                dispatch(fetchServicesData());
            })
            .catch((error) => {
                console.error("Failed to delete:", error);
            });
    };

    const handleModalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setImageFile(file);
            const imageUrl = URL.createObjectURL(file);
            setEditData((prevData) => ({
                ...prevData,
                image_url: imageUrl,
            }));
        }
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        console.log([formData]);
        formData.append('image_title', editData.image_title);
        formData.append('image_description', editData.image_description);
        formData.append('serviceSubject', editData.serviceSubject);
        formData.append('serviceStatus', editData.serviceStatus);
        formData.append('service_code', editData.service_code);
        if (imageFile) {
            formData.append('image_url', imageFile);
        }
        console.log("noted",[formData]);
        try {
            await dispatch(updateServiceData(formData));
            await dispatch(fetchServicesData());
            setIsModalOpen(false);
        } catch (error) {
            console.error("Failed to update service:", error);
        }
    };

    const rowServiceTableColumns: TableColumn<ServiceRowData>[] = [
        {
            name: "Service Title",
            selector: (row) => row.image_title,
            sortable: true,
        },
        {
            name: "Service Image",
            cell: (row) => (
                <img
                    src={row.image_url}
                    alt={row.image_title}
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                />
            ),
            sortable: true,
        },
        {
            name: "Service Subject",
            selector: (row) => row.serviceSubject,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row.serviceStatus,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div>
                    <Button onClick={() => handleEdit(row)} color="primary" size="sm">
                        Edit
                    </Button>
                    <Button onClick={() => handleDelete(row)} color="danger" size="sm" className="ms-2">
                        Delete
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <Col sm={12}>
            <Card>
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={ServiceTitle} />
                <CardBody>
                    <FilterComponent
                        onFilter={(e) => setFilterText(e.target.value)}
                        onClear={() => setFilterText('')}
                        filterText={filterText}
                    />
                    <div className="table-responsive custom-scrollbar">
                        <DataTable
                            data={filteredItems}
                            columns={rowServiceTableColumns}
                            striped
                            pagination
                            className="display dataTable"
                        />
                    </div>
                </CardBody>
            </Card>

            {/* Edit Modal */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
                <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Service</ModalHeader>
                <ModalBody>
                    <form>
                        <FormGroup>
                            <Label for="image_title">Image Title</Label>
                            <Input
                                type="text"
                                name="image_title"
                                value={editData.image_title}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="image_description">Image Description</Label>
                            <Input
                                type="textarea"
                                name="image_description"
                                value={editData.image_description}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="serviceSubject">Service Subject</Label>
                            <Input
                                type="text"
                                name="serviceSubject"
                                value={editData.serviceSubject}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="service_code">Service Code</Label>
                            <Input
                                type="text"
                                name="service_code"
                                value={editData.service_code}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="serviceStatus">Service Status</Label>
                            <Input
                                type="text"
                                name="serviceStatus"
                                value={editData.serviceStatus}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="image_url">Change Image</Label>
                            <Input
                                type="file"
                                name="image_url"
                                accept="image/*"
                                onChange={handleImageChange}
                            />
                        </FormGroup>
                        {editData.image_url && (
                            <img
                                src={editData.image_url}
                                alt="Service"
                                style={{ width: '50%', height: 'auto', marginTop: '5px' }}
                            />
                        )}
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Col>
    );
}
