import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from '../../../../../redux-toolkit/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Btn } from '../../../../../AbstractElements';
import { MilestoneDataCallBackData, ClientColumnData } from '../../../../../Types/TableType';
import { createClientData, fetchData } from '../../../../../redux-toolkit/reducers/ClientReducer';

export default function CreateNewClientForm() {
    const [ClientImage, setClientImage] = useState<File | null>(null); // Store file
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setClientImage(e.target.files[0]); // Set file to state
        }
    };

    const { register, handleSubmit, formState: { errors }, } = useForm<ClientColumnData>();
    const addMilestone: SubmitHandler<ClientColumnData> = (data) => {
        const formData = new FormData();
        formData.append('client_name', data.client_name);
        formData.append('client_description', data.client_description);
        formData.append('client_url', data.client_url);

        if (ClientImage) {
            formData.append('Client_icon', ClientImage); // Add image to formData without []
        }

        dispatch(createClientData(formData))
            .then(() => {
                dispatch(fetchData());
                navigate(`${process.env.PUBLIC_URL}/applications/Clients/client-list`);
            })
            .catch((error) => {
                console.error("Failed to create testimonial:", error);
            });
    };

    return (
        <Form className="theme-form" onSubmit={handleSubmit(addMilestone)}>
            <Row>
                <Col md="6">
                    <FormGroup>
                        <Label>Client Name</Label>
                        <input className="form-control" type="text" placeholder="Enter Client Name *" {...register("client_name", { required: true })} />
                        {errors.client_name && <span className="text-danger">Client Name is required</span>}
                    </FormGroup>
                </Col>
                <Col md="6">
                    <FormGroup>
                        <Label>Client URL</Label>
                        <input className="form-control" type="text" placeholder="Enter Client URL *" {...register("client_url", { required: true })} />
                        {errors.client_url && <span className="text-danger">Client URL is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <FormGroup>
                        <Label>Client Description</Label>
                        <input className="form-control" type="text" placeholder="Enter Client Description" {...register("client_description", { required: true })} />
                        {errors.client_description && <span className="text-danger">Client Description is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <FormGroup>
                        <Label>Client Image</Label>
                        <input 
                            className="form-control" 
                            type="file" 
                            onChange={handleFileChange} // Handle manually instead of registering with useForm
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <div className="text-end">
                        <Btn color="success" className="me-3">Add</Btn>
                        <Btn color="danger">Cancel</Btn>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}
