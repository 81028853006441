import React,{useState} from 'react'
import ContactListTabContent from './ContactUsList';


export default function ContactUs() {
    const [activeTab, setActiveTab] = useState('1');
  return (
    <div className='page-body'>
       <ContactListTabContent activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  )
}