import React from 'react';
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux';
import { SidebarMenuType } from '../../Types/LayoutTypes';
import { Pinned } from '../../Utils/Constants';
import { H6, LI, UL } from '../../AbstractElements';
import { menuList } from '../../Data/SidebarMenuList';
import SidebarSubMenu from './SidebarSubMenu';
import BackButton from './BackButton';
import { RootState } from '../../redux-toolkit/store';
import { useTranslation } from 'react-i18next';

export default function SidebarNav() {
    const [activeMenu, setActiveMenu] = useState([]);
    const { pinedMenu } = useSelector((state: RootState) => state.layout);
    const { t } = useTranslation();
    const shouldHideMenu = (mainMenu: SidebarMenuType) => {
        return mainMenu.menu.map((data) => data.title).every((tittles) => pinedMenu.includes(tittles as string));
    }
    return (
        <UL className='sidebar-links simple-list custom-scrollbar' id='simple-bar'>
            <div className='simplebar-wrapper'>
                <div className='simplebar-mask'>
                    <div className='simplebar-offset'>
                        <div className='simplebar-content-wrapper'>
                            <div className='simplebar-content'>
                                <BackButton />
                                <LI className={`pin-title sidebar-main-title ${pinedMenu.length > 0 ? "show" : ""} `}>
                                    <div>
                                        <H6>{Pinned}</H6>
                                    </div>
                                </LI>
                                {menuList && menuList.map((mainMenu, i) => (
                                    <Fragment key={i}>
                                        <LI className={`sidebar-main-title ${shouldHideMenu(mainMenu) ? 'd-none' : ''}`}>
                                            <div>
                                                <H6 className='lan-1'>{t(`${mainMenu.title}`)}</H6>
                                            </div>
                                        </LI>
                                        <SidebarSubMenu menu={mainMenu.menu} activeMenu={activeMenu} setActiveMenu={setActiveMenu} level={0} />
                                    </Fragment>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </UL>
    )
}
