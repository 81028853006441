import React, { useState } from 'react'
import { Col } from 'reactstrap'
import { FolderPlus } from 'react-feather'
import { Link } from 'react-router-dom'
import { folderPlusData, leftHeaderData } from '../../../Data/HeaderData/LeftHeaderData'
import SvgIcon from '../../../Utils/CommonComponents/CommonIcons/CommonSvgIcons'
import { LI, UL } from '../../../AbstractElements'

export default function LeftHeader() {
    const [folderOpen, setFolderOpen] = useState(false)
    const folderToggle = () => {
        setFolderOpen(!folderOpen)
    }
    return (
        <Col xxl={5} xl={4} xs='auto' className="left-header box-col-6 horizontal-wrapper p-0">
            <div className="left-menu-header">
                <UL className="app-list simple-list">
                    <LI className='onhover-dropdown' onClick={folderToggle}>
                        <div className="app-menu"> <FolderPlus /></div>
                        <UL className={`onhover-show-div left-dropdown text-center ${folderOpen ? 'active':''}`}>
                            {folderPlusData.map((data) => (
                                <LI key={data.id}><Link to={data.link}>{data.text}</Link></LI>
                            ))}
                        </UL>
                    </LI>
                </UL>
                <UL className="header-left simple-list flex-row">
                    {leftHeaderData.map((data) => (
                        <LI className={`onhover-dropdown ${data.id === 4 ? 'p-0' : ''}`} key={data.id}>
                            <span className="f-w-500">
                                <SvgIcon iconId={data.icon} /> {data.text}
                            </span>
                            <UL className="onhover-show-div left-dropdown simple-list">
                                {data.child.map((item) => (
                                    <LI key={item.id}>
                                        <Link to={item.link}>{item.text}</Link>
                                    </LI>
                                ))}
                            </UL>
                        </LI>
                    ))}
                </UL>
            </div>
        </Col>
    )
}
