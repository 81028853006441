import React from 'react'
import CreativeCardContainer from '../../../Components/BonusUi/CreativeCard'

export default function CreativeCard() {
  return (
    <div className='page-body'>
        <CreativeCardContainer/>
    </div>
  )
}
