import React from 'react'
import ProductCreateContainer from '../../../../Components/Apps/Product/ProductCreate'

export default function ProductCreate() {
  return (
    <div className='page-body'>
        <ProductCreateContainer/>
    </div>
  )
}
