import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import ProfileMail from './ProfileMail';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import ProfileContact from './ProfileContact';
import SocialMedia from '../../CommonProfile/SocialMedia';
import ProfileFollower from './ProfileFollower';
import { AppDispatch, RootState } from '../../../../../../redux-toolkit/store';
import { fetchAdminData } from '../../../../../../redux-toolkit/reducers/GetAdminReducer';
import { Admin } from '../../../../../../redux-toolkit/reducers/GetAdminReducer';

export default function ProfileDetail() {
    const dispatch: AppDispatch = useDispatch();

    const Login = useSelector((state: RootState) => state.getadmin);
    const LoginData: Admin[] = Array.isArray(Login?.data) 
        ? Login.data 
        : Object.values(Login?.data || {});
    console.log("Login Data:", LoginData); // Now you'll see the array of admin data

    useEffect(() => {
        const fetchAdmin = async () => {
            try {
                await dispatch(fetchAdminData());
            } catch (error) {
                console.error("Error fetching Login:", error);
            }
        };

        fetchAdmin();
    }, [dispatch]);

    return (
        <div className="info">
            <Row className="g-3 step3">
                <ProfileMail adminData={LoginData} />
                <Col sm={12} xl={4} className="order-sm-0 order-xl-1">
                    <div className="user-designation tour-email">
                        <div className="title">
                            <Link to={'https://dev.athenasecurity.in/'}> Athena Security </Link>
                        </div>
                        <div className="desc mt-2">ADMIN</div>
                    </div>
                </Col>
                {/* Correct way to pass the prop */}
                <ProfileContact adminData={LoginData} />
            </Row>
            <hr />
            {/* <SocialMedia />
            <ProfileFollower /> */}
        </div>
    );
}
