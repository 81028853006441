import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMilestones, createMilestones, deleteMilestones, updateMilestones } from '../Apis/milestonesApi';

// Define MilestoneFormData type
interface MilestoneFormData {
    _id: string;
    am_name: string;
    am_count: string;
    am_code: string;
    created_date: string;
    updated_date: string;
    am_Status: string;
}
interface MilestoneResponse {
    _id: string; 
    am_name: string;
    am_Status:string;
    am_count: string;
    am_code: string;
    created_date: string;
    updated_date: string;
   
}

interface State {
    data: {
        [key: string]: MilestoneResponse; // Allow dynamic keys
    };
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch milestone data
export const fetchData = createAsyncThunk(
    'milestones/fetchData',
    async () => {
        const milestones = await fetchMilestones();
        console.log("API response inside fetchData:", milestones); // Log the API response
        return milestones; // Return the nested milestones data
    }
);

export const deleteMilestonesData = createAsyncThunk(
    "milestones/deleteMilestonesData",
    async (am_code: string) => {
        const data = await deleteMilestones(am_code);
        return data;
    }
);

export const createMilestonesData = createAsyncThunk(
    "milestones/createMilestonesData",
    async (formData: MilestoneFormData) => {
        const data = await createMilestones(formData);
        return data;
    }
);

export const updateMilestonesData = createAsyncThunk(
    "milestones/updateMilestonesData",
    async (formData: { am_code: string, am_name: string, am_count: string }) => {
        const data = await updateMilestones(formData); // Pass the whole formData object
        return data;
    }
);


const milestonesSlice = createSlice({
    name: 'milestones',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Store the fetched data in state
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.isLoading = false;
                // .state.error = action.error.message ?? null; // Assign null if message is undefined
 
            })
            .addCase(createMilestonesData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createMilestonesData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, you can update the state with the new milestone if needed
                // state.data = { ...state.data, [action.payload._id]: action.payload }; // Assuming the response has _id
            })
            .addCase(createMilestonesData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(updateMilestonesData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateMilestonesData.fulfilled, (state, action) => {
                state.isLoading = false;
            
                // First, cast to unknown, then validate the structure
                const updatedMilestone = action.payload as unknown as MilestoneResponse;
            
                // Check that the payload contains the expected fields
                if (updatedMilestone && updatedMilestone._id && updatedMilestone.am_name) {
                    state.data[updatedMilestone._id] = updatedMilestone;
                } else {
                    console.error("Payload structure is invalid", action.payload);
                }
            })
            
            

           
            .addCase(updateMilestonesData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(deleteMilestonesData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteMilestonesData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            });
    },
});

export default milestonesSlice.reducer;
