import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTechnologies, createTechnology, deleteTechnology, updateTechnology } from '../Apis/technology'; // Replace with your technologies API functions

// Define TechnologyFormData type based on the technology schema
interface TechnologyFormData {
    _id?: string; // Optional for newly created technologies
    technologyCode: string; // Initialize as an empty string
    technologyname: string; // Using 'technologyname' as specified
    technology_updated_date?: string; // Optional update date
    technology_created_date?: string; // Optional creation date
    technologyStatus: 'ACTIVE' | 'DEACTIVE'; // Enum for technology status
}

interface TechnologyResponse {
    _id?: string; // Optional for newly created technologies
    technologyCode: string; // Initialize as an empty string
    technologyname: string; // Using 'technologyname' as specified
    technology_updated_date?: string; // Optional update date
    technology_created_date?: string; // Optional creation date
    technologyStatus: 'ACTIVE' | 'DEACTIVE'; // Enum for technology status
}

interface State {
    data: {
        [key: string]: TechnologyResponse; // Allow dynamic keys for each technology
    };
    isLoading: boolean;
    error: string | null; // Allow null values for error
}

const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch technology data
export const fetchTechnologiesData = createAsyncThunk(
    'technologies/fetchTechnologiesData',
    async () => {
        const technologies = await fetchTechnologies();
        console.log("API response inside fetchTechnologiesData:", technologies);
        return technologies; // Assuming technologies API returns an array or object
    }
);

export const deleteTechnologyData = createAsyncThunk(
    "technologies/deleteTechnologyData",
    async (technologyCode: string) => {
        const data = await deleteTechnology(technologyCode);
        return data;
    }
);

export const createTechnologyData = createAsyncThunk(
    "technologies/createTechnologyData",
    async (formData: TechnologyFormData) => {
        const data = await createTechnology(formData);
        console.log("data reducers :", data);
        return data;
    }
);

export const updateTechnologyData = createAsyncThunk(
    "technologies/updateTechnologyData",
    async (formData: TechnologyFormData) => {
        const data = await updateTechnology(formData); // This should work with TechnologyFormData now
        return data;
    }
);

const technologiesSlice = createSlice({
    name: 'technologies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTechnologiesData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchTechnologiesData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Assuming the response has a data field
            })
            .addCase(fetchTechnologiesData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null; // Handle error
            })
            .addCase(createTechnologyData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createTechnologyData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, add new technology to the state
                // state.data[action.payload._id] = action.payload; // Add the new technology to state
            })
            .addCase(createTechnologyData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(updateTechnologyData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateTechnologyData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the technology in the state
                // state.data[action.payload._id] = action.payload;
            })
            .addCase(updateTechnologyData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteTechnologyData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteTechnologyData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Remove the deleted technology from the state
                // delete state.data[action.payload.technologyCode]; // Assuming technologyCode is used to identify the technology
            })
            .addCase(deleteTechnologyData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default technologiesSlice.reducer;
