import React from 'react'
import ProductListContainer from '../../../../Components/Apps/Product/ProductList'

export default function ProjectList() {
  return (
    <div className='page-body'>
        <ProductListContainer/>
    </div>
  )
}
