import React, { Fragment } from 'react';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs';
import { ProjectCreateTitle, AppsTitle } from '../../../../../Utils/Constants';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import CreateNewServiceForm from "./CreateNewServices"; // Adjusted the import for the service form

export default function ServiceCreateContainer() {
    return (
        <Fragment>
            <Breadcrumbs pageTitle={ProjectCreateTitle} parent={AppsTitle} title={ProjectCreateTitle} />
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <CreateNewServiceForm /> {/* Changed to use the service form */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
