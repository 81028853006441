import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchMarketAndSolutionsCategory,fetchMarketAndSolutionsCategoryByCode,createMarketAndSolutionsCategory,updateMarketAndSolutionsCategory,deleteMarketAndSolutionsCategory } from '../Apis/marketsApi';
import { MainCategoryTableColumnData,subCategoryList, SubCategoryTableColumnData,marketSolutions, storyList,dataList,faqList } from '../../Types/TableType';


interface MainCategoryResponse {
    market_solution_code:string,
    main_category_code:string,
    main_category_name:string,
    sub_category_code:string,
    sub_category_name:string,
    description: string,
    banner_image:string,
    left_image:string,
    right_image:string,
    story_list:storyList[],
    data_list:dataList[],
    faq_list:faqList[],
}

interface State {
    data: {
        [key: string]: MainCategoryResponse; // Allow dynamic keys
    };
    byCode :{
        [key: string]: MainCategoryResponse;
    }
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    byCode:{},
    isLoading: false,
    error: null,
};

// Async thunk to fetch milestone data
export const fetchMarketData = createAsyncThunk(
    'Markets/fetchMarketData',
    async () => {
        const MainCategory = await fetchMarketAndSolutionsCategory();
        console.log("API response inside fetchMainCategoryData:", MainCategory); // Log the API response
        return MainCategory; // Return the nested MainCategory data
    }
);

export const fetchMarketDataByCode = createAsyncThunk(
    'Markets/fetchMarketDataByCode',
    async (category_code :string) => {
        const MainCategory = await fetchMarketAndSolutionsCategoryByCode(category_code);
        console.log("API response inside fetchMainCategoryData:", MainCategory); // Log the API response
        return MainCategory; // Return the nested MainCategory data
    }
);





export const deleteMarketsData = createAsyncThunk(
    "Markets/deleteMarketsData",
    async (Markets_code: string) => {
        const data = await deleteMarketAndSolutionsCategory(Markets_code);
        return data;
    }
);


// MarketsReducer.tsx
export const createMarketsData = createAsyncThunk(
    "Markets/createMarketsData",
    async (formData: FormData) => { // Expect FormData as the input
        const data = await createMarketAndSolutionsCategory(formData); // Pass it directly to createMarkets
        return data;
    }
);




export const updateMarketsData = createAsyncThunk(
    "Markets/updateMarketsData",
    async (formData: FormData) => {
        const data = await updateMarketAndSolutionsCategory(formData); 
        return data;
    }
);

const MarketsSlice = createSlice({
    name: 'Markets',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchMarketData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchMarketData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data;
            })
            .addCase(fetchMarketData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(fetchMarketDataByCode.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchMarketDataByCode.fulfilled, (state, action) => {
                state.isLoading = false;
                state.byCode = action.payload.data;
            })
            .addCase(fetchMarketDataByCode.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(createMarketsData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createMarketsData.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(createMarketsData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(updateMarketsData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateMarketsData.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(updateMarketsData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            .addCase(deleteMarketsData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteMarketsData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error?.message ?? null;
            })
            
    },
});

export default MarketsSlice.reducer;

