import React, { useState } from 'react';
import { Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from '../../../../../redux-toolkit/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Btn } from '../../../../../AbstractElements';
import {TestimonialTableColumnData } from '../../../../../Types/TableType';
import { createTestimonialData, fetchData } from '../../../../../redux-toolkit/reducers/TestimonialReducer';

export default function CreateNewTestimonialForm() {
    const [testinomialImage, setTestinomialImage] = useState<File | null>(null); // Store file
    // const [testinomialImageNew, setTestinomialImageNew] = useState<File | null>(null); // Store file
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setTestinomialImage(e.target.files[0]); // Set file to state
        }
    };
    // const handleFileChangeNew = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     if (e.target.files && e.target.files.length > 0) {
    //         setTestinomialImageNew(e.target.files[0]); // Set file to state
    //     }
    // };

    const { register, handleSubmit} = useForm<TestimonialTableColumnData>();
    const addMilestone: SubmitHandler<TestimonialTableColumnData> = (data) => {
        const formData = new FormData();
        formData.append('testimonial_auth_name', data.testimonial_auth_name);
        formData.append('testimonial_description', data.testimonial_description);
        formData.append('testimonial_auth_designation', data.testimonial_auth_designation);

        if (testinomialImage) {
            formData.append('testimonial_auth_image', testinomialImage); // Add image to formData without []
        }
        // if(testinomialImageNew){
        //     formData.append('testimonial_image', testinomialImageNew); // Add image to formData
        // }

        dispatch(createTestimonialData(formData))
            .then(() => {
                dispatch(fetchData());
                navigate(`${process.env.PUBLIC_URL}/applications/testimonial/testimonial-list`);
            })
            .catch((error) => {
                console.error("Failed to create testimonial:", error);
            });
    };

    return (
        <Form className="theme-form" onSubmit={handleSubmit(addMilestone)}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Testimonial Name</Label>
                        <input className="form-control" type="text" placeholder="Enter Testimonial Name *" {...register("testimonial_auth_name", { required: true })} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Testimonial Description</Label>
                        <input className="form-control" type="text" placeholder="Enter Testimonial Description" {...register("testimonial_description", { required: true })} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Testimonial Designation</Label>
                        <input className="form-control" type="text" placeholder="Enter Testimonial Designation *" {...register("testimonial_auth_designation", { required: true })} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Testimonial User Image</Label>
                        <input 
                            className="form-control" 
                            type="file" 
                            onChange={handleFileChange} // Handle manually instead of registering with useForm
                        />
                    </FormGroup>
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <FormGroup>
                        <Label>Testimonial Image</Label>
                        <input 
                            className="form-control" 
                            type="file" 
                            onChange={handleFileChangeNew} // Handle manually instead of registering with useForm
                        />
                    </FormGroup>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <div className="text-end">
                        <Btn color="success" className="me-3">Add</Btn>
                        <Btn color="danger">Cancel</Btn>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}
