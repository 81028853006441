import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchContactus } from '../Apis/contactApi';
import { contactData } from '../../Types/TableType';


interface ContactResponse {
    _id: string;
  name: string;
  email: string;
  phoneno: string;
  subject: string;
  message: string;
}

interface State {
    data: {
        [key: string]: ContactResponse; // Allow dynamic keys
    };
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch milestone data
export const fetchData = createAsyncThunk(
    'contactUs/fetchData',
    async () => {
        const Contact = await fetchContactus();
        console.log("API response inside fetchData:", Contact); // Log the API response
        return Contact; // Return the nested Contact data
    }
);

const TestimonialSlice = createSlice({
    name: 'Contact',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Store the fetched data in state
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.isLoading = false;
                // .state.error = action.error.message ?? null; // Assign null if message is undefined
 
            })
    },
});

export default TestimonialSlice.reducer;
