import React from 'react'
import ServiceCreateContainer from '../../../../Components/Apps/Services/ServiceCreate/CreateNewServices'

export default function ServiceCreate() {
  return (
    <div className='page-body'>
        <ServiceCreateContainer/>
    </div>
  )
}
