import React from 'react';
import TechnologyListContainer from '../../../../Components/Apps/Technology/TechnologyList'; // Adjust the path as necessary

export default function TechnologyList() {
  return (
    <div className='page-body'>
      <TechnologyListContainer />
    </div>
  );
}
