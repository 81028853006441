import React, { useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker';

export default function DateRange() {
    const [value, setValue] = useState<DateObject[]>([new DateObject()]);

    return (
        <DatePicker inputClass="form-control" range value={value}
            onChange={(dates) => setValue(dates as DateObject[])} />
    )
}