import React from 'react'
import UserCardsContainer from '../../../../Components/Apps/Users/UserCards'

export default function UserCards() {
  return (
    <div className='page-body'>
        <UserCardsContainer/>
    </div>
  )
}
