import React from 'react'
import { Col, Row } from 'reactstrap'
import WelcomeCard from './WelcomeCard'
import TotalEarningCards from '../../../../Utils/CommonComponents/DashboardsCommon/DefaultCommon/TotalEarningCards'
import NewsUpdate from './NewsUpdate'
import ProductStock from './ProductStock'

export default function LeftBackground() {
    return (
        <Col xxl={4} xl={100} className="box-col-12 ps-4 pe-4 left-background">
            <Row className="bg-light h-100 p-3 pt-4 pb-4">
                <WelcomeCard />
                {/* <Col xs={12} xl={50} className="box-col-6">
                    <TotalEarningCards colClass='col-md-6' />
                </Col>
                <NewsUpdate />
                <ProductStock /> */}
            </Row>
        </Col>
    )
}
