import React from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CheckCircle, Info, PlusSquare, Target } from 'react-feather';
import { ProjectListNavProps } from '../../../../../Types/ProjectType';
// import { All, CreateNewProduct, Doing, Done, Href } from '../../../../../Utils/Constants'; // Updated for products

export default function ProductListNav({ activeTab, setActiveTab }: ProjectListNavProps) {
    return (
        <Card>
            <Row>
                <Col md={6} className="p-0">
                    {/* 
                    <Nav className="border-tab d-flex" tabs>
                        <NavItem>
                            <NavLink
                                href={Href}
                                className={activeTab === "1" ? "active" : ""}
                                onClick={() => setActiveTab("1")}
                            >
                                <Target /> {All} 
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href={Href}
                                className={activeTab === "2" ? "active" : ""}
                                onClick={() => setActiveTab("2")}
                            >
                                <Info /> {Doing} 
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                href={Href}
                                className={activeTab === "3" ? "active" : ""}
                                onClick={() => setActiveTab("3")}
                            >
                                <CheckCircle /> {Done} 
                            </NavLink>
                        </NavItem>
                    </Nav> 
                    */}
                </Col>
                <Col md={6} className="p-0 d-flex justify-content-end">
                    <Link
                        className="btn btn-primary"
                        to={`${process.env.PUBLIC_URL}/applications/Product/create-product`} // Updated URL for product creation
                        style={{ marginRight: '50px' }} // Add 20px right margin
                    >
                        <PlusSquare /> Create a Product {/* Button for creating new product */}
                    </Link>
                </Col>
            </Row>
        </Card>
    );
}
