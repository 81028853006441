import React from 'react'
import DetailedCourseContainer from '../../../../Components/Miscellaneous/Learning/DetailedCourse'

export default function DetailedCourse() {
  return (
    <div className='page-body'>
        <DetailedCourseContainer/>
    </div>
  )
}
