import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTestimonial,deleteTestimonial,createTestimonial,updateTestimonial } from '../Apis/testimonialApi';
import { TestimonialTableColumnData } from '../../Types/TableType';


interface TestimonialResponse {
    testimonial_auth_name:string,
    testimonial_description:string,
    testimonial_auth_designation:string,
    testimonial_auth_image:string,
    testimonial_code: string,
    testimonial_image:string,
}

interface State {
    data: {
        [key: string]: TestimonialResponse; // Allow dynamic keys
    };
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch milestone data
export const fetchData = createAsyncThunk(
    'Testimonial/fetchData',
    async () => {
        const Testimonial = await fetchTestimonial();
        console.log("API response inside fetchData:", Testimonial); // Log the API response
        return Testimonial; // Return the nested Testimonial data
    }
);

export const deleteTestimonialData = createAsyncThunk(
    "Testimonial/deleteTestimonialData",
    async (testimonial_code: string) => {
        const data = await deleteTestimonial(testimonial_code);
        return data;
    }
);

// TestimonialReducer.tsx
export const createTestimonialData = createAsyncThunk(
    "Testimonial/createTestimonialData",
    async (formData: FormData) => { // Expect FormData as the input
        const data = await createTestimonial(formData); // Pass it directly to createTestimonial
        return data;
    }
);


export const updateTestimonialData = createAsyncThunk(
    "Testimonial/updateTestimonialData",
    async (formData: TestimonialTableColumnData) => {
        const data = await updateTestimonial(formData); 
        return data;
    }
);


const TestimonialSlice = createSlice({
    name: 'Testimonial',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Store the fetched data in state
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.isLoading = false;
                // .state.error = action.error.message ?? null; // Assign null if message is undefined
 
            })
            .addCase(createTestimonialData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createTestimonialData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, you can update the state with the new milestone if needed
                // state.data = { ...state.data, [action.payload._id]: action.payload }; // Assuming the response has _id
            })
            .addCase(createTestimonialData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(updateTestimonialData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateTestimonialData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the specific milestone in the state
                // state.data[action.payload._id] = action.payload; // Assuming the response has _id
            })
            .addCase(updateTestimonialData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(deleteTestimonialData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteTestimonialData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            });
    },
});

export default TestimonialSlice.reducer;
