import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { TableColumn } from "react-data-table-component";
import CardHeaderSpan from '../../../../../Utils/CommonComponents/CardHeaderSpan';
import { MilestoneTitle } from '../../../../../Utils/Constants';
import FilterComponent from '../../../../Tables/DataTables/Common/FilterComponent';
import { fetchData, deleteMilestonesData, updateMilestonesData } from '../../../../../redux-toolkit/reducers/MilestonesReducer';

export interface RowCreateCallBackData {
    am_name: string;
    am_count: string;
    am_Status: string;
    am_code: string;
}

export default function RowCreateCallback() {
    const [filterText, setFilterText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
    const [selectedMilestone, setSelectedMilestone] = useState<RowCreateCallBackData | null>(null); // Selected milestone state
    const [editData, setEditData] = useState<RowCreateCallBackData>({
        am_name: '',
        am_count: '',
        am_Status: '',
        am_code: '',
    }); // Initialize edit data

    const dispatch: AppDispatch = useDispatch();
    
    const milestones = useSelector((state: RootState) => state.milestones);
    const milestonesData: RowCreateCallBackData[] = Array.isArray(milestones?.data) 
        ? milestones.data 
        : Object.values(milestones?.data || {});

    useEffect(() => {
        const fetchMilestones = async () => {
            try {
                await dispatch(fetchData());
            } catch (error) {
                console.error("Error fetching milestones:", error);
            }
        };

        fetchMilestones();
    }, [dispatch]);

    const filteredItems: RowCreateCallBackData[] = milestonesData.filter(
        (item) =>
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );

    // Handle opening the edit modal and populate form with selected row data
    const handleEdit = (row: RowCreateCallBackData) => {
        setSelectedMilestone(row); // Set the selected milestone
        setEditData(row); // Populate form with selected data
        setIsModalOpen(true); // Open the modal
    };
    

    // Handle the form field changes for editing
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Handle form submission to update the milestone
    const handleSubmit = async () => {
        try {
            await dispatch(updateMilestonesData(editData));  // Dispatch update action
            dispatch(fetchData());  // Refetch the updated data
            setIsModalOpen(false);  // Close the modal
        } catch (error) {
            console.error("Failed to update:", error);
        }
    };

    const handleDelete = (row: RowCreateCallBackData) => {
        dispatch(deleteMilestonesData(row.am_code))
            .then(() => {
                dispatch(fetchData());
            })
            .catch((error) => {
                console.error("Failed to delete:", error);
            });
    };

    const rowMilestonesCallBackTableColumns: TableColumn<RowCreateCallBackData>[] = [
        {
            name: "Milestone Name",
            selector: (row: RowCreateCallBackData) => row.am_name,
            sortable: true,
        },
        {
            name: "Milestone Count",
            selector: (row: RowCreateCallBackData) => row.am_count,
            sortable: true,
        },
        {
            name: "Milestone Status",
            selector: (row: RowCreateCallBackData) => row.am_Status,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row: RowCreateCallBackData) => (
                <div>
                    <button onClick={() => handleEdit(row)} className="btn btn-primary btn-sm">
                        Edit
                    </button>
                    <button onClick={() => handleDelete(row)} className="btn btn-danger btn-sm ms-2">
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    return (
        <Col sm={12}>
            <Card>
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={MilestoneTitle} />
                <CardBody>
                    <FilterComponent
                        onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                        onClear={() => setFilterText('')}
                        filterText={filterText}
                    />
                    <div className="table-responsive custom-scrollbar">
                        <DataTable 
                            data={filteredItems} 
                            columns={rowMilestonesCallBackTableColumns} 
                            striped 
                            pagination 
                            className="display dataTable" 
                        />
                    </div>
                </CardBody>
            </Card>

            {/* Edit Modal */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
                <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Milestone</ModalHeader>
                <ModalBody>
                    {selectedMilestone && (
                        <Form>
                            <FormGroup>
                                <Label for="am_name">Milestone Name</Label>
                                <Input
                                    type="text"
                                    name="am_name"
                                    value={editData.am_name || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="am_count">Milestone Count</Label>
                                <Input
                                    type="number"
                                    name="am_count"
                                    value={editData.am_count || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            {/* <FormGroup>
                                <Label for="am_Status">Milestone Status</Label>
                                <Input
                                    type="text"
                                    name="am_Status"
                                    value={editData.am_Status || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup> */}
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Col>
    );
}
