import React from 'react'
import ImageCropperContainer from '../../../Components/BonusUi/ImageCropper'

export default function ImageCropper() {
  return (
    <div className='page-body'>
        <ImageCropperContainer/>
    </div>
  )
}
