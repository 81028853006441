import React from 'react';
import { Link, useNavigate } from 'react-router-dom'
import { Image, LI, P, UL } from '../../../AbstractElements'
import {useDispatch} from 'react-redux';
import { toast } from 'react-toastify';
import { AppDispatch } from '../../../redux-toolkit/store';
import { Admin, HelenWalter, Href, Logout } from '../../../Utils/Constants'
import { profileHeaderData } from '../../../Data/HeaderData/RightHeaderData'
import FeatherIconCom from '../../../Utils/CommonComponents/CommonIcons/FeatherIconCom'
import { dynamicImage } from '../../../Utils';
import { fetchAdminLogout } from '../../../redux-toolkit/reducers/LoginReducer';

export default function ProfileHeader() {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const logout = async() => {
        dispatch(fetchAdminLogout())
        .then(()=>{
            toast.success("Logout Success...!");
            navigate(`${process.env.PUBLIC_URL}/login`);
            localStorage.clear();
        })
        .catch((error: any) => {
            toast.error("Logout failed: " + error.message);
        });  
    }
    return (
        <LI className="profile-nav onhover-dropdown p-0">
            <div className="d-flex align-items-center profile-media">
                <Image className="b-r-10 img-40" src={dynamicImage(`dashboard/profile.png`)} alt="" />
                <div className="flex-grow-1"><span>Athena Security</span>
                    <P className="mb-0">{Admin} <i className="middle fa fa-angle-down"></i></P>
                </div>
            </div>
            <UL className="profile-dropdown onhover-show-div simple-list">
                {/* {profileHeaderData.map((data) => (
                    <LI key={data.id}>
                        <Link to={Href}>
                            <FeatherIconCom iconName={data.icon} />
                            <span>{data.text}</span></Link>
                    </LI>
                ))} */}
                <LI>
                <div onClick={logout} style={{ cursor: "pointer" }}>
                    <FeatherIconCom iconName={"LogIn"} />
                    <span>{Logout}</span>
                </div>
                </LI>
            </UL>
        </LI>
    )
}
