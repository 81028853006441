import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from '../../../../../../redux-toolkit/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Add, Cancel, EnterSomeDetails } from '../../../../../../Utils/Constants';
import { Btn } from '../../../../../../AbstractElements';
import { ServiceDataCallBackData } from '../../../../../../Types/TableType'; // Replace this with the correct type for service data
import { createServiceData, fetchServicesData } from '../../../../../../redux-toolkit/reducers/ServiceReducer';

export default function CreateNewServiceForm() {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [serviceImage, setServiceImage] = useState<File | null>(null); // Store file for image_url

    const { register, handleSubmit, formState: { errors }, } = useForm<ServiceDataCallBackData>();

    // Handle file input change for image_url
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setServiceImage(e.target.files[0]); // Store the file in state
        }
    };

    const addService: SubmitHandler<ServiceDataCallBackData> = (data) => {
        const formData = new FormData();
        formData.append('image_title', data.image_title);
        formData.append('serviceSubject', data.serviceSubject);
        formData.append('image_description', data.image_description);

        if (serviceImage) {
            formData.append('image_url', serviceImage); // Add image file to formData
        }

        dispatch(createServiceData(formData)) // Send formData instead of raw data
            .then(() => {
                dispatch(fetchServicesData());
                navigate(`${process.env.PUBLIC_URL}/applications/Service/service-list`);
            })
            .catch((error) => {
                console.error("Failed to add service:", error);
            });
    };

    return (
        <Form className="theme-form" onSubmit={handleSubmit(addService)}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Service Name</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Service Name *"
                            {...register("image_title", { required: true })}
                        />
                        {errors.image_title && <span style={{ color: "red" }}>Service Name is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Service Subject</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter the Service Type"
                            {...register("serviceSubject", { required: true })}
                        />
                        {errors.serviceSubject && <span style={{ color: "red" }}>Service Type is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Service image Description</Label>
                        <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Enter Service Description *"
                            {...register("image_description", { required: true })}
                        />
                        {errors.image_description && <span style={{ color: "red" }}>Service Description is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Service Image</Label>
                        <input
                            className="form-control"
                            type="file"
                            onChange={handleFileChange} // Handle file input change
                        />
                        {errors.image_url && <span style={{ color: "red" }}>Service Image is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-end">
                        <Btn color="success" className="me-3">{Add}</Btn>
                        <Btn color="danger">{Cancel}</Btn>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}
