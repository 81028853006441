import React from 'react'
import OwlCarouselContainer from '../../../Components/BonusUi/OwlCarousel'

export default function OwlCarousel() {
  return (
    <div className='page-body'>
        <OwlCarouselContainer/>
    </div>
  )
}
