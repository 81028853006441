import { ProjectListDataProp } from "../../../Types/ProjectType";

export const allProjectListData: ProjectListDataProp[] = [
    {
        id: 1,
        title: "Enzo admin Design",
        badge: "Doing",
        image: "user/3.jpg",
        sites: "Themeforest, australia",
        description: "Enzo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "12",
        resolved: "5",
        comment: "7",
        like: "10",
        progress: "70",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 2,
        title: "Govo admin Design",
        badge: "Done",
        image: "user/5.jpg",
        sites: "Themeforest, australia",
        description: "Govo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "24",
        resolved: "24",
        comment: "40",
        like: "3",
        progress: "100",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 3,
        title: "Xolo admin Design",
        badge: "Done",
        image: "user/4.jpg",
        sites: "Themeforest, australia",
        description: "Xolo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "40",
        resolved: "40",
        comment: "20",
        like: "2",
        progress: "100",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 4,
        title: "Zeta admin Design",
        badge: "Done",
        image: "user/1.jpg",
        sites: "Themeforest, australia",
        description: "Zeta Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "24",
        resolved: "24",
        comment: "40",
        like: "3",
        progress: "100",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 5,
        title: "Tivo admin Design",
        badge: "Doing",
        image: "user/5.jpg",
        sites: "Themeforest, australia",
        description: "Tivo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "12",
        resolved: "5",
        comment: "7",
        like: "10",
        progress: "70",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 6,
        title: "Enzo admin Design",
        badge: "Done",
        image: "user/3.jpg",
        sites: "Themeforest, australia",
        description: "Enzo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "40",
        resolved: "40",
        comment: "20",
        like: "2",
        progress: "100",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 7,
        title: "Govo admin Design",
        badge: "Doing",
        image: "user/5.jpg",
        sites: "Themeforest, australia",
        description: "Govo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "24",
        resolved: "24",
        comment: "40",
        like: "3",
        progress: "100",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 8,
        title: "Poco admin Design",
        badge: "Doing",
        image: "user/3.jpg",
        sites: "Themeforest, australia",
        description: "Poco Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "40",
        resolved: "40",
        comment: "20",
        like: "2",
        progress: "100",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 9,
        title: "Tivo admin Design",
        badge: "Doing",
        image: "user/5.jpg",
        sites: "Themeforest, australia",
        description: "Tivo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "24",
        resolved: "24",
        comment: "40",
        like: "3",
        progress: "100",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
    {
        id: 10,
        title: "Enzo admin Design",
        badge: "Doing",
        image: "user/3.jpg",
        sites: "Themeforest, australia",
        description: "Enzo Admin is a full featured, multipurpose, premium bootstrap admin template.",
        issue: "12",
        resolved: "5",
        comment: "7",
        like: "10",
        progress: "70",
        customer1: "user/3.jpg",
        customer2: "user/5.jpg",
        customer3: "user/1.jpg"
    },
]