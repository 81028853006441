import { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Button } from 'reactstrap'
import { Crypto, Dashboard, Ecommerce, Href, Project } from '../../../../Utils/Constants'

export default function BasicDemo() {
    return (
        <div className="btn-group">
            {/* Replace dropdown with a simple button or anchor tag */}
            <Button color="primary" href={`${process.env.PUBLIC_URL}/dashboard`}>
                {Dashboard}
            </Button>
        </div>
    );
}
