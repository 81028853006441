import React from 'react'
import ContactsContainer from '../../../Components/Apps/Contacts'

export default function Contacts() {
  return (
    <div className='page-body'>
        <ContactsContainer/>
    </div>
  )
}
