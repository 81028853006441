import React from 'react';
import TechnologyCreateContainer from '../../../../Components/Apps/Technology/TechnologyCreate'; // Adjust the path as necessary

export default function TechnologyCreate() {
  return (
    <div className='page-body'>
      <TechnologyCreateContainer />
    </div>
  );
}
