import React from 'react'
import ApexChartContainer from '../../../Components/Charts/ApexChart'

export default function ApexChart() {
  
  return (
    <div className='page-body'>
        <ApexChartContainer/>
    </div>
  )
}
