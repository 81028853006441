import React from 'react'
import GoogleMapsContainer from '../../../../Components/Miscellaneous/Maps/GoogleMaps'

export default function GoogleMaps() {
    return (
        <div className='page-body'>
            <GoogleMapsContainer />
        </div>
    )
}
