import React, { useState, useEffect } from 'react';
import { Col, Form, FormGroup, Label, Row, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router';
import { Btn } from '../../../../../AbstractElements';
import { TestimonialTableColumnData, MainCategoryTableColumnData } from '../../../../../Types/TableType';
import { createTestimonialData, fetchData } from '../../../../../redux-toolkit/reducers/TestimonialReducer';
import { fetchMainCategoryDataCurrent, fetchMainCategoryData, fetchMainCategoryDataBycode,createMainCategoryData } from '../../../../../redux-toolkit/reducers/marketSolutionsReducer';
import { createSubCategoryData } from '../../../../../redux-toolkit/reducers/subCategoryReducer';
import { createMarketsData } from '../../../../../redux-toolkit/reducers/MarketReducer';
import Select from 'react-select';
import { FiPlus } from 'react-icons/fi';
import styles from './create.module.css'
import { Editor } from "primereact/editor"; // PrimeReact Editor
import 'quill/dist/quill.snow.css';
import { toast } from 'react-toastify';


interface dataForm {
    main_category_code:string,
    main_category_name:string,
    sub_category_code:string,
    sub_category_name:string,
    description:string,
    banner_image:string,
    left_image:string,
    right_text:string,
    right_image:string,
    left_text:string,
    story_list: Array<{ story_title: string; story_description: string }>;
    data_list: Array<{ data_title: string; data_description: string }>;
    faq_list: Array<{faq_title: string; faq_description: string}>;
    
}

export default function CreateNewTestimonialForm() {
    const [testinomialImage, setTestinomialImage] = useState<File | null>(null); // Store file
    const [isModalOpen, setModalOpen] = useState(false); // Modal state
    const [isModalSubOpen, setModalSubOpen] = useState(false); // Modal state
    const [newOption, setNewOption] = useState({ category_name: '', category_image:'' }); // New option state
    const [newOptionSub, setNewOptionSub] = useState({ sub_category_name: '',main_category_code:'', sub_category_description: '', sub_category_image:'' });
    const [formData, setFormData] = useState<any | null>(null);
    const [dropdownOptions, setDropdownOptions] = useState<any[]>([]);
    const [newDropdownOptions, setNewDropdownOptions] = useState<any[]>([]);
    const [newformDatas, setNewFormData] = useState<dataForm>({
        main_category_code:'',
        main_category_name:'',
        sub_category_code:'',
        sub_category_name:'',
        description:'',
        banner_image:'',
        left_image:'',
        right_text:'',
        right_image:'',
        left_text:'',
        story_list:[],
        data_list:[],
        faq_list:[],
    })
    const [story_list, setStories] = useState<
    Array<{ story_title: string; story_description: string }>
  >([{ story_title: "", story_description: "" }]);
  const [data_list, setData] = useState<
    Array<{ data_title: string; data_description: string }>
  >([{ data_title: "", data_description: "" }]);
  const [faq_list, setFaq] = useState<
    Array<{ faq_title: string; faq_description: string }>
  >([{ faq_title: "", faq_description: "" }]);
    console.log(newformDatas,"newformDatas")
    const [bannerImagePreview, setBannerImagePreview] = useState<string | null>(null);
    const [bannerImage, setBannerImage] = useState<File | null>(null);
    const [leftImage, setLeftImage] = useState<string | null>(null);
    const [leftNewImage, setLeftNewImage] = useState<File | null>(null);
    const [rightImage, setRightImage] = useState<string | null>(null);
    const [rightNewImage, setRightNewImage] = useState<File | null>(null);

    const handleBannerImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setBannerImage(e.target.files[0]);
            setBannerImagePreview(URL.createObjectURL(file)); // Preview the image
        }
    };
    const handleLeftImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setLeftNewImage(e.target.files[0])
            setLeftImage(URL.createObjectURL(file)); // Preview the image
        }
    };
    const handleRightImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setRightNewImage(e.target.files[0])
            setRightImage(URL.createObjectURL(file)); // Preview the image
        }
    };

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const MainCategory = useSelector((state: RootState) => state.MainCategory);
    const MainCategoryDataInfo: MainCategoryTableColumnData[] = Array.isArray(MainCategory?.data)
        ? MainCategory.data
        : Object.values(MainCategory?.data || {});

    useEffect(() => {
        const fetchMilestones = async () => {
            if (!MainCategoryDataInfo || MainCategoryDataInfo.length === 0) {
                try {
                    await dispatch(fetchMainCategoryData());
                } catch (error) {
                    console.error("Error fetching category data:", error);
                }
            }
        };
        fetchMilestones();
    }, [dispatch, MainCategoryDataInfo]);

    useEffect(() => {
        if (MainCategoryDataInfo && MainCategoryDataInfo.length > 0) {
            const options = MainCategoryDataInfo.map((category: MainCategoryTableColumnData) => ({
                value: category.category_code,
                label: category.category_name
            }));
            setDropdownOptions(options);
        }
    }, [MainCategoryDataInfo]);
    
    const addStory = () => {
        setStories([...story_list, { story_title: "", story_description: "" }]);
      };
    
      const removeStory = (index: number) => {
        const updatedStories = story_list.filter((_, i) => i !== index);
        setStories(updatedStories);
      };
    
      const handleStoryInputChange = (
        index: number,
        field: "story_title" | "story_description",
        value: string
      ) => {
        setStories((prevStories) => {
          const updatedStories = prevStories.map((story, idx) => {
            if (idx === index) {
              return { ...story, [field]: value };
            }
            return story;
          });
          return updatedStories;
        });
      };
    
      const addStoryData = () => {
        setData([...data_list, { data_title: "", data_description: "" }]);
      };
    
      const removeStoryData = (index: number) => {
        const updatedData = data_list.filter((_, i) => i !== index);
        setData(updatedData);
      };

      const addFaqData = () => {
        setFaq([...faq_list, { faq_title: "", faq_description: "" }]);
      };

      const removeFaqData = (index: number) => {
        const updatedData = faq_list.filter((_, i) => i !== index);
        setFaq(updatedData);
      };
    
      const handleStoryDataInputChange = (
        index: number,
        field: "data_title" | "data_description",
        value: string
      ) => {
        setData((prevData) => {
          const updatedData = prevData.map((story, idx) => {
            if (idx === index) {
              return { ...story, [field]: value };
            }
            return story;
          });
          return updatedData;
        });
      };

      const handleFAQInputChange = (
        index: number,
        field: "faq_title" | "faq_description",
        value: string
      ) => {
        setFaq((prevFaq) => {
          const updatedData = prevFaq.map((story, idx) => {
            if (idx === index) {
              return { ...story, [field]: value };
            }
            return story;
          });
          return updatedData;
        });
      };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setTestinomialImage(e.target.files[0]);
        }
    };

    const { register, handleSubmit, setValue } = useForm<dataForm>();
    const addMilestone: SubmitHandler<dataForm> = (data) => {
        const formData = new FormData();
        formData.append('main_category_name', newformDatas.main_category_name);
        formData.append('sub_category_name', newformDatas.sub_category_name);
        formData.append('main_category_code',newformDatas.main_category_code);
        formData.append('sub_category_code', newformDatas.sub_category_code);
        formData.append('description', data.description);
        if(bannerImage){
            formData.append('banner_image', bannerImage);
        }
        if(leftNewImage){
            formData.append('left_image', leftNewImage);
        }
        formData.append('story_list',JSON.stringify(story_list))
        if(rightNewImage){
            formData.append('right_image', rightNewImage);
        }
        formData.append('data_list',JSON.stringify(data_list))
        formData.append('faq_list', JSON.stringify(faq_list));

        dispatch(createMarketsData(formData))
            .then(() => {
                dispatch(fetchMainCategoryData())
                .then(()=>{
                    toast.success(`Created Successfully`);
                    navigate(`${process.env.PUBLIC_URL}/applications/MainCategory/List`);
                })
               
            })
            .catch((error) => {
                console.error("Failed to create Markets:", error);
            });
    };

    const toggleModal = () => {
        setModalOpen(!isModalOpen);
    };

    const toggleModalSub = () => {
        setModalSubOpen(!isModalSubOpen);
    };

    const handleFileChangePopup = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setNewOption((prevData: any) => ({
                ...prevData,
                category_image: file,
            }));
        }
    };
    const handleFileChangePopupSub = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setNewOptionSub((prevData: any) => ({
                ...prevData,
                sub_category_image: file,
            }));
        }
    };

    const handleAddNew = async() => {
        if (newOption.category_name) {
            const formData = new FormData();
            formData.append('category_name', newOption.category_name);
            
            formData.append('category_image', newOption.category_image);
            await dispatch(createMainCategoryData(formData))
            .then(() => {
                toast.success(`Created Successfully`);
                setNewOption({category_name: '', category_image: ''})
                setModalOpen(false)
                dispatch(fetchMainCategoryData())
            })
            .catch((error) => {
                console.error("Failed to create Main Category:", error);
            });
            setModalOpen(false);
        }
    };

    

    useEffect(() => {
        const fetchSubCategoryData = async () => {
            if (formData) {
                try {
                    const response = await dispatch(fetchMainCategoryDataBycode(formData));
                    const responseData = (response as any)?.payload?.data || [];
                    
                    console.log("API response data:", responseData); // Log the response to verify
    
                    // Extract sub_category_data from each category in the array
                    const allSubCategoryData = responseData.flatMap((category: any) => category.sub_category_data || []);
    
                    const options = allSubCategoryData.map((subCategory: any) => ({
                        value: subCategory.sub_category_code,
                        label: subCategory.sub_category_name
                    }));
                    
                    setNewDropdownOptions(options);
                } catch (error) {
                    console.error("Failed to fetch main category data:", error);
                }
            }
        };
        fetchSubCategoryData();
    }, [dispatch, formData]);

    
    const fetchSubCategoryData = async (mainCategoryCode:any) => {
        if (mainCategoryCode) {
            try {
                const response = await dispatch(fetchMainCategoryDataBycode(mainCategoryCode));
                const responseData = (response as any)?.payload?.data || [];
                const allSubCategoryData = responseData.flatMap((category: any) => category.sub_category_data || []);
                const options = allSubCategoryData.map((subCategory: any) => ({
                    value: subCategory.sub_category_code,
                    label: subCategory.sub_category_name
                }));
                setNewDropdownOptions(options);
            } catch (error) {
                console.error("Failed to fetch main category data:", error);
            }
        }
    };

    const handleAddNewSub = async () => {
        if (newOptionSub.sub_category_name) {
            const formData = new FormData();
            formData.append('sub_category_name', newOptionSub.sub_category_name);
            formData.append('sub_category_image', newOptionSub.sub_category_image);
            formData.append('sub_category_description', newOptionSub.sub_category_description);
            formData.append('main_category_code', newOptionSub.main_category_code);

            await dispatch(createSubCategoryData(formData))
                .then(() => {
                    toast.success(`Created Successfully`);
                    setNewOptionSub({
                        sub_category_name: '',
                        main_category_code: '',
                        sub_category_description: '',
                        sub_category_image: ''
                    });
                    setModalSubOpen(false);

                    // Fetch main category data and update dropdown options
                    if (newOptionSub.main_category_code) {
                        fetchSubCategoryData(newOptionSub.main_category_code);
                    }
                })
                .catch((error) => {
                    console.error("Failed to create Main Category:", error);
                });

            setModalOpen(false);
        }
    };


    const handleDropdownChange = (selectedOption: any) => {
        if (selectedOption && selectedOption.value === 'addNew') {
            toggleModal();
        } else {
            setValue('main_category_name', selectedOption?.value || '');
            setFormData(selectedOption.value);
            setNewFormData((prevData: any) => ({
                ...prevData,
                main_category_code: selectedOption?.value,
                main_category_name: selectedOption?.label
            }));
        }
    };

    const handleNewDropdownChange = (selectedOption: any) => {
        if (selectedOption && selectedOption.value === 'addNew') {
            toggleModalSub();
        } else {
            setValue('main_category_name', selectedOption?.value || '');
            setNewFormData((prevData: any) => ({
                ...prevData,
                sub_category_code: selectedOption?.value,
                sub_category_name: selectedOption?.label
            }));
        }
    };

    const customDropdownOptions = [
         { value: 'addNew', label: 'Add New' },
        ...dropdownOptions,
       
    ];

    const customNewDropdownOptions = [
        { value: 'addNew', label: 'Add New' },
        ...newDropdownOptions,
        
    ];

    return (
        <Form className="theme-form" onSubmit={handleSubmit(addMilestone)}>
    <Row>
        <Col md="6">
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label for="main_category">Select Main Category</Label>
                        <Select
                            options={customDropdownOptions}
                            onChange={handleDropdownChange}
                            placeholder="Search or select an option"
                        />
                    </FormGroup>
                </CardBody>
            </Card>
        </Col>
        <Col md="6">
            <Card>
                <CardBody>
                    <FormGroup>
                        <Label for="sub_category">Select Sub Category</Label>
                        <Select
                            options={customNewDropdownOptions}
                            onChange={handleNewDropdownChange}
                            placeholder="Search or select an option"
                        />
                    </FormGroup>
                </CardBody>
            </Card>
        </Col>

        <Col md="6">
            <FormGroup>
                <Label>Main Category Name</Label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Category Name*"
                    value={newformDatas.main_category_name || ''}
                    readOnly
                    {...register("main_category_name", { required: true })}
                />
            </FormGroup>
        </Col>
        <Col md="6">
            <FormGroup>
                <Label>Sub Category Name</Label>
                <input
                    className="form-control"
                    type="text"
                    placeholder="Enter Sub Category Name*"
                    value={newformDatas.sub_category_name || ''}
                    readOnly
                    {...register("sub_category_name", { required: true })}
                />
            </FormGroup>
        </Col>
    </Row>
    <Row>
        <Col md="12">
            <FormGroup>
                <Label>Description</Label>
                <textarea className="form-control"
                    placeholder="Enter Description*"
                    {...register("description", { required: true })}>

                </textarea>
            </FormGroup>
        </Col>
    </Row>

    {/* New Banner Image Section */}
    <Row>
            <Col md="12">
                <FormGroup>
                    <Label>Banner Image</Label>
                    <div className={styles['banner-upload-wrapper']}>
                        {bannerImagePreview ? (
                            <img src={bannerImagePreview} alt="Banner Preview" />
                        ) : (
                            <div className={styles['banner-upload-placeholder']}>
                                <FiPlus className={styles['banner-upload-icon']} />
                                <span>Upload Image</span>
                            </div>
                        )}
                        <input
                            type="file"
                            onChange={handleBannerImageChange}
                        />
                    </div>
                </FormGroup>
            </Col>
        </Row>

    {/* Image and Text Area Side-by-Side Section */}
    {/*  Image To left List  LTR Section 2*/}
    <Row>
        {/*Image*/}
    <Col md="6">
        <FormGroup>
            <Label>Left Image</Label>
            <div className={styles['banner-upload-wrapper']}>
                {leftImage ? (
                    <img src={leftImage} alt="Uploaded Left" />
                ) : (
                    <div className={styles['left-upload-placeholder']}>
                            <FiPlus className={styles['banner-upload-icon']} />
                            <span>Upload Image</span>
                    </div>
                )}
                <input
                    type="file"
                    className="form-control"
                    {...register("left_image", { required: true })}
                    onChange={handleLeftImageChange}
                    style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", cursor: "pointer" }}
                />
                </div>
        </FormGroup>
    </Col>
    {/*Story List*/}
        <Col md="6">
        <Card className="p-4">
            <div className={styles['custom-scrollbar']}>
            {story_list.map((story, index) => (
                <div key={index} className="mb-3">
                <Row>
                    <Col className="text-end">
                    <button
                        type="button"
                        onClick={() => removeStory(index)}
                        className="btn"
                    >
                        X
                    </button>
                    </Col>
                    <FormGroup>
                    <Label>Story Title:</Label>
                    <input
                        className="form-control"
                        type="text"
                        value={story.story_title}
                        onChange={(e) =>
                            handleStoryInputChange(
                            index,
                            "story_title",
                            e.target.value
                        )
                        }
                        placeholder="Story title"
                    />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <Label>Story Description:</Label>
                    <Editor
                        value={story.story_description}
                        style={{ height: '200px' }}
                        onTextChange={(e) =>
                            handleStoryInputChange(
                            index,
                            "story_description",
                            e.htmlValue || ""
                        )
                        }
                    />
                    </FormGroup>
                </Row>
                </div>
            ))}
            </div>
        </Card>
        <button
            type="button"
            onClick={addStory}
            className="btn btn-primary mt-2 mb-2"
        >
            + Add Story
        </button>
        </Col>
    </Row>
    {/* Right List to Image  RTL Section 3*/}

    <Row>
    {/*Story List*/}
        <Col md="6">
        <Card className="p-4">
            <div className={styles['custom-scrollbar']}>
            {data_list.map((story, index) => (
                <div key={index} className="mb-3">
                <Row>
                    <Col className="text-end">
                    <button
                        type="button"
                        onClick={() => removeStoryData(index)}
                        className="btn"
                    >
                        X
                    </button>
                    </Col>
                    <FormGroup>
                    <Label>Story Title:</Label>
                    <input
                        className="form-control"
                        type="text"
                        value={story.data_title}
                        onChange={(e) =>
                            handleStoryDataInputChange(
                            index,
                            "data_title",
                            e.target.value
                        )
                        }
                        placeholder="Story title"
                    />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <Label>Story Description:</Label>
                    <Editor
                        value={story.data_description}
                        style={{ height: '200px' }}
                        onTextChange={(e) =>
                            handleStoryDataInputChange(
                            index,
                            "data_description",
                            e.htmlValue || ""
                        )
                        }
                    />
                    </FormGroup>
                </Row>
                </div>
            ))}
            </div>
        </Card>
        <button
            type="button"
            onClick={addStoryData}
            className="btn btn-primary mt-4 mb-2"
        >
            + Add Story
        </button>
        </Col>
        {/*Image*/}
        <Col md="6">
        <FormGroup>
            <Label>Right Image</Label>
            <div className={styles['banner-upload-wrapper']}>
                {rightImage ? (
                    <img src={rightImage} alt="Uploaded Left" />
                ) : (
                    <div className={styles['left-upload-placeholder']}>
                            <FiPlus className={styles['banner-upload-icon']} />
                            <span>Upload Image</span>
                    </div>
                )}
                <input
                    type="file"
                    className="form-control"
                    {...register("right_image", { required: true })}
                    onChange={handleRightImageChange}
                    style={{ opacity: 0, position: "absolute", width: "100%", height: "100%", cursor: "pointer" }}
                />
                </div>
        </FormGroup>
    </Col>
    </Row>
    <Row>
        {/*FAQ List*/}
    <Col md="12">
        <Card className="p-4">
            <div className={styles['custom-scrollbar']}>
            {faq_list.map((story, index) => (
                <div key={index} className="mb-3">
                <Row>
                    <Col className="text-end">
                    <button
                        type="button"
                        onClick={() => removeFaqData(index)}
                        className="btn"
                    >
                        X
                    </button>
                    </Col>
                    <FormGroup>
                    <Label>FAQ Title:</Label>
                    <input
                        className="form-control"
                        type="text"
                        value={story.faq_title}
                        onChange={(e) =>
                            handleFAQInputChange(
                            index,
                            "faq_title",
                            e.target.value
                        )
                        }
                        placeholder="FAQ title"
                    />
                    </FormGroup>
                </Row>
                <Row>
                    <FormGroup>
                    <Label>FAQ Description:</Label>
                    <Editor
                        value={story.faq_description}
                        style={{ height: '100px' }}
                        onTextChange={(e) =>
                            handleFAQInputChange(
                            index,
                            "faq_description",
                            e.htmlValue || ""
                        )
                        }
                    />
                    </FormGroup>
                </Row>
                </div>
            ))}
            </div>
        </Card>
        <button
            type="button"
            onClick={addFaqData}
            className="btn btn-primary mt-4 mb-2"
        >
            + Add FAQ
        </button>
        </Col>
    </Row>

    <Row>
        <Col>
            <Btn color="primary" type="submit">Submit</Btn>
        </Col>
    </Row>
    {/* Main Category Create Modal Start */}
    <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New</ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label for="newName">Name</Label>
                <input
                    type="text"
                    className="form-control"
                    value={newOption.category_name}
                    onChange={(e) => setNewOption({ ...newOption, category_name: e.target.value })}
                    placeholder="Enter category_name"
                />
            </FormGroup>
            <FormGroup>
                        <Label>Main Category Image</Label>
                        <input 
                            className="form-control" 
                            type="file" 
                            onChange={handleFileChangePopup} 
                        />
                    </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={handleAddNew}>Add</Button>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
    </Modal>
    {/* Main Category Create Modal End---------- */}
    {/* Sub Category Create Modal Start */}
    <Modal isOpen={isModalSubOpen} toggle={toggleModalSub}>
        <ModalHeader toggle={toggleModalSub}>Add New</ModalHeader>
        <ModalBody>
            <FormGroup>
                <Label for="newName">Name</Label>
                <input
                    type="text"
                    className="form-control"
                    value={newOptionSub.sub_category_name}
                    onChange={(e) => setNewOptionSub({ ...newOptionSub, sub_category_name: e.target.value })}
                    placeholder="Enter category_name"
                />
            </FormGroup>
             
            <FormGroup>
                        <Label for="main_category">Select Main Category</Label>
                        <select
                        className="form-control"
                        value={newOptionSub?.main_category_code || ''}
                        onChange={(e) => setNewOptionSub({ ...newOptionSub, main_category_code: e.target.value})}
                        >
                        <option value="">Select Main Category</option>
                        {MainCategoryDataInfo.map((category) => (
                            <option key={category.category_code} value={category.category_code}>
                            {category.category_name}
                            </option>
                        ))}
                        </select>
                </FormGroup>


            <FormGroup>
                <Label for="newDescription">Description</Label>
                <textarea
                    className="form-control"
                    value={newOptionSub.sub_category_description}
                    onChange={(e) => setNewOptionSub({ ...newOptionSub, sub_category_description: e.target.value })}
                    placeholder="Enter category_description"
                />
            </FormGroup>
            <FormGroup>
                        <Label>Sub Category Image</Label>
                        <input 
                            className="form-control" 
                            type="file" 
                            onChange={handleFileChangePopupSub} 
                        />
                    </FormGroup>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={handleAddNewSub}>Add</Button>
            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
    </Modal>
    {/* Sub Category Create Modal End---------- */}
        </Form>

    );
}
