export const api = `https://dev-api.athenasecurity.in/api`;
export const ChatMembersApi = `${api}/chatMembers.json`;
export const ChatApi = `${api}/chats.json`;
export const ChatContactApi = `${api}/chatContacts.json`;
export const UserEditTableApi = `${api}/user-edit-table.json`;
export const UserCardsApi = `${api}/user-cards.json`;
export const SearchResultApi = `${api}/searchResult.json`;
export const TodoApi = `${api}/todo.json`;
export const BookmarkApi = `${api}/bookmark.json`;
export const ProductApi = `${api}/product.json`;
export const JobSearchApi = `${api}/jobSearch.json`;
