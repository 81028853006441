import React, { Fragment } from 'react';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { ProductCreateTitle, AppsTitle } from '../../../../Utils/Constants'; // Adjusted for products
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import CreateNewProductForm from "./CreateNewProductForm"; // Adjusted the import for the product form

export default function ProductCreateContainer() {
    return (
        <Fragment>
            <Breadcrumbs pageTitle={ProductCreateTitle} parent={AppsTitle} title={ProductCreateTitle} /> {/* Updated for products */}
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <CreateNewProductForm /> {/* Changed to use the product form */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
