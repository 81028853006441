import React from 'react'
import FaqContainer from '../../../Components/Miscellaneous/Faq'

export default function Faq() {
  return (
    <div className='page-body'>
        <FaqContainer/>
    </div>
  )
}
