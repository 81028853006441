import axios from "axios";
import { api } from "../../api";

// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};

// Define types for the form data and response
export interface FeatureFormData {
  _id?: string; // Optional for newly created features
  feature_code: string;
  name: string;
  icon: string;
  redirection_link: string;
  feature_status: string;
  created_date?: string; // Optional, handled by the server
  updated_date?: string; // Optional, handled by the server
  created_by?: string; // Optional
  updated_by?: string; // Optional
}

interface FeatureResponse {
  data: any; // Adjust this based on the actual response structure
}

// Fetch all features
export const fetchFeatures = async (): Promise<FeatureResponse> => {
  try {
    const response = await axios.get(`${api}/powerfulFeatures/featureretrieve`, {
      headers: { Authorization: getUserToken() },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch features.");
  }
};

// Create a new feature
export const createFeature = async (formData: FormData): Promise<FeatureResponse> => {
  try {
    const response = await axios.post(`${api}/powerfulFeatures/featurecreate`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,  // Replace with the actual token logic
        'Content-Type': 'multipart/form-data',      // Header to handle file uploads
      },
    });
    return response.data;
  } catch (error) {
    console.error("Failed to create feature:", error);
    throw new Error("Failed to create feature.");
  }
};

// Update an existing feature
export const updateFeature = async (formData: FormData): Promise<FeatureResponse> => {
  try {
    const response = await axios.put(`${api}/powerfulFeatures/featureedit`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,  // Replace with the actual token logic
        'Content-Type': 'multipart/form-data',      // Header to handle file uploads
      },
    });
    return response.data;
  } catch (error) {
    console.error("Update feature error:", error);
    throw new Error("Failed to update feature.");
  }
};

// Delete a feature
export const deleteFeature = async (feature_code: string): Promise<FeatureResponse> => {
  try {
    const response = await axios.delete(`${api}/powerfulFeatures/featuredelete?feature_code=${feature_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,  // Ensure the token is passed in the header
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete feature.");
  }
};
