import React from 'react'
import LearningListContainer from '../../../../Components/Miscellaneous/Learning/LearningList'

export default function LearningList() {
  return (
    <div className='page-body'>
        <LearningListContainer/>
    </div>
  )
}
