import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { TableColumn } from "react-data-table-component";
import CardHeaderSpan from '../../../../../Utils/CommonComponents/CardHeaderSpan';
import { ClientTitle } from '../../../../../Utils/Constants';
import FilterComponent from '../../../../Tables/DataTables/Common/FilterComponent';
import { fetchData, deleteClientData, updateClientData } from '../../../../../redux-toolkit/reducers/ClientReducer';
import { ClientColumnData } from '../../../../../Types/TableType';

export default function RowCreateCallback() {
    const [filterText, setFilterText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);  // Modal state
    const [selectedClient, setSelectedClient] = useState<ClientColumnData | null>(null);  // Selected client state
    const [editData, setEditData] = useState<ClientColumnData>({
        client_code: '', // Required field
        client_name: '',
        client_description: '',
        Client_icon: '', // Image URL
        client_url: '' // This is the missing field
    });
    
    const dispatch: AppDispatch = useDispatch();
    
    const Client = useSelector((state: RootState) => state.Client);
    const ClientData: ClientColumnData[] = Array.isArray(Client?.data) 
        ? Client.data 
        : Object.values(Client?.data || {}); // Ensure data is an array

    useEffect(() => {
        const fetchMilestones = async () => {
            try {
                await dispatch(fetchData());
            } catch (error) {
                console.error("Error fetching Client:", error);
            }
        };

        fetchMilestones();
    }, [dispatch]);

    const filteredItems: ClientColumnData[] = ClientData.filter(
        (item) =>
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );

    // Open the modal and fetch the data for the selected client
    const handleEdit = (row: ClientColumnData) => {
        setSelectedClient(row); // Set the selected client
        setEditData(row); // Populate form with selected data
        setIsModalOpen(true); // Open the modal
    };

    // Handle the form field changes for editing, including the image URL field
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Handle image file change (to allow upload)
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setEditData({
                ...editData,
                Client_icon: URL.createObjectURL(file), // Display preview
            });
        }
    };

    // Handle the form submission for updating the client data
    const handleSubmit = async () => {
        try {
            // Ensure editData contains all necessary fields
            await dispatch(updateClientData(editData));  // Dispatch the update action
            dispatch(fetchData());  // Refetch the updated data
            setIsModalOpen(false);  // Close the modal
        } catch (error) {
            console.error("Failed to update:", error);
        }
    };

    const handleDelete = (row: ClientColumnData) => {
        dispatch(deleteClientData(row.client_code))
            .then(() => {
                dispatch(fetchData());
            })
            .catch((error) => {
                console.error("Failed to delete:", error);
            });
    };

    const rowClientCallBackTableColumns: TableColumn<ClientColumnData>[] = [
        {
            name: "Client Name",
            selector: (row: ClientColumnData) => row.client_name,
            sortable: true,
        },
        {
            name: "Client Description",
            selector: (row: ClientColumnData) => row.client_description,
            sortable: true,
        },
        {
            name: "Client Icon", // Change column name
            cell: (row: ClientColumnData) => (
                <img 
                    src={row.Client_icon} 
                    alt={row.client_name} 
                    style={{ width: '50px', height: '50px', objectFit: 'cover' }} 
                    
                />
            ),
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row: ClientColumnData) => (
                <div>
                    <button onClick={() => handleEdit(row)} className="btn btn-primary btn-sm">
                        Edit
                    </button>
                    <button onClick={() => handleDelete(row)} className="btn btn-danger btn-sm ms-2">
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    return (
        <Col sm={12}>
            <Card>
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={ClientTitle} />
                <CardBody>
                    <FilterComponent
                        onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                        onClear={() => setFilterText('')}
                        filterText={filterText}
                    />
                    <div className="table-responsive custom-scrollbar">
                        <DataTable 
                            data={filteredItems} 
                            columns={rowClientCallBackTableColumns} 
                            striped 
                            pagination 
                            className="display dataTable" 
                        />
                    </div>
                </CardBody>
            </Card>

            {/* Edit Modal */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
                <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Client</ModalHeader>
                <ModalBody>
                    {selectedClient && (
                        <Form>
                            <FormGroup>
                                <Label for="client_name">Client Name</Label>
                                <Input
                                    type="text"
                                    name="client_name"
                                    value={editData.client_name || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="client_description">Client Description</Label>
                                <Input
                                    type="textarea"
                                    name="client_description"
                                    value={editData.client_description || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="Client_icon">Client Icon </Label>
                                <Input 
                                    type="file" 
                                    accept="image/*" 
                                    onChange={handleImageChange} 
                                    style={{ marginTop: "10px" }} 
                                />
                                <img 
                                    src={editData.Client_icon} 
                                    alt={editData.client_name} 
                                    style={{ width: "100px", height: "100px", marginTop: "10px" }}
                                />
                            </FormGroup>
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Col>
    );
}
