import React, { useState, useEffect } from 'react';
import {
    Card,
    CardBody,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    FormGroup,
    Label,
    Input,
} from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { TableColumn } from "react-data-table-component";
import CardHeaderSpan from '../../../../../Utils/CommonComponents/CardHeaderSpan';
import { ProductTitle } from '../../../../../Utils/Constants';
import FilterComponent from '../../../../Tables/DataTables/Common/FilterComponent';
import { fetchProductsData, deleteProductData, updateProductData } from '../../../../../redux-toolkit/reducers/ProductReducer';

export interface ProductRowData {
    productName: string;
    productCode: string;
    product_main_image: string;
    product_all_images: string[];
    productDescription: string;
    productShortDescription: string;
    regularPrice: number;
    salePrice: number;
    productTags: string;
    productStatus: string;
}

export default function ProductManagement() {
    const [filterText, setFilterText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState<ProductRowData | null>(null);
    const [editData, setEditData] = useState<ProductRowData>({
        productName: '',
        productCode: '',
        product_main_image: '',
        product_all_images: [],
        productDescription: '',
        productShortDescription: '',
        regularPrice: 0,
        salePrice: 0,
        productTags: '',
        productStatus: '',
    });
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [allImages, setAllImages] = useState<File[]>([]);
    const dispatch: AppDispatch = useDispatch();

    const products = useSelector((state: RootState) => state.product);
    const productsData: ProductRowData[] = Array.isArray(products?.data)
        ? products.data
        : Object.values(products?.data || {});

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                await dispatch(fetchProductsData());
                console.log("Fetched products data:", productsData);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, [dispatch]);

    const filteredItems: ProductRowData[] = productsData.filter(
        (item) =>
            Object.values(item).some((value) =>
                value && value?.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );

    const handleEdit = (row: ProductRowData) => {
        console.log("Editing row:", row);
        if (row && row.productCode) {
            setEditData(row);
            setSelectedProduct(row);
            setImageFile(null); // Reset the main image file
            setAllImages([]); // Reset additional images
            setIsModalOpen(true);
        } else {
            console.error("Selected row does not contain productCode", row);
        }
    };

    const handleDelete = (row: ProductRowData) => {
        dispatch(deleteProductData(row.productCode))
            .then(() => {
                dispatch(fetchProductsData());
            })
            .catch((error) => {
                console.error("Failed to delete:", error);
            });
    };

    const handleModalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setImageFile(file); // Set main image file
            const imageUrl = URL.createObjectURL(file);
            setEditData((prevData) => ({
                ...prevData,
                product_main_image: imageUrl, // Update preview URL for main image
            }));
        }
    };

    const handleAdditionalImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files) {
            const fileArray = Array.from(files);
            const imageUrls = fileArray.map((file) => URL.createObjectURL(file)); // Generate URLs for preview

            setAllImages(fileArray); // Replace previous additional images with newly selected ones
            setEditData((prevData) => ({
                ...prevData,
                product_all_images: imageUrls, // Immediately update preview URLs
            }));
        }
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('productName', editData.productName);
        formData.append('productDescription', editData.productDescription);
        formData.append('productShortDescription', editData.productShortDescription);
        // formData.append('regularPrice', editData.regularPrice?.toString());
        // formData.append('salePrice', editData.salePrice?.toString());
        formData.append('productCode', editData.productCode);
        formData.append('productTags', editData.productTags);
        formData.append('productStatus', editData.productStatus);

        if (imageFile) {
            formData.append('product_main_image', imageFile);
        }
        allImages.forEach(image => {
            formData.append('product_all_images', image);
        });

        try {
            await dispatch(updateProductData(formData));
            await dispatch(fetchProductsData());
            setIsModalOpen(false);
        } catch (error) {
            console.error("Failed to update product:", error);
        }
    };

    const rowProductTableColumns: TableColumn<ProductRowData>[] = [
        {
            name: "Product Name",
            selector: (row) => row.productName,
            sortable: true,
        },
        {
            name: "Product Image",
            cell: (row) => (
                <img
                    src={row.product_main_image}
                    alt={row.productName}
                    style={{ width: '40px', height: '40px', objectFit: 'cover' }}
                />
            ),
            sortable: true,
        },
        {
            name: "Additional Images",
            cell: (row) => (
                <div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto' }}>
                    {row.product_all_images.map((image, index) => (
                        <img
                            key={index}
                            src={image}
                            alt={`Additional ${index}`}
                            style={{ width: '40px', height: '40px', objectFit: 'cover', marginRight: '5px' }}
                        />
                    ))}
                </div>
            ),
        },
        {
            name: "Description",
            selector: (row) => row.productDescription,
            sortable: true,
        },
        {
            name: "Short Description",
            selector: (row) => row.productShortDescription,
            sortable: true,
        },
        // {
        //     name: "Regular Price",
        //     selector: (row) => row.regularPrice,
        //     sortable: true,
        // },
        // {
        //     name: "Sale Price",
        //     selector: (row) => row.salePrice,
        //     sortable: true,
        // },
        {
            name: "Tags",
            selector: (row) => row.productTags,
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => row.productStatus,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row) => (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        onClick={() => handleEdit(row)}
                        color="primary"
                        size="sm"
                        style={{
                            width: '100px',
                            margin: '3px', // Uniform margin
                            borderRadius: '4px', // Slightly rounded corners
                            boxShadow: '0 1px 3px rgba(0,0,0,0.2)', // Shadow for a subtle depth effect
                            display: 'flex',
                            justifyContent: 'center', // Center text horizontally
                            alignItems: 'center', // Center text vertically
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        onClick={() => handleDelete(row)}
                        color="danger"
                        size="sm"
                        style={{
                            width: '100px',
                            margin: '3px', // Uniform margin
                            borderRadius: '4px', // Slightly rounded corners
                            boxShadow: '0 1px 3px rgba(0,0,0,0.2)', // Shadow for a subtle depth effect
                            display: 'flex',
                            justifyContent: 'center', // Center text horizontally
                            alignItems: 'center', // Center text vertically
                        }}
                    >
                        Delete
                    </Button>
                </div>
            ),
        },            
    ];

    return (
        <Col sm={12}>
            <Card>
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={ProductTitle} />
                <CardBody>
                    <FilterComponent
                        onFilter={(e) => setFilterText(e.target.value)}
                        onClear={() => setFilterText('')}
                        filterText={filterText}
                    />
                    <div className="table-responsive custom-scrollbar">
                        <DataTable
                            data={filteredItems}
                            columns={rowProductTableColumns}
                            striped
                            pagination
                            className="display dataTable"
                        />
                    </div>
                </CardBody>
            </Card>

            {/* Edit Modal */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
                <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Product</ModalHeader>
                <ModalBody>
                    <form>
                        <FormGroup>
                            <Label for="productName">Product Name</Label>
                            <Input
                                type="text"
                                name="productName"
                                value={editData.productName}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="productDescription">Description</Label>
                            <Input
                                type="textarea"
                                name="productDescription"
                                value={editData.productDescription}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="productShortDescription">Short Description</Label>
                            <Input
                                type="textarea"
                                name="productShortDescription"
                                value={editData.productShortDescription}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        {/* <FormGroup>
                            <Label for="regularPrice">Regular Price</Label>
                            <Input
                                type="number"
                                name="regularPrice"
                                value={editData.regularPrice}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="salePrice">Sale Price</Label>
                            <Input
                                type="number"
                                name="salePrice"
                                value={editData.salePrice}
                                onChange={handleModalChange}
                            /> */}
                        {/* </FormGroup> */}
                        <FormGroup>
                            <Label for="productTags">Tags</Label>
                            <Input
                                type="text"
                                name="productTags"
                                value={editData.productTags}
                                onChange={handleModalChange}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="productStatus">Status</Label>
                            <Input
                                type="select"
                                name="productStatus"
                                value={editData.productStatus}
                                onChange={handleModalChange}
                            >
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="product_main_image">Main Image</Label>
                            <Input type="file" name="product_main_image" onChange={handleMainImageChange} />
                            {editData.product_main_image && (
                                <img
                                    src={editData.product_main_image}
                                    alt="Main Preview"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover', marginTop: '10px' }}
                                />
                            )}
                        </FormGroup>
                        <FormGroup>
                            <Label for="product_all_images">Additional Images</Label>
                            <Input type="file" name="product_all_images" multiple onChange={handleAdditionalImagesChange} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', marginTop: '10px' }}>
                                {editData.product_all_images.map((image, index) => (
                                    <img
                                        key={index}
                                        src={image}
                                        alt={`Additional Preview ${index}`}
                                        style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '5px' }}
                                    />
                                ))}
                            </div>
                        </FormGroup>
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>{' '}
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </Col>
    );
}
