import React from 'react'
import BasicTablesContainer from '../../../../Components/Tables/ReactstrapTables/BasicTables'

export default function BasicTables() {
  return (
    <div className='page-body'>
        <BasicTablesContainer/>
    </div>
  )
}
