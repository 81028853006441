import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchServices, createService, deleteService, updateService } from '../Apis/servicesApi'; // Replace with your services API functions

// Define ServiceFormData type based on the service schema
interface ServiceFormData {
    _id?: string; // Optional for newly created services
    image_title: string;
    image_url: string;
    serviceSubject: string;
    image_description: string;
    service_code: string;
    created_date?: string; // Optional since created/updated dates might be handled by the server
    updated_date?: string;
    link?: string;
    serviceStatus: string;
    created_by?: string;
    updated_by?: string;
}

interface ServiceResponse {
    _id?: string; // Optional for newly created services
    image_title: string;
    image_url: string;
    serviceSubject: string;
    image_description: string;
    service_code: string;
    created_date?: string; // Optional since created/updated dates might be handled by the server
    updated_date?: string;
    link?: string;
    serviceStatus: string;
    created_by?: string;
    updated_by?: string;
}

interface State {
    data: {
        [key: string]: ServiceResponse; // Allow dynamic keys for each service
    };
    isLoading: boolean;
    error: string | null; // Allow null values for error
}

const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch service data
export const fetchServicesData = createAsyncThunk(
    'services/fetchServicesData',
    async () => {
        const services = await fetchServices();
        console.log("API response inside fetchServicesData:", services);
        return services; // Assuming services API returns an array or object
    }
);

export const deleteServiceData = createAsyncThunk(
    "services/deleteServiceData",
    async (service_code: string) => {
        const data = await deleteService(service_code);
        return data;
    }
);

export const createServiceData = createAsyncThunk(
    "services/createServiceData",
    async (formData: FormData) => {
        const data = await createService(formData);
        console.log("data reducers :", data)
        return data;
    }
);

export const updateServiceData = createAsyncThunk(
    "services/updateServiceData",
    async (formData: FormData) => {
        const data = await updateService(formData);
        return data;
    }
);

const servicesSlice = createSlice({
    name: 'services',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchServicesData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchServicesData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Assuming the response has a data field
            })
            .addCase(fetchServicesData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null; // Handle error
            })
            .addCase(createServiceData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createServiceData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, add new service to the state
                // state.data = { ...state.data, [action.payload._id]: action.payload };
            })
            .addCase(createServiceData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(updateServiceData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateServiceData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the service in the state
                // state.data[action.payload._id] = action.payload;
            })
            .addCase(updateServiceData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteServiceData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteServiceData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default servicesSlice.reducer;
