import React from 'react'
import PaginationsContainer from '../../../Components/BonusUi/Paginations'

export default function Paginations() {
  return (
    <div className='page-body'>
        <PaginationsContainer/>
    </div>
  )
}
