import axios from "axios";
import { api } from "../../api";

// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};

// Define types for the form data and response
export interface ServiceFormData {
  _id?: string; // Optional for newly created services
  image_title: string;
  image_url: string;
  serviceSubject: string;
  image_description: string;
  service_code: string;
  created_date?: string; // Optional, handled by the server
  updated_date?: string; // Optional, handled by the server
  link?: string; // Optional
  serviceStatus: string;
  created_by?: string; // Optional
  updated_by?: string; // Optional
}

interface ServiceResponse {
  data: any; // Adjust this based on the actual response structure
}

export const fetchServices = async (): Promise<ServiceResponse> => {
  try {
    const response = await axios.get(`${api}/services/getServices`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,  // Replace with the actual token logic
        'Content-Type': 'multipart/form-data',      // Header to handle file uploads
      },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch services.");
  }
};

export const createService = async (formData: FormData): Promise<ServiceResponse> => {
  try {
    const response = await axios.post(`${api}/services/serviceCreation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,  // Replace with the actual token logic
        'Content-Type': 'multipart/form-data',      // Header to handle file uploads
      },
    });
    console.log("response :",response.data)
    return response.data;
  } catch (error) {
    console.error("Failed to create service:", error);
    throw new Error("Failed to create service.");
  }
};

export const updateService = async (formData: FormData): Promise<ServiceResponse> => {
  try {
    const response = await axios.put(`${api}/services/serviceUpdate`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error("Update service error:", error);
    throw new Error("Failed to update service.");
  }
};


export const deleteService = async (service_code: string): Promise<ServiceResponse> => {
  try {
    const response = await axios.delete(`${api}/services/deleteService?service_code=${service_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete service.");
  }
};
