import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs'; 
// import { TechnologyInitTitle, TechnologiesTitle } from '../../../../../Utils/Constants';  // Updated constants for technologies
import TechnologyRowCreateCallback from './RowCreateCallBack';  // Updated to the new technology row creation component

export default function TechnologyInitContainer() {
    return (
        <Fragment>
            {/* Updated Breadcrumbs */}
            {/* <Breadcrumbs pageTitle={TechnologyInitTitle} parent={TechnologiesTitle} title={TechnologyInitTitle} />   */}
            <Container fluid>
                <Row>
                    <TechnologyRowCreateCallback />  {/* Updated callback component for technologies */}
                </Row>
            </Container>
        </Fragment>
    );
}
