import React from 'react'
import LetterBoxContainer from '../../../../Components/Apps/Email/LetterBox'

export default function LetterBox() {
  return (
    <div className='page-body'>
        <LetterBoxContainer/>
    </div>
  )
}
