import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchClient,createClient, updateClient,deleteClient} from '../Apis/clientApi';
import { ClientColumnData } from '../../Types/TableType';


interface ClientResponse {
    client_name:string,
    client_description:string,
    client_url:string,
    Client_icon:string,
    client_code:string
}

interface State {
    data: {
        [key: string]: ClientResponse; // Allow dynamic keys
    };
    isLoading: boolean;
    error: string | null; // Change type to include null
}
const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch milestone data
export const fetchData = createAsyncThunk(
    'Client/fetchData',
    async () => {
        const Client = await fetchClient();
        console.log("API response inside fetchData:", Client); // Log the API response
        return Client; // Return the nested Client data
    }
);

export const deleteClientData = createAsyncThunk(
    "Client/deleteClientData",
    async (client_code: string) => {
        const data = await deleteClient(client_code);
        return data;
    }
);

// ClientReducer.tsx
export const createClientData = createAsyncThunk(
    "Client/createClientData",
    async (formData: FormData) => { // Expect FormData as the input
        const data = await createClient(formData); // Pass it directly to createClient
        return data;
    }
);


// ClientReducer.tsx
export const updateClientData = createAsyncThunk(
    "Client/updateClientData",
    async (clientData: ClientColumnData) => {
        const data = await updateClient(clientData); // Pass the entire client data object
        return data;
    }
);


const ClientSlice = createSlice({
    name: 'Client',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Store the fetched data in state
            })
            .addCase(fetchData.rejected, (state, action) => {
                state.isLoading = false;
                // .state.error = action.error.message ?? null; // Assign null if message is undefined
 
            })
            .addCase(createClientData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createClientData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, you can update the state with the new milestone if needed
                // state.data = { ...state.data, [action.payload._id]: action.payload }; // Assuming the response has _id
            })
            .addCase(createClientData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(updateClientData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateClientData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the specific milestone in the state
                // state.data[action.payload._id] = action.payload; // Assuming the response has _id
            })
            .addCase(updateClientData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            })
            .addCase(deleteClientData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteClientData.rejected, (state, action) => {
                state.isLoading = false;
                // state.error = action.error.message; // Optional: Store error message
            });
    },
});

export default ClientSlice.reducer;
