import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from '../../../../../redux-toolkit/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Add, Cancel, EnterSomeDetails } from '../../../../../Utils/Constants';
import { Btn } from '../../../../../AbstractElements';
import { ProductDataCallBackData } from '../../../../../Types/TableType'; // Replace with correct product type
import { createProductData, fetchProductsData } from '../../../../../redux-toolkit/reducers/ProductReducer';

export default function CreateNewProductForm() {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const [productMainImage, setProductMainImage] = useState<File | null>(null);
    const [productAllImages, setProductAllImages] = useState<FileList | null>(null);

    const { register, handleSubmit, formState: { errors } } = useForm<ProductDataCallBackData>();

    // Handle file input change for product_main_image
    const handleMainImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if ( e.target.files && e.target.files.length > 0) {
            setProductMainImage(e.target.files[0]);
        }
    };

    // Handle file input change for product_all_images
    const handleAllImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProductAllImages(e.target.files);
    };

    const addProduct: SubmitHandler<ProductDataCallBackData> = (data) => {
        const formData = new FormData();
        formData.append('productName', data.productName);
        formData.append('productCode', data.productCode);
        formData.append('productDescription', data.productDescription);
        formData.append('productShortDescription', data.productShortDescription);
        // formData.append('regularPrice', data.regularPrice.toString());
        // formData.append('salePrice', data.salePrice.toString());
        formData.append('service_code', data.service_code);
        formData.append('productTags', data.productTags);
        formData.append('productStatus', data.productStatus);

        if (productMainImage) {
            formData.append('product_main_image', productMainImage);
        }

        if (productAllImages) {
            for (let i = 0; i < productAllImages.length; i++) {
                formData.append('product_all_images', productAllImages[i]);
            }
        }

        dispatch(createProductData(formData))
            .then(() => {
                dispatch(fetchProductsData());
                navigate(`${process.env.PUBLIC_URL}/applications/product/product-list`);
            })
            .catch((error) => {
                console.error("Failed to add product:", error);
            });
    };

    return (
        <Form className="theme-form" onSubmit={handleSubmit(addProduct)}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Product Name</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Product Name *"
                            {...register("productName", { required: true })}
                        />
                        {errors.productName && <span style={{ color: "red" }}>Product Name is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <FormGroup>
                        <Label>Product Code</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Product Code *"
                            {...register("productCode", { required: true })}
                        />
                        {errors.productCode && <span style={{ color: "red" }}>Product Code is required</span>}
                    </FormGroup>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Product Short Description</Label>
                        <textarea
                            className="form-control"
                            rows={3}
                            placeholder="Enter Short Description *"
                            {...register("productShortDescription", { required: true })}
                        />
                        {errors.productShortDescription && <span style={{ color: "red" }}>Short Description is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Product Description</Label>
                        <textarea
                            className="form-control"
                            rows={5}
                            placeholder="Enter Product Description *"
                            {...register("productDescription", { required: true })}
                        />
                        {errors.productDescription && <span style={{ color: "red" }}>Product Description is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            {/* <Row> */}
                {/* <Col>
                    <FormGroup>
                        <Label>Regular Price</Label>
                        <input
                            className="form-control"
                            type="number"
                            placeholder="Enter Regular Price *"
                            {...register("regularPrice", { required: true })}
                        />
                        {errors.regularPrice && <span style={{ color: "red" }}>Regular Price is required</span>}
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label>Sale Price</Label>
                        <input
                            className="form-control"
                            type="number"
                            placeholder="Enter Sale Price"
                            {...register("salePrice", { required: true })}
                        />
                        {errors.salePrice && <span style={{ color: "red" }}>Sale Price is required</span>}
                    </FormGroup>
                </Col> */}
            {/* </Row> */}
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Product Tags</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Product Tags *"
                            {...register("productTags", { required: true })}
                        />
                        {errors.productTags && <span style={{ color: "red" }}>Product Tags are required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Product Main Image</Label>
                        <input
                            className="form-control"
                            type="file"
                            onChange={handleMainImageChange}
                        />
                        {errors.product_main_image && <span style={{ color: "red" }}>Main Image is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Product All Images</Label>
                        <input
                            className="form-control"
                            type="file"
                            multiple
                            onChange={handleAllImagesChange}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="text-end">
                        <Btn color="success" className="me-3">{Add}</Btn>
                        <Btn color="danger">{Cancel}</Btn>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}
