import React from 'react'
import BlogSingleContainer from '../../../../Components/Miscellaneous/Blog/BlogSingle'

export default function BlogSingle() {
  return (
    <div className='page-body'>
        <BlogSingleContainer/>
    </div>
  )
}
