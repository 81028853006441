import axios from "axios";
import { api } from "../../api";
import { MainCategoryTableColumnData,SubCategoryTableColumnData } from "../../Types/TableType";
// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};


interface subCategoryResponse {
  data: any; // Adjust this based on the actual response structure
}

export const fetchSubCategory = async (): Promise<subCategoryResponse> => {
    try {
      const response = await axios.get(`${api}/subCategory/Retrieve`, {
        headers: {  Authorization: `Bearer ${getUserToken()}` },
      });
      return response.data; // Return the whole data object
    } catch (error) {
      throw new Error("Failed to fetch MainCategory.");
    }
  };
  
  // MainCategoryApi.tsx
  export const createSubCategory = async (formData: FormData): Promise<subCategoryResponse> => { 
    try {
      console.log("formData", formData);
      const response = await axios.post(`${api}/subCategory/Creation`, formData, {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
          'Content-Type': 'multipart/form-data', // Correct header for file uploads
        },
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to create MainCategory.");
    }
  };
  
  export const updateSubCategory = async (formData: SubCategoryTableColumnData): Promise<subCategoryResponse> => {
    try {
        const response = await axios.put(`${api}/subCategory/Edit`, formData, {
            headers: {
                Authorization: `Bearer ${getUserToken()}`,
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to update MainCategory.");
    }
  };
  
  export const deleteSubCategory = async (sub_category_code: string): Promise<subCategoryResponse> => {
    try {
      const response = await axios.delete(`${api}/subCategory/Delete?sub_category_code=${sub_category_code}`, {
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to delete MainCategory.");
    }
  };