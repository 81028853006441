import React, { useState } from 'react'
import DatePicker, { DateObject } from 'react-multi-date-picker';

export default function MultiplesDates() {
    const [value, setValue] = useState<DateObject[]>([new DateObject()]);

    return (
        <DatePicker
            monthYearSeparator="::"
            inputClass="form-control"
            multiple
            value={value}
            onChange={(dates) => setValue(dates as DateObject[])}
        />
    )
}
