import React from 'react'
import EditProfileContainer from '../../../../Components/Apps/Users/EditProfile'

export default function EditProfile() {
  return (
    <div className='page-body'>
        <EditProfileContainer/>
    </div>
  )
}
