import { Fragment, useState } from 'react'
import { Btn, H6, P } from '../../../../../AbstractElements';
import { FullscreenBelowLg, UXDesigner, WebDesign, WedDesigner } from '../../../../../Utils/Constants';
import CommonModal from '../../../../../Utils/CommonComponents/UiKitsCommon/CommonModal';
import { belowsmData } from '../../../../../Data/UiKitsData/ModalData';
import FeatherIconCom from '../../../../../Utils/CommonComponents/CommonIcons/FeatherIconCom';

function BelowLg() {
    const [modal, setModal] = useState<boolean>(false);
    const toggle = () => { setModal(!modal); }
    const data = {
        isOpen: modal,
        header: true,
        footer: true,
        toggler: toggle,
        title: 'Full screen below lg',
        class: 'modal-fullscreen-lg-down',
        bodyClass: 'dark-modal',
    }
    return (
        <Fragment>
            <Btn color='warning-2x' outline={true} type="button" onClick={toggle} >{FullscreenBelowLg}</Btn>
            <CommonModal modalData={data}>
                <div className="large-modal-header">
                    <FeatherIconCom iconName='ChevronsRight' />
                    <H6>{WebDesign}</H6>
                </div>
                <P className="modal-padding-space">{"We build specialised websites for companies, list them on digital directories, and set up a sales funnel to boost ROI."}</P>
                <div className="modal-details">
                    <div className="web-content">
                        <H6>{WedDesigner}</H6>
                        {belowsmData && belowsmData.map((item, index) => (
                            <Fragment key={index}>
                                <div className={`d-flex ${item.class}`}>
                                    <div className="flex-shrink-0">
                                        <FeatherIconCom iconName='ArrowRightCircle' className='svg-modal' />
                                    </div>
                                    <div className="flex-grow-1 ms-2">
                                        <P>{item.text}</P>
                                    </div>
                                </div>
                                {index === 1 && <H6>{UXDesigner}</H6>}
                            </Fragment>
                        ))}
                    </div>
                </div>
            </CommonModal>
        </Fragment>
    )
}

export default BelowLg