import React from 'react'
import GeneralWidgets from '../../../Components/Widgets/General'

export default function General() {
    return (
        <div className='page-body'>
            <GeneralWidgets />
        </div>
    )
}
