import axios from "axios";
import { api } from "../../api";
import { FeedbackData } from "../../Types/TableType";
// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};


interface FeedBackResponse {
  data: any; // Adjust this based on the actual response structure
}

export const fetchFeedBack = async (): Promise<FeedBackResponse> => {
  try {
    const response = await axios.get(`${api}/userfeedback/getfeedback`, {
      headers: { Authorization: getUserToken() },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch FeedBack.");
  }
};