import React, { Fragment, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { ProductListTitle, ProductTitle } from '../../../../Utils/Constants'; // Updated constants for products
import ProductListNav from "./ProductListNav"; // Updated import for ProductListNav
import ProductListData from "./ProductListData"; // Updated import for ProductListData

export default function ProductListContainer() {
    const [activeTab, setActiveTab] = useState("1"); // State to manage active tab for products

    return (
        <Fragment>
            {/* Breadcrumbs updated for products */}
            <Breadcrumbs pageTitle={ProductListTitle} parent={ProductTitle} title={ProductListTitle} />
            <Container fluid>
                <Row className="product-cards"> {/* Updated className */}
                    <Col md={12} className="product-list"> {/* Updated className */}
                        <ProductListNav activeTab={activeTab} setActiveTab={setActiveTab} /> {/* Updated for products */}
                    </Col>
                    <Col sm={12}>
                        <ProductListData /> {/* Updated for products */}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
