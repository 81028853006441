import React, { FormEvent, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { Btn, H3, P, Image } from '../AbstractElements';
import { dynamicImage } from '../Utils';
import { Col, Form, FormGroup, Input, Label } from 'reactstrap';
import { EmailAddress, Password, RememberPassword, ForgotPassword, SignIn, SignInAccount } from '../Utils/Constants';
import { fetchAdminLogin } from '../redux-toolkit/reducers/LoginReducer';
import { AppDispatch, RootState } from '../redux-toolkit/store'; // Import AppDispatch

interface LoginData {
  email: string;
  password: string;
  rememberMe: boolean;
  token: any;
}

export default function SignInForm() {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch type for dispatch
  const loginStatus = useSelector((state: RootState) => state.authadmin);
  console.log("loginStatus",loginStatus)
  const info = loginStatus.userToken;
  console.log()
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [loginAdmin, setLoginAdmin] = useState<LoginData>({
    email: "",
    password: "",
    rememberMe: false,
    token: null
  });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

//   useEffect(() => {
//     if (info !== null) {
//       navigate(`${process.env.PUBLIC_URL}/dashboard`);
//     }
//   },[]);

  const validateForm = () => {
    let formErrors: { [key: string]: string } = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!loginAdmin.email) {
      formErrors.email = "Email is required.";
    } else if (!emailRegex.test(loginAdmin.email)) {
      formErrors.email = "Enter a valid email.";
    }

    if (!loginAdmin.password) {
      formErrors.password = "Password is required.";
    } else if (loginAdmin.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters.";
    }

    return formErrors;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setLoginAdmin({
      ...loginAdmin,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const toggle = () => setPasswordVisible(!isPasswordVisible);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      dispatch(fetchAdminLogin(loginAdmin))
        .unwrap()  // Use unwrap() to handle the promise properly
        .then(() => {
          toast.success("Login Success...!");
          navigate(`${process.env.PUBLIC_URL}/dashboard`);
          localStorage.setItem("login", JSON.stringify(true));
        })
        .catch((error: any) => {
          toast.error("Login failed: " + error.message);
        });
    }
  };
  

  return (
    <div>
      <div>
        <Link className='logo' to={`${process.env.PUBLIC_URL}/dashboard`}>
          <Image
            className="img-fluid for-light"
            src={dynamicImage(`logo/logo.jpg`)}
            style={{ height: "40px", width: "120px" }}
            alt="looginpage"
            body={true}
          />
        </Link>
      </div>
      <div className="login-main">
        <Form className="theme-form" onSubmit={handleSubmit}>
          <H3>{SignInAccount}</H3>
          <P>{"Enter your email & password to login"}</P>
          <FormGroup>
            <Col><Label>{EmailAddress}</Label></Col>
            <Input
              type="email"
              name="email"
              value={loginAdmin.email}
              onChange={handleInputChange}
            />
            {errors.email && <span className="text-danger">{errors.email}</span>}
          </FormGroup>
          <FormGroup>
            <Col><Label>{Password}</Label></Col>
            <div className="form-input position-relative">
              <Input
                type={isPasswordVisible ? 'text' : 'password'}
                name="password"
                value={loginAdmin.password}
                onChange={handleInputChange}
              />
              <div className='show-hide' onClick={toggle}>
                <span className={!isPasswordVisible ? 'show' : ''}></span>
              </div>
              {errors.password && <span className="text-danger">{errors.password}</span>}
            </div>
          </FormGroup>
          <div className="mb-0 form-group">
            <div className="checkbox p-0">
              <Input
                id="checkbox1"
                type="checkbox"
                name="rememberMe"
                checked={loginAdmin.rememberMe}
                onChange={handleInputChange}
              />
              <Label className="text-muted" htmlFor="checkbox1">{RememberPassword}</Label>
            </div>
            <Link className="link" to={`${process.env.PUBLIC_URL}/auth/forget-password`}>{ForgotPassword}</Link>
            <div className="text-end mt-3">
              <Btn color='primary' className="btn-block w-100">{SignIn}</Btn>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
