import React from 'react'
import GoogleChartContainer from '../../../Components/Charts/GoogleChart'

export default function GoogleChart() {
  
  return (
    <div className='page-body'>
        <GoogleChartContainer/>
    </div>
  )
}
