import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminLogin, adminLogout } from "../Apis/loginApi";
import { LoginData } from "../../Types/TableType";

let userToken = null;
if (typeof window !== "undefined") {
  userToken = sessionStorage.getItem("userToken") || null;
}



// Assuming that LoginData contains { token: string, userInfo: any } structure
export const fetchAdminLogin = createAsyncThunk(
  "authadmin/login",
  async (credentials: LoginData, { rejectWithValue }) => {
    try {
      const data: LoginData = await adminLogin(credentials); // Ensure this matches the expected type
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

export const fetchAdminLogout = createAsyncThunk(
  "authadmin/logout",
  async (_, { rejectWithValue }) => {
    try {
      const data = await adminLogout();
      return data;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue("An unknown error occurred");
    }
  }
);

interface AuthAdminState {
  loading: boolean;
  userInfo: any | null; // Adjust the type based on your actual user info structure
  userToken: string | null;
  error: string | null;
  success: boolean;
}

const initialState: AuthAdminState = {
  loading: false,
  userInfo: null,
  userToken,
  error: null,
  success: false,
};

const authauthadminSlice = createSlice({
  name: "authadmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Login
      .addCase(fetchAdminLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdminLogin.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload; // Assuming the payload is the full user info object

        // Access token directly from payload if there's no message wrapper
        if (payload?.token) {
          state.userToken = payload.token;
          sessionStorage.setItem("userToken", payload.token);
        } else {
          state.error = "Invalid response structure";
        }
      })
      .addCase(fetchAdminLogin.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as string) || "Login failed";
      })
      // Logout
      .addCase(fetchAdminLogout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchAdminLogout.fulfilled, (state) => {
        state.loading = false;
        state.userInfo = null;
        state.userToken = null;
        sessionStorage.removeItem("userToken");
      })
      .addCase(fetchAdminLogout.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = (payload as string) || "Logout failed";
      })
      
  },
});

export default authauthadminSlice.reducer;
