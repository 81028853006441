import React, { Fragment, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs'
import {TestimonialListTitle,TestimonialTitlee } from '../../../../Utils/Constants'

import TestimonialListNav from './TestimonailListNav';
import TestimonialListData from './TestimonialListData'

export default function TestimonialListContainer() {
    const [activeTab, setActiveTab] = useState("1");

    return (
        <Fragment>
            <Breadcrumbs pageTitle={TestimonialListTitle} parent={TestimonialTitlee} title={TestimonialListTitle} />
            <Container fluid>
                <Row className="project-cards">
                    <Col md={12} className="project-list">
                        <TestimonialListNav activeTab={activeTab} setActiveTab={setActiveTab} />
                    </Col>
                    <Col sm={12}>
                        <TestimonialListData />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
