import React, { Fragment, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs'
import {ClientListTitle,ClientTitlee } from '../../../../Utils/Constants'

import ClientListNav from './ClientListNav';
import ClientListData from './ClientListData'

export default function ClientListContainer() {
    const [activeTab, setActiveTab] = useState("1");

    return (
        <Fragment>
            <Breadcrumbs pageTitle={ClientListTitle} parent={ClientTitlee} title={ClientListTitle} />
            <Container fluid>
                <Row className="project-cards">
                    <Col md={12} className="project-list">
                        <ClientListNav activeTab={activeTab} setActiveTab={setActiveTab} />
                    </Col>
                    <Col sm={12}>
                        <ClientListData />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
