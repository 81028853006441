import React from 'react'
import TreeViewContainer from '../../../Components/BonusUi/TreeView'

export default function TreeView() {
  return (
    <div className='page-body'>
        <TreeViewContainer/>
    </div>
  )
}
