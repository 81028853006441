import axios from "axios";
import { api } from "../../api";

// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `Bearer ${tokenStr}` : null;
};

// Define types for the form data and response
export interface TechnologyFormData {
    _id?: string; // Optional for newly created technologies
    technologyCode: string; // Initialize as an empty string
    technologyname: string; // Using 'technologyname' as specified
    technology_updated_date?: string; // Optional update date
    technology_created_date?: string; // Optional creation date
    technologyStatus: 'ACTIVE' | 'DEACTIVE'; // Enum for technology status
}

// Initialize default values for technology
export const defaultTechnology: TechnologyFormData = {
    technologyCode: '',
    technologyname: '',
    technology_updated_date: '',
    technology_created_date: '',
    technologyStatus: 'ACTIVE',
};

// Response interface
interface TechnologyResponse {
    data: any; // Adjust this based on the actual response structure
}

export const fetchTechnologies = async (): Promise<TechnologyResponse> => {
    try {
        const response = await axios.get(`${api}/technologyData/RetrieveTechnology`, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'application/json', // Header to handle JSON data
            },
        });
        return response.data; // Return the whole data object
    } catch (error) {
        throw new Error("Failed to fetch technologies.");
    }
};

export const createTechnology = async (formData: TechnologyFormData): Promise<TechnologyResponse> => {
    try {
        const response = await axios.post(`${api}/technologyData/CreateTechnology`, formData, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'application/json', // Sending as JSON
            },
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to create technology.");
    }
};

export const updateTechnology = async (formData: TechnologyFormData): Promise<TechnologyResponse> => {
    try {
        const response = await axios.put(`${api}/technologyData/EditTechnology`, formData, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'application/json', // Sending as JSON
            },
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to update technology.");
    }
};

export const deleteTechnology = async (technologyCode: string): Promise<TechnologyResponse> => {
    try {
        const response = await axios.delete(`${api}/technologyData/DeleteTechnology?technologyCode=${technologyCode}`, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to delete technology.");
    }
};
