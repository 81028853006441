import React from 'react'
import EmailComposeContainer from '../../../../Components/Apps/Email/EmailCompose'

export default function EmailCompose() {
  return (
    <div className='page-body'>
        <EmailComposeContainer/>
    </div>
  )
}
