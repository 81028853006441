import React, { useState } from 'react';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from '../../../../../redux-toolkit/store';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Add, Cancel } from '../../../../../Utils/Constants';
import { Btn } from '../../../../../AbstractElements';
import { TechnologyDataCallBackData } from '../../../../../Types/TableType'; // Replace with correct technology type
import { createTechnologyData, fetchTechnologiesData } from '../../../../../redux-toolkit/reducers/TechnologyReducer';

export default function CreateNewTechnologyForm() {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors } } = useForm<TechnologyDataCallBackData>();

    const addTechnology: SubmitHandler<TechnologyDataCallBackData> = (data) => {
        const formData = {
            technologyCode: data.technologyCode,
            technologyname: data.technologyname,
            technologyStatus: data.technologyStatus,
            technology_updated_date: new Date().toISOString(),
            technology_created_date: new Date().toISOString()
        };

        dispatch(createTechnologyData(formData))
            .then(() => {
                dispatch(fetchTechnologiesData());
                navigate(`${process.env.PUBLIC_URL}/applications/technology/technology-list`);
            })
            .catch((error) => {
                console.error("Failed to add technology:", error);
            });
    };

    return (
        <Form className="theme-form" onSubmit={handleSubmit(addTechnology)}>
            {/* <Row>
                <Col>
                    <FormGroup>
                        <Label>Technology Code</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Technology Code *"
                            {...register("technologyCode", { required: true })}
                        />
                        {errors.technologyCode && <span style={{ color: "red" }}>Technology Code is required</span>}
                    </FormGroup>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Technology Name</Label>
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Enter Technology Name *"
                            {...register("technologyname", { required: true })}
                        />
                        {errors.technologyname && <span style={{ color: "red" }}>Technology Name is required</span>}
                    </FormGroup>
                </Col>
            </Row>
            {/* <Row>
                <Col>
                    <FormGroup>
                        <Label>Status</Label>
                        <Input
                            type="select"
                            {...register("technologyStatus", { required: true })}
                        >
                            <option value="ACTIVE">Active</option>
                            <option value="DEACTIVE">Deactive</option>
                        </Input>
                        {errors.technologyStatus && <span style={{ color: "red" }}>Status is required</span>}
                    </FormGroup>
                </Col>
            </Row> */}
            <Row>
                <Col>
                    <div className="text-end">
                        <Btn color="success" className="me-3">{Add}</Btn>
                        <Btn color="danger">{Cancel}</Btn>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}
