import React,{useEffect} from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { ProjectListTabProp } from "../../../../Types/ProjectType";
import { RootState,AppDispatch } from "../../../../redux-toolkit/store";
import { fetchData } from "../../../../redux-toolkit/reducers/ContactUsReducer";
import { contactData } from "../../../../Types/TableType";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { H3, Image } from "../../../../AbstractElements";
import { FaEnvelope, FaPhoneAlt, FaRegCommentDots } from 'react-icons/fa';

interface ExtendedProjectListTabProp extends ProjectListTabProp {
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

interface ContactData {
  _id: string;
  name: string;
  email: string;
  phoneno: string;
  subject: string;
  message: string;
}

interface ApiResponse {
  status: number;
  message: string;
  data: ContactData[];
}

export default function ContactListTabContent({activeTab,setActiveTab,}: ExtendedProjectListTabProp) {
  const dispatch: AppDispatch = useDispatch();
  const contactUs = useSelector((state: RootState) => state.contactUs);
  const contactUsData: contactData[] = Array.isArray(contactUs?.data) ? contactUs.data : Object.values(contactUs?.data || {});
  useEffect(() => {
    const fetchContactus = async () => {
        try {
            await dispatch(fetchData());
        } catch (error) {
            console.error("Error fetching Contact:", error);
        }
    };
    fetchContactus();
}, [dispatch]);
  return (
    <>
      <Breadcrumbs pageTitle={"Contact List"} parent={"Contact"} title={"Contact List"} />
      <Card style={{ display: "none" }}>
        <CardBody>
          {/* Tab Navigation */}
          <div className="tab-navigation">
            <button
              onClick={() => setActiveTab("1")}
              className={activeTab === "1" ? "active" : ""}
            >
              All Projects
            </button>
            <button
              onClick={() => setActiveTab("2")}
              className={activeTab === "2" ? "active" : ""}
            >
              Doing
            </button>
            <button
              onClick={() => setActiveTab("3")}
              className={activeTab === "3" ? "active" : ""}
            >
              Done
            </button>
          </div>
        </CardBody>
      </Card>
      {/* Contact List */}
      <Card className="p-5">
        <Row>
          {contactUsData.map((contact) => (
            <Col xxl={4} lg={4} md={6} key={contact._id}>
              <Card className="project-box">
                <Image
                  className="img-60 me-1 rounded-circle"
                  src={"https://img.freepik.com/premium-vector/vector-flat-illustration-grayscale-avatar-user-profile-person-icon-profile-picture-business-profile-woman-suitable-social-media-profiles-icons-screensavers-as-templatex9_719432-1328.jpg?ga=GA1.1.38938053.1720239901&semt=ais_hybrid"}
                  alt="Contact"
                />
                <CardBody>
                  <H3>{contact.name}</H3>
                  <div className="contact-info">
                    <p>
                      <FaEnvelope /> {contact.email}
                    </p>
                    <p>
                      <FaPhoneAlt /> {contact.phoneno}
                    </p>
                  </div>
                  <p>{contact.subject}</p>
                  <p>{contact.message}</p>
                  <button className="btn btn-primary">
                    <FaRegCommentDots /> Contact
                  </button>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </>
  );
}
