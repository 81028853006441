import axios from "axios";
import { api } from "../../api";

// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `Bearer ${tokenStr}` : null;
};

// Define types for the form data and response
export interface ProductFormData {
    _id?: string; // Optional for newly created products
    productName: string;
    productCode: string;
    product_main_image: string;
    product_all_images: string[];
    productDescription: string;
    productShortDescription: string;
    regularPrice: number;
    salePrice: number;
    service_code: string;
    productTags: string;
    productStatus: string;
    created_by?: string; // Optional
    updated_by?: string; // Optional
}

interface ProductResponse {
    data: any; // Adjust this based on the actual response structure
}

export const fetchProducts = async (): Promise<ProductResponse> => {
    try {
        const response = await axios.get(`${api}/products/getProducts`, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'multipart/form-data', // Header to handle file uploads
            },
        });
        return response.data; // Return the whole data object
    } catch (error) {
        throw new Error("Failed to fetch products.");
    }
};

export const createProduct = async (formData: FormData): Promise<ProductResponse> => {
    try {
        const response = await axios.post(`${api}/products/createProduct`, formData, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'multipart/form-data', // Header to handle file uploads
            },
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to create product.");
    }
};

export const updateProduct = async (formData: FormData): Promise<ProductResponse> => {
    try {
        const response = await axios.put(`${api}/products/editProduct`, formData, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'multipart/form-data', // Use 'multipart/form-data' when sending FormData
            },
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to update product.");
    }
};


export const deleteProduct = async (productCode: string): Promise<ProductResponse> => {
    try {
        const response = await axios.delete(`${api}/products/deleteProduct?productCode=${productCode}`, {
            headers: {
                Authorization: getUserToken(),
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        throw new Error("Failed to delete product.");
    }
};
