import React,{useState} from 'react'
import FeedBackListTabContent from './FeedBackList';


export default function FeedBackList() {
    const [activeTab, setActiveTab] = useState('1');
  return (
    <div className='page-body'>
       <FeedBackListTabContent activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  )
}