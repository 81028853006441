import { TableColumn } from "react-data-table-component";
import { AjaxSourcedColumnsInterface, CustomCellInterFace, HtmlColumnsInterface, ServerSideProcessingInterface } from "../../../Types/TableType";
import { Href } from "../../../Utils/Constants";
import { Badges, LI, UL } from "../../../AbstractElements";

export const htmlTableSpan: string = `The foundation for DataTables is progressive enhancement, so it is very adept at reading table information directly from the DOM. This example shows how easy it is to add searching, ordering and paging to your HTML table by simply running DataTables on it.`
export const ajaxTableSpan: string = `DataTables has the ability to read data from virtually any JSON data source that can be obtained by Ajax. This can be done, in its most simple form, by setting the <code>ajax:option</code> option to the address of the JSON data source.`
export const javaScriptTableSpan: string = `At times you will wish to be able to create a table from dynamic information passed directly to DataTables, rather than having it read from the document. This is achieved using the <code class="option" title="DataTables initialisation option">data</code> option in the initialisation object, passing in an array of data to be used (like all other DataTables handled data, this can be arrays or objects using the<code class="option" title="DataTables initialisation option">columns.data</code> option).`
export const javaScriptTableSpan2: string = `A <code class="tag" title="HTML tag">table</code> must be available on the page for DataTables to use. This examples shows an empty <code class="tag" title="HTML tag">table</code> element being initialising as a DataTable with a set of data from a Javascript array. The columns in the table are dynamically created based on the <code class="option" title="DataTables initialisation option">columns.title</code>configuration option.`
export const serverSideProcessingSpan: string = `There are many ways to get your data into DataTables, and if you are working with seriously large databases, you might want to consider using the server-side options that DataTables provides. With server-side processing enabled, all paging, searching, ordering actions that DataTables performs are handed off to a server where an SQL engine (or similar) can perform these actions on the large data set (after all, that's what the database engine is designed for!). As such, each draw of the table will result in a new Ajax request being made to get the required data.`
export const serverSideProcessingSpan2: string = `Server-side processing is enabled by setting the <code class="option" title="DataTables initialisation option">serverSide:option</code> option to <code>true</code> and providing an Ajax data source through the <code class="option" title="DataTables initialisation option">ajax:option</code> option.`

const CustomBadge = ({ position, color }: CustomCellInterFace) => {
    return (
        <Badges className="rounded-pill"  color={color}>{position}</Badges>
    );
};

export const htmlColumns: TableColumn<HtmlColumnsInterface>[] = [
    {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: "Position",
        selector: (row) => row.position,
        sortable: true,
    },
    {
        name: "Salary",
        selector: (row) => row.salary,
        sortable: true,
    },
    {
        name: "Office",
        selector: (row) => row.office,
        sortable: true,
    },
    {
        name: "CV",
        sortable: true,
        cell: () => (
            <div className="action">
                <a className="pdf" onClick={handlePDFClick} rel="/assets/images/favicon.png" href="/assets/pdf/sample.pdf" target="_blank">
                    <i className="icofont icofont-file-pdf"> </i>
                </a>
            </div>
        ),
    },
    {
        name: "Status",
        selector: (row) => row.badge,
        cell: (row) => (
            <CustomBadge color={row.color} position={row.badge} />
        ),
        sortable: true,
    },
    {
        name: "Email",
        selector: (row) => row.email,
        sortable: true,
    },
    {
        name: "Action",
        sortable: true,
        cell: () => (
            <UL className="action simple-list flex-row">
                <LI className="edit">
                    <a href={Href}>
                        <i className="icon-pencil-alt" />
                    </a>
                </LI>
                <LI className="delete">
                    <a href={Href}>
                        <i className="icon-trash" />
                    </a>
                </LI>
            </UL>
        ),
    },
];

export const ajaxSourcedColumns: TableColumn<AjaxSourcedColumnsInterface>[] = [
    {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: "Position",
        selector: (row) => row.position ,
        sortable: true,
    },
    {
        name: "Office",
        selector: (row) => row.office ,
        sortable: true,
    },
    {
        name: "Extn.",
        selector: (row) => row.extends ,
        sortable: true,
    },
    {
        name: "Start Date",
        selector: (row) => row.date ,
        sortable: true,
    },
    {
        name: "Salary",
        selector: (row) => row.salary ,
        sortable: true,
    },
];

export const ajaxSourcedData: AjaxSourcedColumnsInterface[] = [
    {
        id: 1,
        name: "Angelica Ramos",
        position: "Chief Executive Officer (CEO)",
        office: "London",
        extends: "5797",
        date: "2009/10/09",
        salary: "$1,200,000",
    },
    {
        id: 2,
        name: "Archana Desai",
        position: "Accountant",
        office: "Tokyo",
        extends: "5407",
        date: "2008/11/28",
        salary: "$162,700",
    },
    {
        id: 3,
        name: "Ashton Cox",
        position: "Junior Technical Author",
        office: "San Francisco",
        extends: "1562",
        date: "2009/01/12",
        salary: "$86,000",
    },
    {
        id: 4,
        name: "Bradley Greer",
        position: "Software Engineer",
        office: "London",
        extends: "2558",
        date: "2012/10/13",
        salary: "$132,000",
    },
    {
        id: 5,
        name: "Brenden Wagner",
        position: "Software Engineer",
        office: "San Francisco",
        extends: "1314",
        date: "2011/06/07",
        salary: "$206,850",
    },
    {
        id: 6,
        name: "Bruno Nash",
        position: "Software Engineer",
        office: "London",
        extends: "6222",
        date: "2011/05/03",
        salary: "$163,500",
    },
    {
        id: 7,
        name: "Caesar Vance",
        position: "Pre-Sales Support",
        office: "New York",
        extends: "8330",
        date: "2011/12/12",
        salary: "$106,450",
    },
    {
        id: 8,
        name: "Cara Stevens",
        position: "Sales Assistant",
        office: "New York",
        extends: "3990",
        date: "2011/12/06",
        salary: "$145,600",
    },
    {
        id: 9,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        extends: "6224",
        date: "2012/03/29",
        salary: "$433,060",
    },
    {
        id: 10,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        extends: "6741",
        date: "2008/10/16",
        salary: "$470,600",
    },
    {
        id: 11,
        name: "Dai Rios",
        position: "Personnel Lead",
        office: "Edinburgh",
        extends: "2290",
        date: "2012/09/26",
        salary: "$217,500",
    },
    {
        id: 12,
        name: "Doris Wilder",
        position: "Sales Assistant",
        office: "Sidney",
        extends: "3023",
        date: "2010/09/20",
        salary: "$85,600",
    },
    {
        id: 13,
        name: "Fiona Green",
        position: "Chief Executive Officer (CEO)",
        office: "San Francisco",
        extends: "2947",
        date: "2010/03/11",
        salary: "$850,000",
    },
    {
        id: 14,
        name: "Garrett Winters",
        position: "Accountant",
        office: "Tokyo",
        extends: "8422",
        date: "2011/07/25",
        salary: "$170,750",
    },
    {
        id: 15,
        name: "Gavin Cortez",
        position: "Team Leader",
        office: "San Francisco",
        extends: "2860",
        date: "2008/10/26",
        salary: "$235,500",
    },
    {
        id: 16,
        name: "Gavin Joyce",
        position: "Developer",
        office: "Edinburgh",
        extends: "8822",
        date: "2010/12/22",
        salary: "$92,575",
    },
    {
        id: 17,
        name: "Gloria Little",
        position: "Systems Administrator",
        office: "New York",
        extends: "1721",
        date: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 18,
        name: "Haley Kennedy",
        position: "Senior Marketing Designer",
        office: "London",
        extends: "3597",
        date: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 19,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        extends: "9608",
        date: "2012/08/06",
        salary: "$137,500",
    },
    {
        id: 20,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        extends: "3814",
        date: "2008/12/19",
        salary: "$90,560",
    },
    {
        id: 21,
        name: "Gloria Little",
        position: "Systems Administrator",
        office: "New York",
        extends: "1721",
        date: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 22,
        name: "Haley Kennedy",
        position: "Senior Marketing Designer",
        office: "London",
        extends: "3597",
        date: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 23,
        name: "Hermione Butler",
        position: "Regional Director",
        office: "London",
        extends: "1016",
        date: "2011/03/21",
        salary: "$356,250",
    },
    {
        id: 24,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        extends: "9608",
        date: "2012/08/06",
        salary: "$137,500",
    },

    {
        id: 25,
        name: "Hope Fuentes",
        position: "Secretary",
        office: "San Francisco",
        extends: "6318",
        date: "2010/02/12",
        salary: "$109,850",
    },
    {
        id: 26,
        name: "Howard Hatfield",
        position: "Office Manager",
        office: "San Francisco",
        extends: "7031",
        date: "2008/12/16",
        salary: "$164,500",
    },
    {
        id: 27,
        name: "Jackson Bradshaw",
        position: "Director",
        office: "New York",
        extends: "1042",
        date: "2008/09/26",
        salary: "$645,750",
    },
    {
        id: 28,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        extends: "3814",
        date: "2008/12/19",
        salary: "$90,560",
    },
    {
        id: 29,
        name: "Jenette Caldwell",
        position: "Development Lead",
        office: "New York",
        extends: "1937",
        date: "2011/09/03",
        salary: "$345,000",
    },
    {
        id: 30,
        name: "Jennifer Acosta",
        position: "Junior Javascript Developer",
        office: "Edinburgh",
        extends: "3431",
        date: "2013/02/01",
        salary: "$75,650",
    },
    {
        id: 31,
        name: "Bruno Nash",
        position: "Software Engineer",
        office: "London",
        extends: "6222",
        date: "2011/05/03",
        salary: "$163,500",
    },
    {
        id: 32,
        name: "Caesar Vance",
        position: "Pre-Sales Support",
        office: "New York",
        extends: "8330",
        date: "2011/12/12",
        salary: "$106,450",
    },
    {
        id: 33,
        name: "Cara Stevens",
        position: "Sales Assistant",
        office: "New York",
        extends: "3990",
        date: "2011/12/06",
        salary: "$145,600",
    },
    {
        id: 34,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        extends: "6224",
        date: "2012/03/29",
        salary: "$433,060",
    },
    {
        id: 35,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        extends: "6741",
        date: "2008/10/16",
        salary: "$470,600",
    },
    {
        id: 36,
        name: "Archana Desai",
        position: "Accountant",
        office: "Tokyo",
        extends: "5407",
        date: "2008/11/28",
        salary: "$162,700",
    },
    {
        id: 37,
        name: "Ashton Cox",
        position: "Junior Technical Author",
        office: "San Francisco",
        extends: "1562",
        date: "2009/01/12",
        salary: "$86,000",
    },
    {
        id: 38,
        name: "Bradley Greer",
        position: "Software Engineer",
        office: "London",
        extends: "2558",
        date: "2012/10/13",
        salary: "$132,000",
    },
    {
        id: 39,
        name: "Brenden Wagner",
        position: "Software Engineer",
        office: "San Francisco",
        extends: "1314",
        date: "2011/06/07",
        salary: "$206,850",
    },
    {
        id: 40,
        name: "Bruno Nash",
        position: "Software Engineer",
        office: "London",
        extends: "6222",
        date: "2011/05/03",
        salary: "$163,500",
    },
    {
        id: 41,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        extends: "9608",
        date: "2012/08/06",
        salary: "$137,500",
    },
    {
        id: 42,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        extends: "3814",
        date: "2008/12/19",
        salary: "$90,560",
    },
    {
        id: 43,
        name: "Gloria Little",
        position: "Systems Administrator",
        office: "New York",
        extends: "1721",
        date: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 44,
        name: "Haley Kennedy",
        position: "Senior Marketing Designer",
        office: "London",
        extends: "3597",
        date: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 45,
        name: "Hermione Butler",
        position: "Regional Director",
        office: "London",
        extends: "1016",
        date: "2011/03/21",
        salary: "$356,250",
    },
    {
        id: 46,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        extends: "6224",
        date: "2012/03/29",
        salary: "$433,060",
    },
    {
        id: 47,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        extends: "6741",
        date: "2008/10/16",
        salary: "$470,600",
    },
    {
        id: 48,
        name: "Dai Rios",
        position: "Personnel Lead",
        office: "Edinburgh",
        extends: "2290",
        date: "2012/09/26",
        salary: "$217,500",
    },
    {
        id: 49,
        name: "Doris Wilder",
        position: "Sales Assistant",
        office: "Sidney",
        extends: "3023",
        date: "2010/09/20",
        salary: "$85,600",
    },
    {
        id: 50,
        name: "Fiona Green",
        position: "Chief Executive Officer (CEO)",
        office: "San Francisco",
        extends: "2947",
        date: "2010/03/11",
        salary: "$850,000",
    },
    {
        id: 51,
        name: "Jennifer Acosta",
        position: "Junior Javascript Developer",
        office: "Edinburgh",
        extends: "3431",
        date: "2013/02/01",
        salary: "$75,650",
    },
    {
        id: 52,
        name: "Bruno Nash",
        position: "Software Engineer",
        office: "London",
        extends: "6222",
        date: "2011/05/03",
        salary: "$163,500",
    },
    {
        id: 53,
        name: "Caesar Vance",
        position: "Pre-Sales Support",
        office: "New York",
        extends: "8330",
        date: "2011/12/12",
        salary: "$106,450",
    },
    {
        id: 54,
        name: "Cara Stevens",
        position: "Sales Assistant",
        office: "New York",
        extends: "3990",
        date: "2011/12/06",
        salary: "$145,600",
    },
    {
        id: 55,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        extends: "6224",
        date: "2012/03/29",
        salary: "$433,060",
    },
    {
        id: 56,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        extends: "6741",
        date: "2008/10/16",
        salary: "$470,600",
    },
    {
        id: 57,
        name: "Archana Desai",
        position: "Accountant",
        office: "Tokyo",
        extends: "5407",
        date: "2008/11/28",
        salary: "$162,700",
    },
];


const handlePDFClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const pdfUrl = '/assets/pdf/sample.pdf';
    window.open(pdfUrl, '_blank');
};
export const htmlColumnsTableData: HtmlColumnsInterface[] = [
    {
        id: 1,
        name: "Airi Satou",
        position: "Accountant",
        office: "Tokyo",
        badge: "hired",
        email: "t.nixon@datatables.net",
        color: "success",
        salary: "$162,700",
    },
    {
        id: 2,
        name: "Ashton Cox",
        position: "Junior Technical Author",
        office: "San Francisco",
        badge: "hired",
        color: "warning",
        email: "a.cox@datatables.net",
        salary: "$86,000",
    },
    {
        id: 3,
        name: "Brielle Williamson",
        position: "Integration Specialist",
        office: "New York",
        badge: "Pending",
        color: "danger",
        email: "b.williamson@datatables.net",
        salary: "$372,000",
    },
    {
        id: 4,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "c.kelly@datatables.net",
        salary: "$433,060",
    },
    {
        id: 5,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        badge: "in process",
        color: "warning",
        email: "c.marshall@datatables.net",
        salary: "$470,600",
    },
    {
        id: 6,
        name: "Colleen Hurst",
        position: "Javascript Developer",
        office: "San Francisco",
        badge: "Pending",
        color: "danger",
        email: "c.hurst@datatables.net",
        salary: "$205,500",
    },
    {
        id: 7,
        name: "Donna Snider",
        position: "Customer Support",
        office: "New York",
        badge: "hired",
        color: "success",
        email: "d.snider@datatables.net",
        salary: "$112,000",
    },
    {
        id: 8,
        name: "Garrett Winters",
        position: "Accountant",
        office: "Tokyo",
        badge: "Pending",
        color: "danger",
        email: "g.winters@datatables.net",
        salary: "$170,750",
    },
    {
        id: 9,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        badge: "in process",
        color: "warning",
        email: "h.chandler@datatables.net",
        salary: "$137,500",
    },
    {
        id: 10,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$90,560",
    },
    {
        id: 11,
        name: "Quinn Flynn",
        position: "Support Lead",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "q.flynn@datatables.net",
        salary: "$342,000",
    },
    {
        id: 12,
        name: "Rhona Davidson",
        position: "Integration Specialist",
        office: "Tokyo",
        badge: "hired",
        color: "success",
        email: "r.davidson@datatables.net",
        salary: "$327,900",
    },
    {
        id: 13,
        name: "Sonya Frost",
        position: "Software Engineer",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "s.frost@datatables.net",
        salary: "$103,600",
    },
    {
        id: 14,
        name: "Tiger Nixon",
        position: "System Architect",
        office: "Edinburgh",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$320,800",
    },
    {
        id: 15,
        name: "Airi Satou",
        position: "Accountant",
        office: "Tokyo",
        badge: "hired",
        email: "t.nixon@datatables.net",
        color: "success",
        salary: "$162,700",
    },
    {
        id: 16,
        name: "Ashton Cox",
        position: "Junior Technical Author",
        office: "San Francisco",
        badge: "hired",
        color: "warning",
        email: "a.cox@datatables.net",
        salary: "$86,000",
    },
    {
        id: 17,
        name: "Brielle Williamson",
        position: "Integration Specialist",
        office: "New York",
        badge: "Pending",
        color: "danger",
        email: "b.williamson@datatables.net",
        salary: "$372,000",
    },
    {
        id: 18,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "c.kelly@datatables.net",
        salary: "$433,060",
    },
    {
        id: 19,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        badge: "in process",
        color: "warning",
        email: "c.marshall@datatables.net",
        salary: "$470,600",
    },
    {
        id: 20,
        name: "Colleen Hurst",
        position: "Javascript Developer",
        office: "San Francisco",
        badge: "Pending",
        color: "danger",
        email: "c.hurst@datatables.net",
        salary: "$205,500",
    },
    {
        id: 21,
        name: "Donna Snider",
        position: "Customer Support",
        office: "New York",
        badge: "hired",
        color: "success",
        email: "d.snider@datatables.net",
        salary: "$112,000",
    },
    {
        id: 22,
        name: "Garrett Winters",
        position: "Accountant",
        office: "Tokyo",
        badge: "Pending",
        color: "danger",
        email: "g.winters@datatables.net",
        salary: "$170,750",
    },
    {
        id: 23,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        badge: "in process",
        color: "warning",
        email: "h.chandler@datatables.net",
        salary: "$137,500",
    },
    {
        id: 24,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$90,560",
    },
    {
        id: 25,
        name: "Quinn Flynn",
        position: "Support Lead",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "q.flynn@datatables.net",
        salary: "$342,000",
    },
    {
        id: 26,
        name: "Rhona Davidson",
        position: "Integration Specialist",
        office: "Tokyo",
        badge: "hired",
        color: "success",
        email: "r.davidson@datatables.net",
        salary: "$327,900",
    },
    {
        id: 27,
        name: "Sonya Frost",
        position: "Software Engineer",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "s.frost@datatables.net",
        salary: "$103,600",
    },
    {
        id: 28,
        name: "Tiger Nixon",
        position: "System Architect",
        office: "Edinburgh",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$320,800",
    },
    {
        id: 29,
        name: "Donna Snider",
        position: "Customer Support",
        office: "New York",
        badge: "hired",
        color: "success",
        email: "d.snider@datatables.net",
        salary: "$112,000",
    },
    {
        id: 30,
        name: "Garrett Winters",
        position: "Accountant",
        office: "Tokyo",
        badge: "Pending",
        color: "danger",
        email: "g.winters@datatables.net",
        salary: "$170,750",
    },
    {
        id: 31,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        badge: "in process",
        color: "warning",
        email: "h.chandler@datatables.net",
        salary: "$137,500",
    },
    {
        id: 32,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$90,560",
    },
    {
        id: 33,
        name: "Quinn Flynn",
        position: "Support Lead",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "q.flynn@datatables.net",
        salary: "$342,000",
    },
    {
        id: 34,
        name: "Rhona Davidson",
        position: "Integration Specialist",
        office: "Tokyo",
        badge: "hired",
        color: "success",
        email: "r.davidson@datatables.net",
        salary: "$327,900",
    },
    {
        id: 35,
        name: "Sonya Frost",
        position: "Software Engineer",
        office: "Edinburgh",
        badge: "in processwarning",
        color: "warning",
        email: "s.frost@datatables.net",
        salary: "$103,600",
    },
    {
        id: 36,
        name: "Tiger Nixon",
        position: "System Architect",
        office: "Edinburgh",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$320,800",
    },
    {
        id: 37,
        name: "Airi Satou",
        position: "Accountant",
        office: "Tokyo",
        badge: "hired",
        email: "t.nixon@datatables.net",
        color: "success",
        salary: "$162,700",
    },
    {
        id: 38,
        name: "Ashton Cox",
        position: "Junior Technical Author",
        office: "San Francisco",
        badge: "hired",
        color: "warning",
        email: "a.cox@datatables.net",
        salary: "$86,000",
    },
    {
        id: 39,
        name: "Brielle Williamson",
        position: "Integration Specialist",
        office: "New York",
        badge: "Pending",
        color: "danger",
        email: "b.williamson@datatables.net",
        salary: "$372,000",
    },
    {
        id: 40,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "c.kelly@datatables.net",
        salary: "$433,060",
    },
    {
        id: 41,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$90,560",
    },
    {
        id: 42,
        name: "Quinn Flynn",
        position: "Support Lead",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "q.flynn@datatables.net",
        salary: "$342,000",
    },
    {
        id: 43,
        name: "Rhona Davidson",
        position: "Integration Specialist",
        office: "Tokyo",
        badge: "hired",
        color: "success",
        email: "r.davidson@datatables.net",
        salary: "$327,900",
    },
    {
        id: 44,
        name: "Sonya Frost",
        position: "Software Engineer",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "s.frost@datatables.net",
        salary: "$103,600",
    },
    {
        id: 45,
        name: "Tiger Nixon",
        position: "System Architect",
        office: "Edinburgh",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$320,800",
    },
    {
        id: 46,
        name: "Donna Snider",
        position: "Customer Support",
        office: "New York",
        badge: "hired",
        color: "success",
        email: "d.snider@datatables.net",
        salary: "$112,000",
    },
    {
        id: 47,
        name: "Garrett Winters",
        position: "Accountant",
        office: "Tokyo",
        badge: "Pending",
        color: "danger",
        email: "g.winters@datatables.net",
        salary: "$170,750",
    },
    {
        id: 48,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        badge: "in process",
        color: "warning",
        email: "h.chandler@datatables.net",
        salary: "$137,500",
    },
    {
        id: 49,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        badge: "hired",
        color: "success",
        email: "j.gaines@datatables.net",
        salary: "$90,560",
    },
    {
        id: 50,
        name: "Quinn Flynn",
        position: "Support Lead",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "q.flynn@datatables.net",
        salary: "$342,000",
    },
    {
        id: 51,
        name: "Airi Satou",
        position: "Accountant",
        office: "Tokyo",
        badge: "hired",
        email: "t.nixon@datatables.net",
        color: "success",
        salary: "$162,700",
    },
    {
        id: 52,
        name: "Ashton Cox",
        position: "Junior Technical Author",
        office: "San Francisco",
        badge: "hired",
        color: "warning",
        email: "a.cox@datatables.net",
        salary: "$86,000",
    },
    {
        id: 53,
        name: "Brielle Williamson",
        position: "Integration Specialist",
        office: "New York",
        badge: "Pending",
        color: "danger",
        email: "b.williamson@datatables.net",
        salary: "$372,000",
    },
    {
        id: 54,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        badge: "in process",
        color: "warning",
        email: "c.kelly@datatables.net",
        salary: "$433,060",
    },
    {
        id: 55,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        badge: "in process",
        color: "warning",
        email: "c.marshall@datatables.net",
        salary: "$470,600",
    },
    {
        id: 56,
        name: "Colleen Hurst",
        position: "Javascript Developer",
        office: "San Francisco",
        badge: "Pending",
        color: "danger",
        email: "c.hurst@datatables.net",
        salary: "$205,500",
    },
    {
        id: 57,
        name: "Donna Snider",
        position: "Customer Support",
        office: "New York",
        badge: "hired",
        color: "success",
        email: "d.snider@datatables.net",
        salary: "$112,000",
    },
];

export const javascriptSourcedData: AjaxSourcedColumnsInterface[] = [
    {
        id: 1,
        name: "Angelica Ramos",
        position: "Chief Executive Officer (CEO)",
        office: "London",
        extends: "5797",
        date: "2009/10/09",
        salary: "$1,200,000",
    },
    {
        id: 2,
        name: "Archana Desai",
        position: "Accountant",
        office: "Tokyo",
        extends: "5407",
        date: "2008/11/28",
        salary: "$162,700",
    },
    {
        id: 3,
        name: "Ashton Cox",
        position: "Junior Technical Author",
        office: "San Francisco",
        extends: "1562",
        date: "2009/01/12",
        salary: "$86,000",
    },
    {
        id: 4,
        name: "Bradley Greer",
        position: "Software Engineer",
        office: "London",
        extends: "2558",
        date: "2012/10/13",
        salary: "$132,000",
    },
    {
        id: 5,
        name: "Brenden Wagner",
        position: "Software Engineer",
        office: "San Francisco",
        extends: "1314",
        date: "2011/06/07",
        salary: "$206,850",
    },
    {
        id: 6,
        name: "Bruno Nash",
        position: "Software Engineer",
        office: "London",
        extends: "6222",
        date: "2011/05/03",
        salary: "$163,500",
    },
    {
        id: 7,
        name: "Caesar Vance",
        position: "Pre-Sales Support",
        office: "New York",
        extends: "8330",
        date: "2011/12/12",
        salary: "$106,450",
    },
    {
        id: 8,
        name: "Cara Stevens",
        position: "Sales Assistant",
        office: "New York",
        extends: "3990",
        date: "2011/12/06",
        salary: "$145,600",
    },
    {
        id: 9,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        extends: "6224",
        date: "2012/03/29",
        salary: "$433,060",
    },
    {
        id: 10,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        extends: "6741",
        date: "2008/10/16",
        salary: "$470,600",
    },
    {
        id: 11,
        name: "Dai Rios",
        position: "Personnel Lead",
        office: "Edinburgh",
        extends: "2290",
        date: "2012/09/26",
        salary: "$217,500",
    },
    {
        id: 12,
        name: "Doris Wilder",
        position: "Sales Assistant",
        office: "Sidney",
        extends: "3023",
        date: "2010/09/20",
        salary: "$85,600",
    },
    {
        id: 13,
        name: "Fiona Green",
        position: "Chief Executive Officer (CEO)",
        office: "San Francisco",
        extends: "2947",
        date: "2010/03/11",
        salary: "$850,000",
    },
    {
        id: 14,
        name: "Garrett Winters",
        position: "Accountant",
        office: "Tokyo",
        extends: "8422",
        date: "2011/07/25",
        salary: "$170,750",
    },
    {
        id: 15,
        name: "Gavin Cortez",
        position: "Team Leader",
        office: "San Francisco",
        extends: "2860",
        date: "2008/10/26",
        salary: "$235,500",
    },
    {
        id: 16,
        name: "Gavin Joyce",
        position: "Developer",
        office: "Edinburgh",
        extends: "8822",
        date: "2010/12/22",
        salary: "$92,575",
    },
    {
        id: 17,
        name: "Gloria Little",
        position: "Systems Administrator",
        office: "New York",
        extends: "1721",
        date: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 18,
        name: "Haley Kennedy",
        position: "Senior Marketing Designer",
        office: "London",
        extends: "3597",
        date: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 19,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        extends: "9608",
        date: "2012/08/06",
        salary: "$137,500",
    },
    {
        id: 20,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        extends: "3814",
        date: "2008/12/19",
        salary: "$90,560",
    },
    {
        id: 21,
        name: "Gloria Little",
        position: "Systems Administrator",
        office: "New York",
        extends: "1721",
        date: "2009/04/10",
        salary: "$237,500",
    },
    {
        id: 22,
        name: "Haley Kennedy",
        position: "Senior Marketing Designer",
        office: "London",
        extends: "3597",
        date: "2012/12/18",
        salary: "$313,500",
    },
    {
        id: 23,
        name: "Hermione Butler",
        position: "Regional Director",
        office: "London",
        extends: "1016",
        date: "2011/03/21",
        salary: "$356,250",
    },
    {
        id: 24,
        name: "Herrod Chandler",
        position: "Sales Assistant",
        office: "San Francisco",
        extends: "9608",
        date: "2012/08/06",
        salary: "$137,500",
    },

    {
        id: 25,
        name: "Hope Fuentes",
        position: "Secretary",
        office: "San Francisco",
        extends: "6318",
        date: "2010/02/12",
        salary: "$109,850",
    },
    {
        id: 26,
        name: "Howard Hatfield",
        position: "Office Manager",
        office: "San Francisco",
        extends: "7031",
        date: "2008/12/16",
        salary: "$164,500",
    },
    {
        id: 27,
        name: "Jackson Bradshaw",
        position: "Director",
        office: "New York",
        extends: "1042",
        date: "2008/09/26",
        salary: "$645,750",
    },
    {
        id: 28,
        name: "Jena Gaines",
        position: "Office Manager",
        office: "London",
        extends: "3814",
        date: "2008/12/19",
        salary: "$90,560",
    },
    {
        id: 29,
        name: "Jenette Caldwell",
        position: "Development Lead",
        office: "New York",
        extends: "1937",
        date: "2011/09/03",
        salary: "$345,000",
    },
    {
        id: 30,
        name: "Jennifer Acosta",
        position: "Junior Javascript Developer",
        office: "Edinburgh",
        extends: "3431",
        date: "2013/02/01",
        salary: "$75,650",
    },
    {
        id: 31,
        name: "Bruno Nash",
        position: "Software Engineer",
        office: "London",
        extends: "6222",
        date: "2011/05/03",
        salary: "$163,500",
    },
    {
        id: 32,
        name: "Caesar Vance",
        position: "Pre-Sales Support",
        office: "New York",
        extends: "8330",
        date: "2011/12/12",
        salary: "$106,450",
    },
    {
        id: 33,
        name: "Cara Stevens",
        position: "Sales Assistant",
        office: "New York",
        extends: "3990",
        date: "2011/12/06",
        salary: "$145,600",
    },
    {
        id: 34,
        name: "Cedric Kelly",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        extends: "6224",
        date: "2012/03/29",
        salary: "$433,060",
    },
    {
        id: 35,
        name: "Charde Marshall",
        position: "Regional Director",
        office: "San Francisco",
        extends: "6741",
        date: "2008/10/16",
        salary: "$470,600",
    },
    {
        id: 36,
        name: "Archana Desai",
        position: "Accountant",
        office: "Tokyo",
        extends: "5407",
        date: "2008/11/28",
        salary: "$162,700",
    },
];

export const serverSideProcessingColumns: TableColumn<ServerSideProcessingInterface>[] = [
    {
        name: "First Name",
        selector: (row) => row.name,
        sortable: true,
    },
    {
        name: "Last Name",
        selector: (row) => row.lastName,
        sortable: true,
    },
    {
        name: "Position",
        selector: (row) => row.position,
        sortable: true,
    },
    {
        name: "Office",
        selector: (row) => row.office,
        sortable: true,
    },
    {
        name: "Start Date",
        selector: (row) => row.date,
        sortable: true,
    },
    {
        name: "Salary",
        selector: (row) => row.salary,
        sortable: true,
    },
];

export const serverSideData: ServerSideProcessingInterface[] = [
    {
        id: 1,
        name: "Angelica",
        position: "Chief Executive Officer (CEO)",
        office: "London",
        lastName: "Ramos",
        date: "2009/10/09",
        salary: "$1,200,000"
    },
    {
        id: 2,
        name: "Archana",
        position: "Accountant",
        office: "Tokyo",
        lastName: "Desai",
        date: "2008/11/28",
        salary: "$162,700"
    },
    {
        id: 3,
        name: "Ashton",
        position: "Junior Technical Author",
        office: "San Francisco",
        lastName: "Cox",
        date: "2009/01/12",
        salary: "$86,000"
    },
    {
        id: 4,
        name: "Bradley",
        position: "Software Engineer",
        office: "London",
        lastName: "Greer",
        date: "2012/10/13",
        salary: "$132,000"
    },
    {
        id: 5,
        name: "Brenden",
        position: "Software Engineer",
        office: "San Francisco",
        lastName: "Wagner",
        date: "2011/06/07",
        salary: "$206,850"
    },
    {
        id: 6,
        name: "Bruno",
        position: "Software Engineer",
        office: "London",
        lastName: "Nash",
        date: "2011/05/03",
        salary: "$163,500"
    },
    {
        id: 7,
        name: "Caesar",
        position: "Pre-Sales Support",
        office: "New York",
        lastName: "Vance",
        date: "2011/12/12",
        salary: "$106,450"
    },
    {
        id: 8,
        name: "Cara",
        position: "Sales Assistant",
        office: "New York",
        lastName: "Stevens",
        date: "2011/12/06",
        salary: "$145,600"
    },
    {
        id: 9,
        name: "Cedric",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        lastName: "Kelly",
        date: "2012/03/29",
        salary: "$433,060"
    },
    {
        id: 10,
        name: "Charde",
        position: "Regional Director",
        office: "San Francisco",
        lastName: "Marshall",
        date: "2008/10/16",
        salary: "$470,600"
    },
    {
        id: 11,
        name: "Dai",
        position: "Personnel Lead",
        office: "Edinburgh",
        lastName: "Rios",
        date: "2012/09/26",
        salary: "$217,500"
    },
    {
        id: 12,
        name: "Doris",
        position: "Sales Assistant",
        office: "Sidney",
        lastName: "Wilder",
        date: "2010/09/20",
        salary: "$85,600"
    },
    {
        id: 13,
        name: "Fiona",
        position: "Chief Executive Officer (CEO)",
        office: "San Francisco",
        lastName: "Green",
        date: "2010/03/11",
        salary: "$850,000"
    },
    {
        id: 14,
        name: "Garrett",
        position: "Accountant",
        office: "Tokyo",
        lastName: "Winters",
        date: "2011/07/25",
        salary: "$170,750"
    },
    {
        id: 15,
        name: "Gavin",
        position: "Team Leader",
        office: "San Francisco",
        lastName: "Cortez",
        date: "2008/10/26",
        salary: "$235,500"
    },
    {
        id: 16,
        name: "Gavin",
        position: "Developer",
        office: "Edinburgh",
        lastName: "Joyce",
        date: "2010/12/22",
        salary: "$92,575"
    },
    {
        id: 17,
        name: "Gloria",
        position: "Systems Administrator",
        office: "New York",
        lastName: "Little",
        date: "2009/04/10",
        salary: "$237,500"
    },
    {
        id: 18,
        name: "Haley",
        position: "Senior Marketing Designer",
        office: "London",
        lastName: "Kennedy",
        date: "2012/12/18",
        salary: "$313,500"
    },
    {
        id: 19,
        name: "Herrod",
        position: "Sales Assistant",
        office: "San Francisco",
        lastName: "Chandler",
        date: "2012/08/06",
        salary: "$137,500"
    },
    {
        id: 20,
        name: "Jena",
        position: "Office Manager",
        office: "London",
        lastName: "Gaines",
        date: "2008/12/19",
        salary: "$90,560"
    },
    {
        id: 21,
        name: "Gloria",
        position: "Systems Administrator",
        office: "New York",
        lastName: "Little",
        date: "2009/04/10",
        salary: "$237,500"
    },
    {
        id: 22,
        name: "Haley",
        position: "Senior Marketing Designer",
        office: "London",
        lastName: "Kennedy",
        date: "2012/12/18",
        salary: "$313,500"
    },
    {
        id: 23,
        name: "Hermione",
        position: "Regional Director",
        office: "London",
        lastName: "Butler",
        date: "2011/03/21",
        salary: "$356,250"
    },
    {
        id: 24,
        name: "Herrod",
        position: "Sales Assistant",
        office: "San Francisco",
        lastName: "Chandler",
        date: "2012/08/06",
        salary: "$137,500"
    },

    {
        id: 25,
        name: "Hope",
        position: "Secretary",
        office: "San Francisco",
        lastName: "Fuentes",
        date: "2010/02/12",
        salary: "$109,850"
    },
    {
        id: 26,
        name: "Howard",
        position: "Office Manager",
        office: "San Francisco",
        lastName: "Hatfield",
        date: "2008/12/16",
        salary: "$164,500"
    },
    {
        id: 27,
        name: "Jackson",
        position: "Director",
        office: "New York",
        lastName: "Bradshaw",
        date: "2008/09/26",
        salary: "$645,750"
    },
    {
        id: 28,
        name: "Jena",
        position: "Office Manager",
        office: "London",
        lastName: "Gaines",
        date: "2008/12/19",
        salary: "$90,560"
    },
    {
        id: 29,
        name: "Jenette",
        position: "Development Lead",
        office: "New York",
        lastName: "Caldwell",
        date: "2011/09/03",
        salary: "$345,000"
    },
    {
        id: 30,
        name: "Jennifer",
        position: "Junior Javascript Developer",
        office: "Edinburgh",
        lastName: "Acosta",
        date: "2013/02/01",
        salary: "$75,650"
    },
    {
        id: 31,
        name: "Bruno",
        position: "Software Engineer",
        office: "London",
        lastName: "Nash",
        date: "2011/05/03",
        salary: "$163,500"
    },
    {
        id: 32,
        name: "Caesar",
        position: "Pre-Sales Support",
        office: "New York",
        lastName: "Vance",
        date: "2011/12/12",
        salary: "$106,450"
    },
    {
        id: 33,
        name: "Cara",
        position: "Sales Assistant",
        office: "New York",
        lastName: "Stevens",
        date: "2011/12/06",
        salary: "$145,600"
    },
    {
        id: 34,
        name: "Cedric",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        lastName: "Kelly",
        date: "2012/03/29",
        salary: "$433,060"
    },
    {
        id: 35,
        name: "Charde",
        position: "Regional Director",
        office: "San Francisco",
        lastName: "Marshall",
        date: "2008/10/16",
        salary: "$470,600"
    },
    {
        id: 36,
        name: "Archana",
        position: "Accountant",
        office: "Tokyo",
        lastName: "Desai",
        date: "2008/11/28",
        salary: "$162,700"
    },
    {
        id: 37,
        name: "Ashton",
        position: "Junior Technical Author",
        office: "San Francisco",
        lastName: "Cox",
        date: "2009/01/12",
        salary: "$86,000"
    },
    {
        id: 38,
        name: "Bradley",
        position: "Software Engineer",
        office: "London",
        lastName: "Greer",
        date: "2012/10/13",
        salary: "$132,000"
    },
    {
        id: 39,
        name: "Brenden",
        position: "Software Engineer",
        office: "San Francisco",
        lastName: "Wagner",
        date: "2011/06/07",
        salary: "$206,850"
    },
    {
        id: 40,
        name: "Bruno",
        position: "Software Engineer",
        office: "London",
        lastName: "Nash",
        date: "2011/05/03",
        salary: "$163,500"
    },
    {
        id: 41,
        name: "Herrod",
        position: "Sales Assistant",
        office: "San Francisco",
        lastName: "Chandler",
        date: "2012/08/06",
        salary: "$137,500"
    },
    {
        id: 42,
        name: "Jena",
        position: "Office Manager",
        office: "London",
        lastName: "Gaines",
        date: "2008/12/19",
        salary: "$90,560"
    },
    {
        id: 43,
        name: "Gloria",
        position: "Systems Administrator",
        office: "New York",
        lastName: "Little",
        date: "2009/04/10",
        salary: "$237,500"
    },
    {
        id: 44,
        name: "Haley",
        position: "Senior Marketing Designer",
        office: "London",
        lastName: "Kennedy",
        date: "2012/12/18",
        salary: "$313,500"
    },
    {
        id: 45,
        name: "Hermione",
        position: "Regional Director",
        office: "London",
        lastName: "Butler",
        date: "2011/03/21",
        salary: "$356,250"
    },
    {
        id: 46,
        name: "Cedric",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        lastName: "Kelly",
        date: "2012/03/29",
        salary: "$433,060"
    },
    {
        id: 47,
        name: "Charde",
        position: "Regional Director",
        office: "San Francisco",
        lastName: "Marshall",
        date: "2008/10/16",
        salary: "$470,600"
    },
    {
        id: 48,
        name: "Dai",
        position: "Personnel Lead",
        office: "Edinburgh",
        lastName: "Rios",
        date: "2012/09/26",
        salary: "$217,500"
    },
    {
        id: 49,
        name: "Doris",
        position: "Sales Assistant",
        office: "Sidney",
        lastName: "Wilder",
        date: "2010/09/20",
        salary: "$85,600"
    },
    {
        id: 50,
        name: "Fiona",
        position: "Chief Executive Officer (CEO)",
        office: "San Francisco",
        lastName: "Green",
        date: "2010/03/11",
        salary: "$850,000"
    },
    {
        id: 51,
        name: "Jennifer",
        position: "Junior Javascript Developer",
        office: "Edinburgh",
        lastName: "Acosta",
        date: "2013/02/01",
        salary: "$75,650"
    },
    {
        id: 52,
        name: "Bruno",
        position: "Software Engineer",
        office: "London",
        lastName: "Nash",
        date: "2011/05/03",
        salary: "$163,500"
    },
    {
        id: 53,
        name: "Caesar",
        position: "Pre-Sales Support",
        office: "New York",
        lastName: "Vance",
        date: "2011/12/12",
        salary: "$106,450"
    },
    {
        id: 54,
        name: "Cara",
        position: "Sales Assistant",
        office: "New York",
        lastName: "Stevens",
        date: "2011/12/06",
        salary: "$145,600"
    },
    {
        id: 55,
        name: "Cedric",
        position: "Senior Javascript Developer",
        office: "Edinburgh",
        lastName: "Kelly",
        date: "2012/03/29",
        salary: "$433,060"
    },
    {
        id: 56,
        name: "Charde",
        position: "Regional Director",
        office: "San Francisco",
        lastName: "6741",
        date: "2008/10/16",
        salary: "$470,600"
    },
    {
        id: 57,
        name: "Archana",
        position: "Accountant",
        office: "Tokyo",
        lastName: "Desai",
        date: "2008/11/28",
        salary: "$162,700"
    }
]