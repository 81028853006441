import React, { Fragment, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs'
import {TestimonialListTitle,TestimonialTitlee } from '../../../../Utils/Constants'

import MarketAndSolutionNav from "./MarketAndSolutionNav"
import MarketAndSolutionList from "./MarketAndSolutionTable";

export default function MainCategoryListContainer() {
    const [activeTab, setActiveTab] = useState("1");

    return (
        <Fragment>
            <Breadcrumbs pageTitle={'Market & Solutions'} parent={'Market & Solutions'} title={''} />
            <Container fluid>
                <Row className="project-cards">
                    <Col md={12} className="project-list">
                        <MarketAndSolutionNav activeTab={activeTab} setActiveTab={setActiveTab} />
                    </Col>
                    <Col sm={12}>
                        <MarketAndSolutionList />
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )
}
