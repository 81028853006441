import axios from "axios";
import { api } from "../../api";
import { BlogTableColumn } from "../../Types/TableType";
// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};


interface BlogResponse {
  data: any; // Adjust this based on the actual response structure
}

export const fetchBlog = async (): Promise<BlogResponse> => {
  try {
    const response = await axios.get(`${api}/blogs/blogRetrieve`, {
      headers: { Authorization: getUserToken() },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch Blog.");
  }
};

// BlogApi.tsx
export const createBlog = async (formData: FormData): Promise<BlogResponse> => { 
  try {
    console.log("formData", formData);
    const response = await axios.post(`${api}/blogs/blogCreation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data', // Correct header for file uploads
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to create Blog.");
  }
};


export const updateBlog = async (blogData : BlogTableColumn ): Promise<BlogResponse> => {
  try {
    console.log("blogData",blogData)
    const response = await axios.put(`${api}/blogs/blogUpdate`,blogData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to update milestone.");
  }
};

export const deleteBlog = async (Blog_code: string): Promise<BlogResponse> => {
  try {
    const response = await axios.delete(`${api}/blogs/blogDelete?blogCode=${Blog_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete Blog.");
  }
};
