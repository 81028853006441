import React,{useState} from 'react'

import BlogBoxTable from '../../../../Components/Miscellaneous/Blog/BlogListTabs'

export default function BlogTable() {
    const [activeTab, setActiveTab] = useState('1');
  return (
    <div className='page-body'>
        <BlogBoxTable activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  )
}
