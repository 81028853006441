import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs'; 
// import { ProductInitTitle, ProductsTitle } from '../../../../../Utils/Constants';  // Updated constants for products
import ProductRowCreateCallback from './RowCreateCallBack';  // Updated to the new product row creation component

export default function ProductInitContainer() {
    return (
        <Fragment>
            {/* Updated Breadcrumbs */}
            {/* <Breadcrumbs pageTitle={ProductInitTitle} parent={ProductsTitle} title={ProductInitTitle} />   */}
            <Container fluid>
                <Row>
                    <ProductRowCreateCallback />  {/* Updated callback component for products */}
                </Row>
            </Container>
        </Fragment>
    );
}
