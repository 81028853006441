import React, { Fragment } from 'react';
import Breadcrumbs from '../../../../CommonElements/Breadcrumbs';
import { TechnologyCreateTitle, AppsTitle } from '../../../../Utils/Constants'; // Adjusted for technologies
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import CreateNewTechnologyForm from "./CreateNewTechnology"; // Adjusted the import for the technology form

export default function TechnologyCreateContainer() {
    return (
        <Fragment>
            <Breadcrumbs pageTitle={TechnologyCreateTitle} parent={AppsTitle} title={TechnologyCreateTitle} /> {/* Updated for technologies */}
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <Card>
                            <CardBody>
                                <CreateNewTechnologyForm /> {/* Changed to use the technology form */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
}
