import axios from "axios";
import { api } from "../../api";
import { ClientColumnData } from "../../Types/TableType";
// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};


interface ClientResponse {
  data: any; // Adjust this based on the actual response structure
}

export const fetchClient = async (): Promise<ClientResponse> => {
  try {
    const response = await axios.get(`${api}/clients/getclients`, {
      headers: { Authorization: getUserToken() },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch Client.");
  }
};

// ClientApi.tsx
export const createClient = async (formData: FormData): Promise<ClientResponse> => { 
  try {
    const response = await axios.post(`${api}/clients/clientCreation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data', // Correct header for file uploads
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to create Client.");
  }
};


// ClientApi.tsx
export const updateClient = async (clientData: ClientColumnData): Promise<ClientResponse> => {
  try {
    const response = await axios.put(`${api}/clients/clientUpdate`, clientData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to update client.");
  }
};


export const deleteClient = async (client_code: string): Promise<ClientResponse> => {
  try {
    const response = await axios.delete(`${api}/clients/deleteclient?client_code=${client_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete Client.");
  }
};
