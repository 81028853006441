import axios from "axios";
import { api } from "../../api";
import { LoginData } from "../../Types/TableType";

export const getUserToken = (): string | null => {
  const tokenStr = sessionStorage.getItem("userToken");
  return tokenStr ? `${tokenStr}` : null;
};

export const adminLogin = async (credentials: LoginData) => {
  const { email, password } = credentials;
  try {
    const response = await axios.get(`${api}/admin/systemUserLogin`, {
      params: {
        email,
        password,
      },
    });

    if (response && response.data && response.data.data && response.data.data.token) {
      sessionStorage.setItem("userToken", response.data.data.token); // Store token
      return response.data; // Return the whole response
    } else {
      throw new Error("Invalid response structure from API");
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios-specific error handling
      throw new Error(error.response?.data?.message || "Failed to login");
    } else {
      // Fallback for non-Axios errors
      throw new Error(error instanceof Error ? error.message : "Failed to login");
    }
  }
};

export const adminLogout = async () => {
  try {
    const response = await axios({
      method: "POST",
      url: `${api}/admin/systemUserLogout`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
      },
    });
    sessionStorage.removeItem("userToken");
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios-specific error handling
      throw new Error(error.response?.data?.message || "Failed to logout");
    } else {
      throw new Error(error instanceof Error ? error.message : "Failed to logout");
    }
  }
};

export const getAdmin = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${api}/admin/getSystemUserList`,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("userToken")}`,
      },
    });
    console.log("response",response)
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // Axios-specific error handling
      throw new Error(error.response?.data?.message || "Failed to logout");
    } else {
      throw new Error(error instanceof Error ? error.message : "Failed to logout");
    }
  }
};
