import React from 'react'
import MilestoneCreateContainer from '../../../../Components/Apps/Milestones/MilestoneCreate'

export default function MilestoneCreate() {
  return (
    <div className='page-body'>
        <MilestoneCreateContainer/>
    </div>
  )
}
