import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchFeatures, createFeature, deleteFeature, updateFeature } from '../Apis/powerfulFeaturesApi'; // Replace with your features API functions

// Define FeatureFormData type based on the feature schema
interface FeatureFormData {
    _id?: string; // Optional for newly created features
    feature_code: string;
    name: string;
    icon: string;
    redirection_link: string;
    feature_status: string;
    created_date?: string; // Optional since created/updated dates might be handled by the server
    updated_date?: string;
    created_by?: string;
    updated_by?: string;
}

interface FeatureResponse {
    _id?: string; // Optional for newly created features
    feature_code: string;
    name: string;
    icon: string;
    redirection_link: string;
    feature_status: string;
    created_date?: string; // Optional since created/updated dates might be handled by the server
    updated_date?: string;
    created_by?: string;
    updated_by?: string;
}

interface State {
    data: {
        [key: string]: FeatureResponse; // Allow dynamic keys for each feature
    };
    isLoading: boolean;
    error: string | null; // Allow null values for error
}

const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch feature data
export const fetchFeaturesData = createAsyncThunk(
    'features/fetchFeaturesData',
    async () => {
        const features = await fetchFeatures();
        console.log("API response inside fetchFeaturesData:", features);
        return features; // Assuming features API returns an array or object
    }
);

export const deleteFeatureData = createAsyncThunk(
    "features/deleteFeatureData",
    async (feature_code: string) => {
        const data = await deleteFeature(feature_code);
        return data;
    }
);

export const createFeatureData = createAsyncThunk(
    "features/createFeatureData",
    async (formData: FormData) => {
        const data = await createFeature(formData);
        console.log("data reducers :", data)
        return data;
    }
);

export const updateFeatureData = createAsyncThunk(
    "features/updateFeatureData",
    async (formData: FormData) => {
        const data = await updateFeature(formData);
        return data;
    }
);

const featuresSlice = createSlice({
    name: 'features',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchFeaturesData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchFeaturesData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Assuming the response has a data field
            })
            .addCase(fetchFeaturesData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null; // Handle error
            })
            .addCase(createFeatureData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createFeatureData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, add new feature to the state
                // state.data = { ...state.data, [action.payload._id]: action.payload };
            })
            .addCase(createFeatureData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(updateFeatureData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateFeatureData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the feature in the state
                // state.data[action.payload._id] = action.payload;
            })
            .addCase(updateFeatureData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteFeatureData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteFeatureData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default featuresSlice.reducer;
