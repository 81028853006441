import React from 'react'
import ProjectDashboard from '../../../Components/Dashboards/Project'

export default function Project() {
    return (
        <div className='page-body'>
            <ProjectDashboard />
        </div>
    )
}
