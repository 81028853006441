import React from 'react'
import CalendarContainer from '../../../Components/Apps/Calendar'

export default function Calendar() {
  return (
    <div className='page-body'>
        <CalendarContainer/>
    </div>
  )
}
