import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProducts, createProduct, deleteProduct, updateProduct } from '../Apis/productApi'; // Replace with your products API functions

// Define ProductFormData type based on the product schema
interface ProductFormData {
    _id?: string; // Optional for newly created products
    productName: string;
    productCode: string;
    product_main_image: string;
    product_all_images: string[];
    productDescription: string;
    productShortDescription: string;
    regularPrice: number;
    salePrice: number;
    service_code: string;
    productTags: string;
    productStatus: string;
    createdAt?: string; // Optional since created/updated dates might be handled by the server
    updatedAt?: string;
    created_by?: string;
    updated_by?: string;
}

interface ProductResponse {
    _id?: string; // Optional for newly created products
    productName: string;
    productCode: string;
    product_main_image: string;
    product_all_images: string[];
    productDescription: string;
    productShortDescription: string;
    regularPrice: number;
    salePrice: number;
    service_code: string;
    productTags: string;
    productStatus: string;
    createdAt?: string; // Optional since created/updated dates might be handled by the server
    updatedAt?: string;
    created_by?: string;
    updated_by?: string;
}

interface State {
    data: {
        [key: string]: ProductResponse; // Allow dynamic keys for each product
    };
    isLoading: boolean;
    error: string | null; // Allow null values for error
}

const initialState: State = {
    data: {},
    isLoading: false,
    error: null,
};

// Async thunk to fetch product data
export const fetchProductsData = createAsyncThunk(
    'products/fetchProductsData',
    async () => {
        const products = await fetchProducts();
        console.log("API response inside fetchProductsData:", products);
        return products; // Assuming products API returns an array or object
    }
);

export const deleteProductData = createAsyncThunk(
    "products/deleteProductData",
    async (productCode: string) => {
        const data = await deleteProduct(productCode);
        return data;
    }
);

export const createProductData = createAsyncThunk(
    "products/createProductData",
    async (formData: FormData) => {
        const data = await createProduct(formData);
        console.log("data reducers :", data);
        return data;
    }
);

export const updateProductData = createAsyncThunk(
    "products/updateProductData",
    async (formData: FormData) => {
        const data = await updateProduct(formData); // This should work with FormData now
        return data;
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProductsData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchProductsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload.data; // Assuming the response has a data field
            })
            .addCase(fetchProductsData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null; // Handle error
            })
            .addCase(createProductData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(createProductData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Optionally, add new product to the state
                // state.data[action.payload._id] = action.payload; // Add the new product to state
            })
            .addCase(createProductData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(updateProductData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateProductData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the product in the state
                // state.data[action.payload._id] = action.payload;
            })
            .addCase(updateProductData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteProductData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteProductData.fulfilled, (state, action) => {
                state.isLoading = false;
                // Remove the deleted product from the state
                // delete state.data[action.payload.product_Code]; // Assuming productCode is used to identify the product
            })
            .addCase(deleteProductData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default productsSlice.reducer;
