import React from 'react'
import BookmarksContainer from '../../../Components/Apps/Bookmarks'

export default function Bookmarks() {
  return (
    <div className='page-body'>
        <BookmarksContainer/>
    </div>
  )
}
