import React, { Fragment } from 'react';
import { Container, Row } from 'reactstrap';
import Breadcrumbs from '../../../../../CommonElements/Breadcrumbs'; 
// import { ServiceInitTitle, ServicesTitle } from '../../../../../Utils/Constants';  // Updated constants for services
import ServiceRowCreateCallback from './RowCreateCallback';  // Updated to the new service row creation component

export default function ServiceInitContainer() {
    return (
        <Fragment>
            {/* Updated Breadcrumbs */}
            {/* <Breadcrumbs pageTitle={ServiceInitTitle} parent={ServicesTitle} title={ServiceInitTitle} />   */}
            <Container fluid>
                <Row>
                    <ServiceRowCreateCallback />  {/* Updated callback component for services */}
                </Row>
            </Container>
        </Fragment>
    );
}
