import React,{useState} from 'react'

import CreateNewBlogForm from '../../../../Components/Miscellaneous/Blog/AddBlog';

export default function BlogAdd() {
    const [activeTab, setActiveTab] = useState('1');
  return (
    <div className='page-body'>
        <CreateNewBlogForm activeTab={activeTab} setActiveTab={setActiveTab} />
    </div>
  )
}
