import { Col, Container, Row } from "reactstrap";
import { P } from "../AbstractElements";
import { FooterText } from "../Utils/Constants";
import SvgIcon from "../Utils/CommonComponents/CommonIcons/CommonSvgIcons";

export default function FooterLayout() {
    return (
        <footer className="footer">
            <Container fluid>
                <Row>
                    <Col md={6} className="p-0 footer-copyright">
                        <P className="mb-0">{FooterText}</P>
                    </Col>
                    <Col md={6} className="p-0">
                        <P className="heart mb-0">
                           Designed By Social Collage
                            {/* <SvgIcon className="footer-icon" iconId="heart" /> */}
                        </P>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}