import React from 'react'
import FlagIconsContainer from '../../../Components/Icons/FlagIcons'

export default function FlagIcons() {
  return (
    <div className='page-body'>
        <FlagIconsContainer/>
    </div>
  )
}
