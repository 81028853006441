import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";
import DataTable from 'react-data-table-component';
import { RootState, AppDispatch } from '../../../../../redux-toolkit/store';
import { TableColumn } from "react-data-table-component";
import CardHeaderSpan from '../../../../../Utils/CommonComponents/CardHeaderSpan';
import { TestimonialTitle } from '../../../../../Utils/Constants';
import FilterComponent from '../../../../Tables/DataTables/Common/FilterComponent';
import { fetchData, deleteTestimonialData, updateTestimonialData } from '../../../../../redux-toolkit/reducers/TestimonialReducer';
import { TestimonialTableColumnData } from '../../../../../Types/TableType';

export default function RowCreateCallback() {
    const [filterText, setFilterText] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);  
    const [selectedTestimonial, setSelectedTestimonial] = useState<TestimonialTableColumnData | null>(null);  
    const [editData, setEditData] = useState<TestimonialTableColumnData>({
        testimonial_code: '',
        testimonial_auth_name: '',
        testimonial_auth_designation: '',
        testimonial_description: '',
        testimonial_auth_image: '',
    }); 
    const dispatch: AppDispatch = useDispatch();
    
    const testimonail = useSelector((state: RootState) => state.Testimonial);
    const testimonailData: TestimonialTableColumnData[] = Array.isArray(testimonail?.data) 
        ? testimonail.data 
        : Object.values(testimonail?.data || {});

    useEffect(() => {
        const fetchMilestones = async () => {
            try {
                await dispatch(fetchData());
            } catch (error) {
                console.error("Error fetching testimonail:", error);
            }
        };

        fetchMilestones();
    }, [dispatch]);

    const filteredItems: TestimonialTableColumnData[] = testimonailData.filter(
        (item) =>
            Object.values(item).some((value) =>
                value && value.toString().toLowerCase().includes(filterText.toLowerCase())
            )
    );

    const handleEdit = (row: TestimonialTableColumnData) => {
        setSelectedTestimonial(row);
        setEditData(row);
        setIsModalOpen(true);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEditData((prevData) => ({
                    ...prevData,
                    testimonial_auth_image: reader.result as string // Assuming the image will be in base64 format
                }));
            };
            reader.readAsDataURL(file);
        }
    };
    const handleImageChangeNew = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setEditData((prevData) => ({
                    ...prevData,
                    testimonial_image: reader.result as string // Assuming the image will be in base64 format
                }));
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async () => {
        console.log(editData,"editData")
        try {
            await dispatch(updateTestimonialData(editData));  
            dispatch(fetchData());
            setIsModalOpen(false);  
        } catch (error) {
            console.error("Failed to update:", error);
        }
    };

    const handleDelete = (row: TestimonialTableColumnData) => {
        dispatch(deleteTestimonialData(row.testimonial_code))
            .then(() => {
                dispatch(fetchData());
            })
            .catch((error) => {
                console.error("Failed to delete:", error);
            });
    };

    const rowTestimonialCallBackTableColumns: TableColumn<TestimonialTableColumnData>[] = [
        {
            name: "Testimonial Name",
            selector: (row: TestimonialTableColumnData) => row.testimonial_auth_name,
            sortable: true,
        },
        {
            name: "Designation",
            selector: (row: TestimonialTableColumnData) => row.testimonial_auth_designation,
            sortable: true,
        },
        {
            name: "Description",
            selector: (row: TestimonialTableColumnData) => row.testimonial_description,
            sortable: true,
        },
        {
            name: "Actions",
            cell: (row: TestimonialTableColumnData) => (
                <div>
                    <button onClick={() => handleEdit(row)} className="btn btn-primary btn-sm">
                        Edit
                    </button>
                    <button onClick={() => handleDelete(row)} className="btn btn-danger btn-sm ms-2">
                        Delete
                    </button>
                </div>
            ),
        },
    ];

    return (
        <Col sm={12}>
            <Card>
                <CardHeaderSpan headingClassName="pb-0 card-no-border" heading={TestimonialTitle} />
                <CardBody>
                    <FilterComponent
                        onFilter={(e: React.ChangeEvent<HTMLInputElement>) => setFilterText(e.target.value)}
                        onClear={() => setFilterText('')}
                        filterText={filterText}
                    />
                    <div className="table-responsive custom-scrollbar">
                        <DataTable 
                            data={filteredItems} 
                            columns={rowTestimonialCallBackTableColumns} 
                            striped 
                            pagination 
                            className="display dataTable" 
                        />
                    </div>
                </CardBody>
            </Card>

            {/* Edit Modal */}
            <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
                <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>Edit Testimonial</ModalHeader>
                <ModalBody>
                    {selectedTestimonial && (
                        <Form>
                            <FormGroup>
                                <Label for="testimonial_auth_name">Name</Label>
                                <Input
                                    type="text"
                                    name="testimonial_auth_name"
                                    value={editData.testimonial_auth_name || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="testimonial_auth_designation">Designation</Label>
                                <Input
                                    type="text"
                                    name="testimonial_auth_designation"
                                    value={editData.testimonial_auth_designation || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="testimonial_description">Description</Label>
                                <Input
                                    type="textarea"
                                    name="testimonial_description"
                                    value={editData.testimonial_description || ''}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="testimonial_auth_image">Change User Image</Label>
                                <Input
                                    type="file"
                                    name="testimonial_auth_image"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                />
                            </FormGroup>
                            {editData.testimonial_auth_image && (
                                <img 
                                    src={editData.testimonial_auth_image} 
                                    alt="Testimonial"
                                    style={{ width: '50%', height: 'auto', marginTop: '5px' }} 
                                />
                            )}
                        </Form>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleSubmit}>Save</Button>{' '}
                    <Button color="secondary" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </Col>
    );
}
