import Scrollable from "../Pages/BonusUi/Scrollable";
import Toasts from "../Pages/BonusUi/Toasts";
import TreeView from "../Pages/BonusUi/TreeView";
import Accordion from "../Pages/UiKits/Accordion";
import Alert from "../Pages/UiKits/Alert";
import Avatars from "../Pages/UiKits/Avatars";
import Dropdown from "../Pages/UiKits/Dropdown";
import Grid from "../Pages/UiKits/Grid";
import HelperClasses from "../Pages/UiKits/HelperClasses";
import Lists from "../Pages/UiKits/Lists";
import Tabs from "../Pages/UiKits/Tabs";
import Popover from "../Pages/UiKits/Popover";
import Tooltip from "../Pages/UiKits/Tooltip";
import Modal from "../Pages/UiKits/Modal";
import Progress from "../Pages/UiKits/Progress";
import TagAndPills from "../Pages/UiKits/Tag&Pills";
import Typography from "../Pages/UiKits/Typography";
// import ProductPage from "../Pages/Apps/Ecommerce/ProductPage";
// import ProductList from "../Pages/Apps/Ecommerce/ProductList";
// import AddProduct from "../Pages/Apps/Ecommerce/AddProduct";
// import Product from "../Pages/Apps/Ecommerce/Product";
import ProjectList from "../Pages/Apps/Project/ProjectList";
import ProjectCreate from "../Pages/Apps/Project/ProjectCreate";
import ProductList from "../Pages/Apps/Product/ProductList";
import ProductCreate from "../Pages/Apps/Product/ProductCreate";
import TechnologyList from "../Pages/Apps/Technology/TechnologyList";
import TechnologyCreate from "../Pages/Apps/Technology/TechnologyCreate";
import MilestoneCreate from "../Pages/Apps/Milestones/MilestoneCreate";
import MilestoneList from "../Pages/Apps/Milestones/MilestonesList";
import ServiceCreate from "../Pages/Apps/Services/ServiceCreate";
import ServiceList from "../Pages/Apps/Services/ServiceList";
import FileManager from "../Pages/Apps/FileManager";
import General from "../Pages/Widgets/General";
import Chart from "../Pages/Widgets/Chart";
// import Ecommerce from "../Pages/Dashboards/Ecommerce";
import Project from "../Pages/Dashboards/Project";
import CkEditor from "../Pages/Miscellaneous/Editors/CkEditor";
import MdeEditor from "../Pages/Miscellaneous/Editors/MdeEditor";
import AceCodeEditor from "../Pages/Miscellaneous/Editors/AceCodeEditor";
import SupportTicket from "../Pages/Miscellaneous/SupportTicket";
import Default from "../Pages/Dashboards/Default";
import Timeline from "../Pages/BonusUi/Timeline";
import FlagIcons from "../Pages/Icons/FlagIcons";
import FontAwesomeIcon from "../Pages/Icons/FontAwesomeIcon";
import IcoIcon from "../Pages/Icons/IcoIcon";
import ThemifyIcon from "../Pages/Icons/ThemifyIcon";
import FeatherIcons from "../Pages/Icons/FeatherIcons";
import GalleryGrid from "../Pages/Miscellaneous/Gallery/GalleryGrid";
import GalleryDescription from "../Pages/Miscellaneous/Gallery/GalleryDescription";
import MasonryGallery from "../Pages/Miscellaneous/Gallery/MasonryGallery";
import MasonryGalleryDescription from "../Pages/Miscellaneous/Gallery/MasonryGalleryDescription";
import ImageHoverEffects from "../Pages/Miscellaneous/Gallery/ImageHoverEffects";
import BlogDetails from "../Pages/Miscellaneous/Blog/BlogDetails";
import BlogSingle from "../Pages/Miscellaneous/Blog/BlogSingle";
import BlogTable from "../Pages/Miscellaneous/Blog/BlogList";
import AddPost from "../Pages/Miscellaneous/Blog/AddPost";
import BasicInit from "../Pages/Tables/DataTables/BasicInit";
import AdvanceInit from "../Pages/Tables/DataTables/AdvanceInit";
import APIDataTables from "../Pages/Tables/DataTables/APIDataTables";
import DataSource from "../Pages/Tables/DataTables/DataSource";
import SamplePage from "../Pages/Pages/SamplePage";
import FormWizard2 from "../Pages/Forms/FormLayout/FormWizard2";
import BasicTables from "../Pages/Tables/ReactstrapTables/BasicTables";
import TableComponents from "../Pages/Tables/ReactstrapTables/TableComponents";
import FormWizard1 from "../Pages/Forms/FormLayout/FormWizard1";
import TwoFactor from "../Pages/Forms/FormLayout/TwoFactor";
import ImageCropper from "../Pages/BonusUi/ImageCropper";
import BasicCard from "../Pages/BonusUi/BasicCard";
import CreativeCard from "../Pages/BonusUi/CreativeCard";
import Paginations from "../Pages/BonusUi/Paginations";
import Breadcrumb from "../Pages/BonusUi/Breadcrumb";
import RangeSlider from "../Pages/BonusUi/RangeSlider";
import SweetAlert from "../Pages/BonusUi/SweetAlert";
import OwlCarousel from "../Pages/BonusUi/OwlCarousel";
import Ribbons from "../Pages/BonusUi/Ribbons";
import Rating from "../Pages/BonusUi/Rating";
import Dropzone from "../Pages/BonusUi/Dropzone";
import Tour from "../Pages/BonusUi/Tour";
import PrivateChat from "../Pages/Apps/Chat/PrivateChat";
import GroupChat from "../Pages/Apps/Chat/GroupChat";
import UserProfile from "../Pages/Apps/Users/UserProfile";
import EditProfile from "../Pages/Apps/Users/EditProfile";
import UserCards from "../Pages/Apps/Users/UserCards";
import SocialApp from "../Pages/Apps/SocialApp";
import SearchResult from "../Pages/Apps/SearchResult";
import Tasks from "../Pages/Apps/Tasks";
import Calendar from "../Pages/Apps/Calendar";
import ToDo from "../Pages/Apps/ToDo";
import Bookmarks from "../Pages/Apps/Bookmarks";
import Contacts from "../Pages/Apps/Contacts";
import EmailApplication from "../Pages/Apps/Email/EmailApplication";
import EmailCompose from "../Pages/Apps/Email/EmailCompose";
import LetterBox from "../Pages/Apps/Email/LetterBox";
// import Invoice1 from "../Pages/Apps/Ecommerce/Invoices/Invoice1";
// import Invoice2 from "../Pages/Apps/Ecommerce/Invoices/Invoice2";
// import Invoice3 from "../Pages/Apps/Ecommerce/Invoices/Invoice3";
// import Invoice4 from "../Pages/Apps/Ecommerce/Invoices/Invoice4";
// import Cart from "../Pages/Apps/Ecommerce/Cart";
// import Wishlist from "../Pages/Apps/Ecommerce/Wishlist";
// import Checkout from "../Pages/Apps/Ecommerce/Checkout";
// import Pricing from "../Pages/Apps/Ecommerce/Pricing";
// import Invoice5 from "../Pages/Apps/Ecommerce/Invoices/Invoice5";
// import Invoice6 from "../Pages/Apps/Ecommerce/Invoices/Invoice6";
// import PaymentDetails from "../Pages/Apps/Ecommerce/PaymentDetails";
// import OrderHistory from "../Pages/Apps/Ecommerce/OrderHistory";
import Faq from "../Pages/Miscellaneous/Faq";
import Knowledgebase from "../Pages/Miscellaneous/Knowledgebase";
import LearningList from "../Pages/Miscellaneous/Learning/LearningList";
import DetailedCourse from "../Pages/Miscellaneous/Learning/DetailedCourse";
import CardsView from "../Pages/Miscellaneous/JobSearch/CardsView";
import ListView from "../Pages/Miscellaneous/JobSearch/ListView";
import JobDetails from "../Pages/Miscellaneous/JobSearch/JobDetails";
import ApplyJobs from "../Pages/Miscellaneous/JobSearch/ApplyJobs";
import GoogleMaps from "../Pages/Miscellaneous/Maps/GoogleMaps";
import LeafletMap from "../Pages/Miscellaneous/Maps/LeafletMap";
import InputMask from "../Pages/Forms/FormControls/InputMask";
import MegaOptions from "../Pages/Forms/FormControls/MegaOptions";
import Datepicker from "../Pages/Forms/FormWidgets/Datepicker";
import GoogleChart from "../Pages/Charts/GoogleChart";
import ChartJsChart from "../Pages/Charts/ChartJsChart";
import FormValidation from "../Pages/Forms/FormControls/FormValidation";
import InputGroups from "../Pages/Forms/FormControls/InputGroups";
import Touchspin from "../Pages/Forms/FormWidgets/Touchspin";
import Switch from "../Pages/Forms/FormWidgets/Switch";
import Typeahead from "../Pages/Forms/FormWidgets/Typeahead";
import Clipboard from "../Pages/Forms/FormWidgets/Clipboard";
import BaseInputs from "../Pages/Forms/FormControls/BaseInputs";
import CheckboxAndRadio from "../Pages/Forms/FormControls/Checkbox&Radio";
import WhetherIcon from "../Pages/Icons/WhetherIcon";
import DefaultStyle from "../Pages/Buttons/DefaultStyle";
import ButtonGroup from "../Pages/Buttons/ButtonGroup";
import ApexChart from "../Pages/Charts/ApexChart";
import TestimonialList from "../Pages/Apps/Testimonial/TestimonialList";
import TestimonialCreate from "../Pages/Apps/Testimonial/TestimonialCreate";
import ContactUs from "../Components/Miscellaneous/ContactUsInfo";
import FeedBackList from "../Components/Miscellaneous/FeedBackfrom";
import ClientCreate from "../Pages/Apps/Clients/ClientCreate";
import ClientList from "../Pages/Apps/Clients/ClientList";
import BlogAdd from "../Pages/Miscellaneous/Blog/BlogAdd";
import MainCategoryList from "../Pages/Apps/marketSolutions/TestimonialList";
import MainCategoryCreate from "../Pages/Apps/marketSolutions/MarketSolutionsCreate";



export const routes = [ 
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/typography`, element:<Typography/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/avatars`, element:<Avatars/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/helper-classes`, element:<HelperClasses/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/scrollable`, element:<Scrollable/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/tree`, element:<TreeView/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/toasts`, element:<Toasts/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/tabs`, element:<Tabs/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/lists`, element:<Lists/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/dropdown`, element:<Dropdown/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/accordion`, element:<Accordion/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/alert`, element:<Alert/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/popover`, element:<Popover/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/tooltip`, element:<Tooltip/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/modal`, element:<Modal/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/grid`, element:<Grid/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/tag-pills`, element:<TagAndPills/> },
    {path: `${process.env.PUBLIC_URL}/components/ui-kits/progress-bar`, element:<Progress/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/product-page`, element:<ProductPage/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/product-list`, element:<ProductList/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/add-products`, element:<AddProduct/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/product`, element:<Product/> },
    // {path: `${process.env.PUBLIC_URL}/applications/service/service-list`, element:<ProjectList/> },
    {path: `${process.env.PUBLIC_URL}/applications/testimonial/testimonial-list`, element:<TestimonialList/> },
    {path: `${process.env.PUBLIC_URL}/applications/testimonial/create-testimonial`, element:<TestimonialCreate/> },
    // {path: `${process.env.PUBLIC_URL}/applications/service/create-service`, element:<ProjectCreate/> },
    {path: `${process.env.PUBLIC_URL}/applications/product/product-list`, element:<ProductList/> },
    {path: `${process.env.PUBLIC_URL}/applications/product/create-product`, element:<ProductCreate/> },
    {path: `${process.env.PUBLIC_URL}/applications/technology/technology-list`, element:<TechnologyList/> },
    {path: `${process.env.PUBLIC_URL}/applications/technology/create-technology`, element:<TechnologyCreate/> },
    {path: `${process.env.PUBLIC_URL}/applications/Milestone/milestone-list`, element:<MilestoneList/> },
    {path: `${process.env.PUBLIC_URL}/applications/Milestone/create-milestone`, element:<MilestoneCreate/> },
    {path: `${process.env.PUBLIC_URL}/applications/Service/create-Service`, element:<ServiceCreate/> },
    {path: `${process.env.PUBLIC_URL}/applications/Service/service-list`, element:<ServiceList/> },
    {path: `${process.env.PUBLIC_URL}/applications/file-manager`, element:<FileManager/> },
    {path: `${process.env.PUBLIC_URL}/widgets/general-widget`, element:<General/> },
    {path: `${process.env.PUBLIC_URL}/widgets/chart-widget`, element:<Chart/> },
    // {path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, element:<Ecommerce/> },
    {path: `${process.env.PUBLIC_URL}/dashboard/project`, element:<Project/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/editors/ck-editor`, element:<CkEditor/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/editors/mde-editor`, element:<MdeEditor/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/editors/ace-code-editor`, element:<AceCodeEditor/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/support-ticket`, element:<SupportTicket/> },
    {path: `${process.env.PUBLIC_URL}/dashboard`, element:<Default/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/timeline`, element:<Timeline/> },
    {path: `${process.env.PUBLIC_URL}/components/icons/flag-icon`, element:<FlagIcons/> },
    {path: `${process.env.PUBLIC_URL}/components/icons/font-awesome`, element:<FontAwesomeIcon/> },
    {path: `${process.env.PUBLIC_URL}/components/icons/ico-icon`, element:<IcoIcon/> },
    {path: `${process.env.PUBLIC_URL}/components/icons/themify-icon`, element:<ThemifyIcon/> },
    {path: `${process.env.PUBLIC_URL}/components/icons/feather-icon`, element:<FeatherIcons/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/grid-gallery`, element:<GalleryGrid/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/grid-gallery-description`, element:<GalleryDescription/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/masonry-gallery`, element:<MasonryGallery/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/masonry-gallery-description`, element:<MasonryGalleryDescription/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/gallery/gallery-hover`, element:<ImageHoverEffects/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/blog/blog-detail`, element:<BlogDetails/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/blog/blog-single`, element:<BlogSingle/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/blog/blog-table`, element:<BlogTable/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/blog/add-post`, element:<AddPost/> },
    {path: `${process.env.PUBLIC_URL}/table/data-tables/basic-init`, element:<BasicInit/> },
    {path: `${process.env.PUBLIC_URL}/table/data-tables/advance-init`, element:<AdvanceInit/> },
    {path: `${process.env.PUBLIC_URL}/table/data-tables/api`, element:<APIDataTables/> },
    {path: `${process.env.PUBLIC_URL}/table/data-tables/data-sources`, element:<DataSource/> },
    {path: `${process.env.PUBLIC_URL}/pages/sample-page`, element:<SamplePage/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-layout/form-wizard-2`, element:<FormWizard2/> },
    {path: `${process.env.PUBLIC_URL}/table/reactstrap-tables/basic-table`, element:<BasicTables/> },
    {path: `${process.env.PUBLIC_URL}/table/reactstrap-tables/table-components`, element:<TableComponents/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-layout/form-wizard-1`, element:<FormWizard1/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-layout/two-factor`, element:<TwoFactor/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/image-cropper`, element:<ImageCropper/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/basic-card`, element:<BasicCard/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/creative-card`, element:<CreativeCard/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/pagination`, element:<Paginations/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/breadcrumb`, element:<Breadcrumb/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/range-slider`, element:<RangeSlider/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/sweet-alert-2`, element:<SweetAlert/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/owl-carousel`, element:<OwlCarousel/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/ribbons`, element:<Ribbons/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/rating`, element:<Rating/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/dropzone`, element:<Dropzone/> },
    {path: `${process.env.PUBLIC_URL}/components/bonus-ui/tour`, element:<Tour/> },
    {path: `${process.env.PUBLIC_URL}/applications/chat/chat-private`, element:<PrivateChat/> },
    {path: `${process.env.PUBLIC_URL}/applications/chat/chat-group`, element:<GroupChat/> },
    {path: `${process.env.PUBLIC_URL}/applications/users/user-profile`, element:<UserProfile/> },
    {path: `${process.env.PUBLIC_URL}/applications/users/edit-profile`, element:<EditProfile/> },
    {path: `${process.env.PUBLIC_URL}/applications/users/user-cards`, element:<UserCards/> },
    {path: `${process.env.PUBLIC_URL}/applications/social-app`, element:<SocialApp/> },
    {path: `${process.env.PUBLIC_URL}/applications/search`, element:<SearchResult/> },
    {path: `${process.env.PUBLIC_URL}/applications/task`, element:<Tasks/> },
    {path: `${process.env.PUBLIC_URL}/applications/calendar-basic`, element:<Calendar/> },
    {path: `${process.env.PUBLIC_URL}/applications/to-do`, element:<ToDo/> },
    {path: `${process.env.PUBLIC_URL}/applications/bookmark`, element:<Bookmarks/> },
    {path: `${process.env.PUBLIC_URL}/applications/contacts`, element:<Contacts/> },
    {path: `${process.env.PUBLIC_URL}/applications/email/email-app`, element:<EmailApplication/> },
    {path: `${process.env.PUBLIC_URL}/applications/email/email-compose`, element:<EmailCompose/> },
    {path: `${process.env.PUBLIC_URL}/applications/email/letter-box`, element:<LetterBox/> },
    // {path: `${process.env.PUBLIC_URL}/invoice/invoice-1`, element:<Invoice1/> },
    // {path: `${process.env.PUBLIC_URL}/invoice/invoice-2`, element:<Invoice2/> },
    // {path: `${process.env.PUBLIC_URL}/invoice/invoice-3`, element:<Invoice3/> },
    // {path: `${process.env.PUBLIC_URL}/invoice/invoice-4`, element:<Invoice4/> },
    // {path: `${process.env.PUBLIC_URL}/invoice/invoice-5`, element:<Invoice5/> },
    // {path: `${process.env.PUBLIC_URL}/invoice/invoice-6`, element:<Invoice6/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/cart`, element:<Cart/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/wishlist`, element:<Wishlist/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/checkout`, element:<Checkout/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/pricing`, element:<Pricing/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/payment-details`, element:<PaymentDetails/> },
    // {path: `${process.env.PUBLIC_URL}/applications/ecommerce/order-history`, element:<OrderHistory/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/faq`, element:<Faq/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/knowledgebase`, element:<Knowledgebase/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/learning/learning-list-view`, element:<LearningList/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/learning/course-details`, element:<DetailedCourse/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-cards-view`, element:<CardsView/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-list-view`, element:<ListView/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-details`, element:<JobDetails/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/job-search/job-apply`, element:<ApplyJobs/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/maps/google-map`, element:<GoogleMaps/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/maps/leaflet-map`, element:<LeafletMap/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-controls/input-mask`, element:<InputMask/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-controls/mega-options`, element:<MegaOptions/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-widgets/datepicker`, element:<Datepicker/> },
    {path: `${process.env.PUBLIC_URL}/components/charts/chart-google`, element:<GoogleChart/> },
    {path: `${process.env.PUBLIC_URL}/components/charts/chartjs`, element:<ChartJsChart/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-controls/form-validation`, element:<FormValidation/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-controls/input-group`, element:<InputGroups/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-widgets/touchspin`, element:<Touchspin/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-widgets/switch`, element:<Switch/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-widgets/typeahead`, element:<Typeahead/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-widgets/clipboard`, element:<Clipboard/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-controls/base-input`, element:<BaseInputs/> },
    {path: `${process.env.PUBLIC_URL}/forms/form-controls/radio-checkbox-control`, element:<CheckboxAndRadio/> },
    {path: `${process.env.PUBLIC_URL}/components/icons/whether-icon`, element:<WhetherIcon/> },
    {path: `${process.env.PUBLIC_URL}/components/buttons/default`, element:<DefaultStyle/> },
    {path: `${process.env.PUBLIC_URL}/components/buttons/button-group`, element:<ButtonGroup/> },
    {path: `${process.env.PUBLIC_URL}/components/charts/chart-apex`, element:<ApexChart/> },
    {path: `${process.env.PUBLIC_URL}/applications/ContactUs/contactUs-list`, element:<ContactUs/> },
    {path: `${process.env.PUBLIC_URL}/applications/Feedback/feedback-list`, element:<FeedBackList/> },
    {path: `${process.env.PUBLIC_URL}/applications/Clients/client-list`, element:<ClientList/> },
    {path: `${process.env.PUBLIC_URL}/applications/Clients/create-Client`, element:<ClientCreate/> },
    {path: `${process.env.PUBLIC_URL}/miscellaneous/blog/blog-Add`, element:<BlogAdd/> },
    {path: `${process.env.PUBLIC_URL}/applications/MainCategory/List`, element:<MainCategoryList/> },
    {path: `${process.env.PUBLIC_URL}/applications/MainCategory/Create`, element:<MainCategoryCreate/> },
]