import axios from "axios";
import { api } from "../../api";
import { TestimonialTableColumnData } from "../../Types/TableType";
// Type for Authorization Token
export const getUserToken = (): string | null => {
    const tokenStr = sessionStorage.getItem("userToken");
    return tokenStr ? `${tokenStr}` : null;
};


interface TestimonialResponse {
  data: any; // Adjust this based on the actual response structure
}

export const fetchTestimonial = async (): Promise<TestimonialResponse> => {
  try {
    const response = await axios.get(`${api}/testimonial/testimonialRetrieve`, {
      headers: { Authorization: getUserToken() },
    });
    return response.data; // Return the whole data object
  } catch (error) {
    throw new Error("Failed to fetch Testimonial.");
  }
};

// testimonialApi.tsx
export const createTestimonial = async (formData: FormData): Promise<TestimonialResponse> => { 
  try {
    console.log("formData", formData);
    const response = await axios.post(`${api}/testimonial/testimonialCreation`, formData, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'multipart/form-data', // Correct header for file uploads
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to create testimonial.");
  }
};


// export const updateTestimonial = async (testimonial_code: string): Promise<TestimonialResponse> => {
//   try {
//     const response = await axios.put(`${api}/testimonial/testimonialEdit`, { testimonial_code }, {
//       headers: {
//         Authorization: `Bearer ${getUserToken()}`,
//         'Content-Type': 'application/json',
//       },
//     });
//     return response.data;
//   } catch (error) {
//     throw new Error("Failed to update milestone.");
//   }
// };
export const updateTestimonial = async (formData: TestimonialTableColumnData): Promise<TestimonialResponse> => {
  try {
      const response = await axios.put(`${api}/testimonial/testimonialEdit`, formData, {
          headers: {
              Authorization: `Bearer ${getUserToken()}`,
              'Content-Type': 'application/json',
          },
      });
      return response.data;
  } catch (error) {
      throw new Error("Failed to update testimonial.");
  }
};

export const deleteTestimonial = async (testimonial_code: string): Promise<TestimonialResponse> => {
  try {
    const response = await axios.delete(`${api}/testimonial/testimonialDelete?testimonial_code=${testimonial_code}`, {
      headers: {
        Authorization: `Bearer ${getUserToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Failed to delete Testimonial.");
  }
};
