import React from 'react'
import ButtonGroupContainer from '../../../Components/Buttons/ButtonGroup'

export default function ButtonGroup() {
  
  return (
    <div className='page-body'>
        <ButtonGroupContainer/>
    </div>
  )
}
