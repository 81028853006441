import React from 'react'
import BaseInputsContainer from '../../../../Components/Forms/FormControls/BaseInputs'

export default function BaseInputs() {
  
  return (
    <div className='page-body'>
        <BaseInputsContainer/>
    </div>
  )
}
