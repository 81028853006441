import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAdmin } from "../Apis/loginApi";
import { LoginData } from "../../Types/TableType";

interface AdminResponse {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
    userType: string;
}

export interface Admin {
    _id: string;
    email: string;
    first_name: string;
    last_name: string;
    userType: string;
}

interface State {
    data: AdminResponse[]; // Update to store an array of admins
    isLoading: boolean;
    error: string | null;
}

const initialState: State = {
    data: [], // Initialize as an empty array
    isLoading: false,
    error: null,
};

export const fetchAdminData = createAsyncThunk(
    'getadmin/fetchData',
    async () => {
        const response = await getAdmin();
        console.log("API response inside fetchData:", response);
        return response.data; // Return the array of data
    }
);

const authAdminSlice = createSlice({
    name: "getadmin",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAdminData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAdminData.fulfilled, (state, action) => {
                state.isLoading = false; // Set loading to false when data is fetched
                state.data = action.payload; // Store the fetched data (array of admins)
                state.error = null;
            })
            .addCase(fetchAdminData.rejected, (state, action) => {
                state.isLoading = false; // Ensure loading is stopped in case of error
                state.error = action.error.message || "Error fetching admin data";
            });
    },
});

export default authAdminSlice.reducer;
