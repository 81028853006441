import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Add, Cancel } from "../../../../Utils/Constants";
import { Btn } from "../../../../AbstractElements";
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs";
import { setProjects } from "../../../../redux-toolkit/reducers/ProjectReducer";
import { createBlogData } from "../../../../redux-toolkit/reducers/BlogReducer";
import { BlogTableColumn } from "../../../../Types/TableType";
import { AppDispatch } from "../../../../redux-toolkit/store";
import { Editor } from "primereact/editor"; // PrimeReact Editor
import 'quill/dist/quill.snow.css';

// Interface for Blog form
interface BlogType {
  blogName: string;
  blogSubject: string;
  blogDescription: string;
  BlogType: any;
  external_link?: string; // Optional field
  bb_story_title: string;
  bb_story_description: string;
  blogThumbnail: string | File; // Allow for URL string or File object
  blog_list: Array<{ bb_story_title: string; bb_story_description: string }>; // Removed image handling
}

export default function CreateNewBlogForm() {
  const dispatch = useDispatch<AppDispatch>(); 
  const navigate = useNavigate();
  const [blog_list, setStories] = useState<
    Array<{ bb_story_title: string; bb_story_description: string }>
  >([{ bb_story_title: "", bb_story_description: "" }]);
  console.log("blog_list", blog_list);
  const [blogThumbnail, setBlogThumbnail] = useState<string | File | null>(
    null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BlogTableColumn>();

  const addBlog: SubmitHandler<BlogTableColumn> = async(data) => {
    console.log(data, "123456");
  //   const finallData = {
  //     ...data,
  //     blog_list: blog_list.map((story) => ({
  //         bb_story_title: story.bb_story_title,
  //         bb_story_description: story.bb_story_description
  //     })),
  //     blogThumbnail: blogThumbnail, // Ensure blogThumbnail is part of the final object
  // };
  const formData = new FormData();
  formData.append("blogName", data.blogName);
  formData.append("blogDescription", data.blogDescription);
  formData.append("blogSubject", data.blogSubject);
  formData.append("external_link", data.external_link || ""); // Optional fields should be handled carefully
  formData.append("blog_list", JSON.stringify(blog_list));

  if (blogThumbnail) {
    formData.append("blogThumbnail", blogThumbnail);
  }

  try {
    await dispatch(createBlogData(formData)); // Await the async action dispatch
    navigate(`${process.env.PUBLIC_URL}/miscellaneous/blog/blog-table`);
  } catch (error) {
    console.error("Error submitting blog:", error);
  }
  };

  const addStory = () => {
    setStories([...blog_list, { bb_story_title: "", bb_story_description: "" }]);
  };

  const removeStory = (index: number) => {
    const updatedStories = blog_list.filter((_, i) => i !== index);
    setStories(updatedStories);
  };

  const handleStoryInputChange = (
    index: number,
    field: "bb_story_title" | "bb_story_description",
    value: string
  ) => {
    setStories((prevStories) => {
      const updatedStories = prevStories.map((story, idx) => {
        if (idx === index) {
          return { ...story, [field]: value };
        }
        return story;
      });
      return updatedStories;
    });
  };

  return (
    <>
      <Breadcrumbs
        pageTitle={"Blog List"}
        parent={"Blog"}
        title={"Create New Blog"}
      />
      <Container fluid>
        <Row>
          <Col sm={12}>
            <Card>
              <CardBody>
                <Form className="theme-form" onSubmit={handleSubmit(addBlog)}>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Blog Title</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Blog name *"
                          {...register("blogName", { required: true })}
                        />
                        {errors.blogName && (
                          <span style={{ color: "red" }}>
                            Blog name is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Blog Subject</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Blog subject *"
                          {...register("blogSubject", { required: true })}
                        />
                        {errors.blogSubject && (
                          <span style={{ color: "red" }}>
                            Blog subject is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Blog Description</Label>
                        <textarea
                          className="form-control"
                          rows={3}
                          placeholder="Blog description *"
                          {...register("blogDescription", { required: true })}
                        />
                        {errors.blogDescription && (
                          <span style={{ color: "red" }}>
                            Blog description is required
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>External Link</Label>
                        <input
                          className="form-control"
                          type="url"
                          placeholder="External link"
                          {...register("external_link")}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>Blog Thumbnail</Label>
                        <input
                          className="form-control"
                          type="file"
                          onChange={(e) =>
                            setBlogThumbnail(
                              e.target.files ? e.target.files[0] : null
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Card className="p-4">
                    {blog_list.map((story, index) => (
                      <div key={index}>
                        <Row>
                          <Col className="text-end">
                            <button
                              type="button"
                              onClick={() => removeStory(index)}
                              className="btn"
                            >
                              X
                            </button>
                          </Col>
                          <FormGroup>
                            <Label>Blog Title:</Label>
                            <input
                              className="form-control"
                              type="text"
                              value={story.bb_story_title}
                              onChange={(e) =>
                                handleStoryInputChange(
                                  index,
                                  "bb_story_title",
                                  e.target.value
                                )
                              }
                              placeholder="Story title"
                            />
                          </FormGroup>
                        </Row>
                        <Row>
                          <FormGroup>
                            <Label>{"Blog Description"}:</Label>
                            <Editor
                              value={story.bb_story_description}
                              style={{ height: '200px' }}
                              onTextChange={(e) =>
                                handleStoryInputChange(
                                  index,
                                  "bb_story_description",
                                  e.htmlValue || ""
                                )
                              }
                            />
                          </FormGroup>
                        </Row>
                      </div>
                    ))}
                  </Card>

                  <button
                    type="button"
                    onClick={addStory}
                    className="btn btn-primary"
                  >
                    + Add Story
                  </button>

                  <Row className="mt-4">
                    <Col>
                      <div className="text-end">
                        <Btn color="success" className="me-3" type="submit">
                          {Add}
                        </Btn>
                        <Btn color="danger">{Cancel}</Btn>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
