import { GroupButton, NestingIconDataItem, RadioCheckboxItem } from "../../Types/ButtonsType";


export const groupButtonData: GroupButton[] = [
    {
        title: "Basic Button Group",
        color: [
            {
                colorClass: "primary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "secondary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "success",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "info",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "warning",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "danger",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "light",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                colorClass: "dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Edges Button Group",
        color: [
            {
                btnClass: " btn-group-pill",
                colorClass: "primary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "secondary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "success",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "info",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "warning",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "danger",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "light",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                btnClass: " btn-group-pill",
                colorClass: "dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Flat Button Group",
        color: [
            {
                btnClass: " btn-group-square",
                colorClass: "primary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "secondary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "success",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "info",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "warning",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "danger",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "light",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                btnClass: " btn-group-square",
                colorClass: "dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Large Button Group",
        color: [
            {
                colorClass: "primary",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "secondary",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "success",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "info",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "warning",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "danger",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "light",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "dark",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
                colClass: "button-group-mb-sm",
            },
        ],
    },
    {
        title: "Large Edges Button Group",
        color: [
            {
                btnClass: " btn-group-pill",
                colorClass: "primary",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "secondary",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "success",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "info",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "warning",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "danger",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "light",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                btnClass: " btn-group-pill",
                colorClass: "dark",
                size: "lg",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Outline Custom Button Group",
        color: [
            {
                btnClass: "btn-group-pill",
                colorClass: "outline-primary",
                differentClass: "primary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: "btn-group-pill",
                differentClass: "secondary",
                colorClass: "outline-secondary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: "btn-group-pill",
                differentClass: "success",
                colorClass: "outline-success",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: "btn-group-pill",
                differentClass: "info",
                colorClass: "outline-info",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                differentClass: "warning",
                btnClass: "btn-group-pill",
                colorClass: "outline-warning",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: "btn-group-pill",
                differentClass: "danger",
                colorClass: " btn-outline-danger",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: "btn-group-pill",
                differentClass: "light",
                colorClass: "outline-light txt-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: "btn-group-pill",
                differentClass: "dark",
                colClass: "button-group-mb-sm",
                colorClass: "outline-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Outline Custom Button Group",
        color: [
            {
                colorClass: "outline-primary",
                leftButtonClass: "primary",
                rightButtonClass: "primary",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-secondary",
                leftButtonClass: "secondary",
                rightButtonClass: "secondary",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-success",
                leftButtonClass: "success",
                rightButtonClass: "success",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-info",
                leftButtonClass: "info",
                rightButtonClass: "info",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-warning",
                leftButtonClass: "warning",
                rightButtonClass: "warning",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-danger",
                leftButtonClass: "danger",
                rightButtonClass: "danger",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-light txt-dark",
                leftButtonClass: "light",
                rightButtonClass: "light",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                colorClass: "outline-dark",
                leftButtonClass: "dark",
                rightButtonClass: "dark",
                btnClass: "btn-group-pill",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Outline Button Group",
        color: [
            {
                colorClass: "outline-primary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-secondary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-success",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-info",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-warning",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-danger",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colorClass: "outline-light txt-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                colorClass: "outline-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Outline Edges Group",
        color: [
            {
                btnClass: " btn-group-pill",
                colorClass: "outline-primary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "outline-secondary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "outline-success",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "outline-info",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "outline-warning",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "outline-danger",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-pill",
                colorClass: "outline-light txt-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                btnClass: " btn-group-pill",
                colorClass: "outline-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
    {
        title: "Outline Flat Button",
        color: [
            {
                btnClass: " btn-group-square",
                colorClass: "outline-primary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "outline-secondary",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "outline-success",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "outline-info",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "outline-warning",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "outline-danger",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                btnClass: " btn-group-square",
                colorClass: "outline-light txt-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
            {
                colClass: "button-group-mb-sm",
                btnClass: " btn-group-square",
                colorClass: "outline-dark",
                title1: "Left",
                title2: "Middle",
                title3: "Right",
            },
        ],
    },
];

export const radioCheckboxData:RadioCheckboxItem[] = [
    {
      title: 'Radio button group',
      span: `Add <code>.active</code> for active state and<code>.disabled</code> class or <code>disabled="disabled"</code> attribute`,
      subButton: [
        {
          btnClass: "btn-radio",
          colorClass: "primary",
          divClass: "radio radio-primary",
          id1: "radio7",
          title1: "Option 1",
          id2: "radio8",
          title2: "Option 2",
          name: 'radio1'
        },
        {
          btnClass: "btn-radio",
          colorClass: "secondary",
          divClass: "radio radio-secondary",
          id1: "radio11",
          title1: "Option 1",
          id2: "radio12",
          title2: "Option 2",
          name: 'radio2'
        },
        {
          btnClass: "btn-radio",
          colorClass: "success",
          divClass: "radio radio-success",
          id1: "radio13",
          title1: "Option 1",
          id2: "radio14",
          title2: "Option 2",
          name: 'radio3'
        },
        {
          btnClass: "btn-radio",
          colorClass: "info",
          divClass: "radio radio-info",
          id1: "radio15",
          title1: "Option 1",
          id2: "radio16",
          title2: "Option 2",
          name: 'radio4'
        },
        {
          btnClass: "btn-radio",
          colorClass: "warning",
          divClass: "radio radio-warning",
          id1: "radio17",
          title1: "Option 1",
          id2: "radio18",
          title2: "Option 2",
          name: 'radio5'
        },
        {
          btnClass: "btn-radio",
          colorClass: "danger",
          divClass: "radio radio-danger",
          id1: "radio20",
          title1: "Option 1",
          id2: "radio22",
          title2: "Option 2",
          name: 'radio6'
        },
        {
          colClass: "button-group-mb-sm xl-50",
          btnClass: "btn-radio",
          colorClass: "light txt-dark",
          divClass: "radio radio-light",
          id1: "radio23",
          title1: "Option 1",
          id2: "radio24",
          title2: "Option 2",
          name: 'radio7'
        },
      ],
    },
    {
      title: 'Check Box button group',
      span: `Add <code>.active</code> for active state and <code>.disabled</code> class or <code>disabled="disabled"</code> attribute`,
      subButton: [
        {
          btnClass: "btn-option",
          colorClass: "primary",
          divClass: "checkbox checkbox-primary",
          id1: "checkbox-primary-1",
          title1: "Option 1",
          id2: "checkbox-primary-2",
          title2: "Option 2",
          checkBox: true,
        },
        {
          btnClass: "btn-group btn-option",
          colorClass: "secondary",
          divClass: "checkbox checkbox-secondary",
          id1: "checkbox-primary-3",
          title1: "Option 1",
          id2: "checkbox-primary-4",
          title2: "Option 2",
          checkBox: true,
        },
        {
          btnClass: "btn-group btn-option",
          colorClass: "success",
          divClass: "checkbox checkbox-success",
          id1: "checkbox-primary-5",
          title1: "Option 1",
          id2: "checkbox-primary-6",
          title2: "Option 2",
          checkBox: true,
        },
        {
          btnClass: "btn-group btn-option",
          colorClass: "info",
          divClass: "checkbox checkbox-info",
          id1: "checkbox-primary-7",
          title1: "Option 1",
          id2: "checkbox-primary-8",
          title2: "Option 2",
          checkBox: true,
        },
        {
          btnClass: "btn-group btn-option",
          colorClass: "warning",
          divClass: "checkbox checkbox-warning",
          id1: "checkbox-primary-9",
          title1: "Option 1",
          id2: "checkbox-primary-10",
          title2: "Option 2",
          checkBox: true,
        },
        {
          btnClass: "btn-group btn-option",
          colorClass: "danger",
          divClass: "checkbox checkbox-danger",
          id1: "checkbox-danger-11",
          title1: "Option 1",
          id2: "checkbox-danger-12",
          title2: "Option 2",
          checkBox: true,
        },
        {
          colClass: "button-group-mb-sm",
          btnClass: "btn-group btn-option",
          colorClass: "light txt-dark",
          divClass: "checkbox checkbox-light",
          id1: "checkbox-light-13",
          title1: "Option 1",
          id2: "checkbox-light-14",
          title2: "Option 2",
          checkBox: true,
        },
      ],
    },
  ];
  
  export const nestingIconData: NestingIconDataItem[] = [
    { id: 1,
      child: [
       {id: 1, color:'primary',iconClass:'fa-bold'},
       {id: 2, color:'secondary',iconClass:'fa-italic'},
       {id: 3, color:'success',iconClass:'fa-file-image-o'},
       {id: 4, color:'info',iconClass:'fa-paperclip'},
      ]
    },
    { id: 2,
      child: [
       {id: 1,size:'lg', color:'primary',iconClass:'fa-bold'},
       {id: 2,size:'lg', color:'secondary',iconClass:'fa-italic'},
       {id: 3,size:'lg', color:'success',iconClass:'fa-file-image-o'},
       {id: 4,size:'lg', color:'info',iconClass:'fa-paperclip'},
      ]
    }
  ]