//bookmark
export const bookmarkViewsNav = [
    { id: '1', title: 'Created by me' },
    { id: '2', title: 'Favourites' },
    { id: '3', title: 'Shared with me' },
    { id: '4', title: 'My bookmark' },
]

export const bookmarkTagsNav = [
    { id: '5', title: 'notification' },
    { id: '6', title: 'Newsletter' },
    { id: '7', title: 'Business' },
    { id: '8', title: 'Holidays' },
    { id: '9', title: 'Important' },
    { id: '10', title: 'Organization' },
];

//contacts
export const contactNavData = [
    { id: 2, title: 'Organization' },
    { id: 3, title: 'Follow up' },
    { id: 4, title: 'Favorites' },
    { id: 5, title: 'Ideas' },
    { id: 6, title: 'Important' },
    { id: 7, title: 'Business' },
    { id: 8, title: 'Holidays' },
];

export const organizationList = [
    {
        id: 1,
        image: 'user/user.png',
        name: 'Mark Jecno',
        email: 'MARKJENCOEMAIL',
        gender: 'Male'
    },
    {
        id: 2,
        image: 'user/3.jpg',
        name: 'Jason Borne',
        email: 'jasonb@gmail.com',
        gender: 'Female'
    },
    {
        id: 3,
        image: 'user/4.jpg',
        name: 'Sarah Loren',
        email: 'barnes@gmail.com',
        gender: 'Female'
    },
    {
        id: 4,
        image: 'user/10.jpg',
        name: 'Andew Jon',
        email: 'andewjon@gmail.com',
        gender: 'Female'
    }
];

export const dayList = ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
export const monthList = ['January','February','March','April','May','June','July','August','September','October','November','December']